import {
  derive,
  DialogStore,
  LanguageStore,
  RouterStore,
  type Signal,
  type ViewContext,
} from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { NavStore } from "@stores/NavStore";
import { NotesStore } from "@stores/NotesStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { TasksStore } from "@stores/TasksStore";
import { ThemeStore } from "@stores/ThemeStore";
import Close from "@icons/Close";
import { TaskEditDialog } from "@views/TaskListItem/TaskEditDialog/TaskEditDialog";
import { Favorite, Task } from "schemas";
import styles from "./FavoriteListItem.module.css";
import NoteIcon from "@icons/Note";
import TaskIcon from "@icons/Task";
import ProjectIcon from "@icons/FolderLine";

type FavoriteType = "task" | "note";

interface FavoriteListItemProps {
  $favorite: Signal<Favorite>;
  onClick?: () => void;
}

export function FavoriteListItem(props: FavoriteListItemProps, ctx: ViewContext) {
  const router = ctx.getStore(RouterStore);
  const auth = ctx.getStore(AuthStore);
  const projects = ctx.getStore(ProjectsStore);
  const tasks = ctx.getStore(TasksStore);
  const notes = ctx.getStore(NotesStore);
  const theme = ctx.getStore(ThemeStore);
  const nav = ctx.getStore(NavStore);
  const dialog = ctx.getStore(DialogStore);
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);

  const $project = derive([props.$favorite, projects.$cache], (favorite, projects) => {
    const projectId = parseInt(favorite.path.split("/")[2]);
    return projects.find((p) => p.id === projectId);
  });

  const $details = derive(
    [props.$favorite, auth.$me, projects.$cache, notes.$cache, tasks.$cache, $currentLanguage],
    (favorite, me, projectsCache, notesCache, tasksCache, _) => {
      let title = favorite.path;
      let color = "#888";
      let type: FavoriteType = "note";
      let _task: Task | undefined;

      if (favorite.path.startsWith("/projects/")) {
        const projectId = parseInt(favorite.path.split("/")[2]);
        const project = projectsCache.find((p) => p.id === projectId);
        if (!project) {
          color = "#f00";
          title = "MISSING PROJECT";
        } else {
          color = project.color;

          if (favorite.path.includes("/tasks")) {
            const taskId = parseInt(favorite.path.split("/")[4]);
            if (!isNaN(taskId)) {
              // SIDE EFFECT
              tasks.ensureTaskIsLoaded(taskId);

              type = "task";

              const task = tasksCache.get(taskId);
              if (task) {
                title = task.title;
                _task = task;
              } else {
                title = "MISSING TASK";
                color = "#f00";
              }
            } else {
              title = translate("workspace.nav.favorites.labels.tasksList_projectName", {
                projectName: project.name,
              }).get();
            }
          } else if (favorite.path.includes("/notes")) {
            const noteId = parseInt(favorite.path.split("/")[4]);
            if (!isNaN(noteId)) {
              // SIDE EFFECT
              notes.ensureNoteIsLoaded(noteId);

              type = "note";

              const note = notesCache.get(noteId);
              if (note) {
                title = note.title;
              } else {
                title = "MISSING NOTE";
                color = "#f00";
              }
            } else {
              title = translate("workspace.nav.favorites.labels.notesList_projectName", {
                projectName: project.name,
              }).get();
            }
          } else {
            // Overview
            title = translate("workspace.nav.favorites.labels.overview_projectName", {
              projectName: project.name,
            }).get();
          }
        }
      }

      return { title, color, type, _task };
    },
  );

  const $active = derive([props.$favorite, router.$path], (favorite, path) => favorite.path === path);

  return (
    <li
      class={[styles.listItem, { [styles.active]: $active }]}
      style={theme.getTheme$(derive([$details], (x) => x.color))}
    >
      <a
        class={styles.link}
        href={derive([props.$favorite], (f) => f.path)}
        title={derive([$details], (x) => x.title)}
        onClick={(e) => {
          const details = $details.get();
          if (details._task) {
            // Open task dialog if it's a task.
            e.preventDefault();
            const project = projects.$cache.get().find((p) => p.id === details._task!.projectId);
            dialog.open(TaskEditDialog, {
              task: details._task,
              projectId: details._task.projectId,
              color: project?.color,
              onToListClicked: () => {
                router.navigate(`/projects/${details._task!.projectId}/tasks?task=${details._task!.id}`);
                props.onClick?.();
              },
            });
          } else {
            // router.navigate(``)
            props.onClick?.();
          }
        }}
      >
        <div class={styles.main}>
          <div class={styles.icon}>
            {derive([$details], ({ type }) => {
              switch (type) {
                case "note":
                  return <NoteIcon />;
                case "task":
                  return <TaskIcon />;
              }
            })}
          </div>
          <span class={styles.name}>{derive([$details], (x) => x.title)}</span>
          <div class={styles.controls}>
            <button
              onClick={(e) => {
                e.preventDefault();
                nav.removeFavorite(props.$favorite.get().id);
              }}
              class={styles.removeButton}
            >
              <Close />
            </button>
          </div>
        </div>

        <ul class={styles.meta}>
          {derive([$details], ({ type }) => {
            switch (type) {
              case "note":
                return (
                  <li>
                    <NoteIcon />
                    <span>Note</span>
                  </li>
                );
              case "task":
                return (
                  <li>
                    <TaskIcon />
                    <span>Task</span>
                  </li>
                );
            }
          })}
          <li>
            <ProjectIcon />
            <span>{derive([$project], (p) => p?.name)}</span>
          </li>
        </ul>
      </a>
    </li>
  );
}
