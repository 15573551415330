import { sticky } from "@helpers/sticky";
import { LanguageStore, type Signal, RouterStore, ViewContext, derive } from "@manyducks.co/dolla";
import { ProjectsStore } from "@stores/ProjectsStore";
import { TasksStore } from "@stores/TasksStore";
import ChevronLeft from "@icons/ChevronLeft";
import ChevronRight from "@icons/ChevronRight";
import { Button } from "@views/Button";
import { IconButton } from "@views/IconButton";
import { Select } from "@views/Select";
import { ToolBar, ToolBarGroup } from "@views/ToolBar";
import styles from "./ProjectCalendar.module.css";
import { CalendarView } from "./CalendarView/CalendarView";
import { CardDetailStack } from "@views/CardDetailStack";
import { DayDetails } from "./DayDetails/DayDetails";
import { AuthStore } from "@stores/AuthStore";

type DetailTabs = "date" | "unscheduled";

interface ProjectCalendarProps {}

export function ProjectCalendar(props: ProjectCalendarProps, ctx: ViewContext) {
  const { translate } = ctx.getStore(LanguageStore);
  const router = ctx.getStore(RouterStore);
  const projects = ctx.getStore(ProjectsStore);
  const tasks = ctx.getStore(TasksStore);
  const auth = ctx.getStore(AuthStore);

  const $projectId = derive([router.$params], (params) => (params.projectId ? Number(params.projectId) : 0));

  const $year = derive([router.$params], (p) => (p.year != null ? Number(p.year) : new Date().getFullYear()));
  const $month = derive([router.$params], (p) =>
    p.month != null ? Number(p.month) : new Date().getMonth() + 1,
  );
  const $day = derive([router.$params], (p) => (p.day != null ? Number(p.day) : undefined));

  const $selectedDate = derive([$year, $month, $day], (y, m, d) => {
    if (d == null) {
      return undefined;
    } else {
      return new Date(y, m - 1, d);
    }
  });

  let lastProjectId: number | undefined;

  const $currentProject = derive(
    [projects.$cache, $projectId],
    (projects, id) => projects.find((p) => p.id === id)!,
  );
  const $project = derive([$currentProject], sticky());
  const $selectableYears = derive([$project], (p) => {
    const years = [];
    let cursor = new Date(p.createdAt).getFullYear();
    let max = new Date().getFullYear() + 2;
    // From the year the project was created to 2 years in the future.
    while (cursor <= max) {
      years.push(cursor);
      cursor += 1;
    }
    return years;
  });

  const $userRole = derive([auth.$me, $project], (user, project) => {
    if (!user || !project || project.archivedAt != null) {
      return "viewer";
    }
    return project.users.find((u) => u.id === user.id)?.role ?? "viewer";
  });

  ctx.watch([$month, $year, $projectId], (month, year, projectId) => {
    tasks.fetchCalendar(year, month, {
      projectId,
      withAdjacent: lastProjectId != projectId,
    });
    lastProjectId = projectId;
  });

  return (
    <div class={styles.layout}>
      <CardDetailStack
        $showDetail={derive([$selectedDate], (x) => x != null)}
        expandedThreshold={1200}
        expandedListWidth={800}
        listContent={
          <div class={styles.listContent}>
            <div class={styles.topControls}>
              <ToolBar>
                <ToolBarGroup>
                  <Select
                    value={$month}
                    options={[
                      { label: getMonthName$(ctx, 1), value: 1 },
                      { label: getMonthName$(ctx, 2), value: 2 },
                      { label: getMonthName$(ctx, 3), value: 3 },
                      { label: getMonthName$(ctx, 4), value: 4 },
                      { label: getMonthName$(ctx, 5), value: 5 },
                      { label: getMonthName$(ctx, 6), value: 6 },
                      { label: getMonthName$(ctx, 7), value: 7 },
                      { label: getMonthName$(ctx, 8), value: 8 },
                      { label: getMonthName$(ctx, 9), value: 9 },
                      { label: getMonthName$(ctx, 10), value: 10 },
                      { label: getMonthName$(ctx, 11), value: 11 },
                      { label: getMonthName$(ctx, 12), value: 12 },
                    ]}
                    onChange={(value) => {
                      const projectId = $projectId.get();
                      const year = $year.get();
                      router.navigate(`/projects/${projectId}/calendar/${year}/${value}`);
                    }}
                  />
                  {derive([$selectableYears], (years) => {
                    return (
                      <Select
                        value={$year}
                        options={years.map((y) => ({ label: y.toString(), value: y }))}
                        onChange={(value) => {
                          const projectId = $projectId.get();
                          const month = $month.get();
                          router.navigate(`/projects/${projectId}/calendar/${value}/${month}`);
                        }}
                      />
                    );
                  })}
                </ToolBarGroup>

                <ToolBarGroup>
                  <IconButton
                    onClick={() => {
                      const projectId = $projectId.get();
                      const year = $year.get();
                      const month = $month.get();

                      if (month == 1) {
                        router.navigate(`/projects/${projectId}/calendar/${year - 1}/12`);
                      } else {
                        router.navigate(`/projects/${projectId}/calendar/${year}/${month - 1}`);
                      }
                    }}
                  >
                    <ChevronLeft />
                  </IconButton>
                  <Button
                    onClick={() => {
                      const now = new Date();
                      const projectId = $projectId.get();
                      const year = now.getFullYear();
                      const month = now.getMonth() + 1;

                      router.navigate(`/projects/${projectId}/calendar/${year}/${month}`);
                    }}
                  >
                    {translate("workspace.project.tasks.calendar.todayButtonText")}
                  </Button>
                  <IconButton
                    onClick={() => {
                      const projectId = $projectId.get();
                      const year = $year.get();
                      const month = $month.get();

                      if (month == 12) {
                        router.navigate(`/projects/${projectId}/calendar/${year + 1}/1`);
                      } else {
                        router.navigate(`/projects/${projectId}/calendar/${year}/${month + 1}`);
                      }
                    }}
                  >
                    <ChevronRight />
                  </IconButton>
                </ToolBarGroup>
              </ToolBar>
            </div>

            <div class={styles.calendar}>
              <CalendarView
                $month={$month}
                $year={$year}
                $selectedDate={$selectedDate}
                $project={$project}
                onDaySelected={(date) => {
                  const projectId = $projectId.get();
                  const year = date.getFullYear();
                  const month = date.getMonth() + 1;
                  const day = date.getDate();

                  router.navigate(`/projects/${projectId}/calendar/${year}/${month}/${day}`);
                }}
              />
            </div>
          </div>
        }
        detailContent={
          <DayDetails
            $projectId={$projectId}
            $selectedDate={$selectedDate}
            $canInteract={derive([$userRole], (role) => role != "viewer")}
            onBackButtonClicked={() => {
              const projectId = $projectId.get();
              const year = $year.get();
              const month = $month.get();

              router.navigate(`/projects/${projectId}/calendar/${year}/${month}`);
            }}
          />
        }
      />
    </div>
  );
}

function getMonthName$(ctx: ViewContext, month: number): Signal<string> {
  const { $currentLanguage } = ctx.getStore(LanguageStore);
  return derive([$currentLanguage], (lang) => {
    const formatter = new Intl.DateTimeFormat([lang!, "en-US"], { month: "short" });
    const date = new Date(2024, month - 1, 1);
    return formatter.format(date);
  });
}
