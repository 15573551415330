import {
  Blend,
  CorePalette,
  DislikeAnalyzer,
  Hct,
  TonalPalette,
  argbFromHex,
  hexFromArgb,
} from "@material/material-color-utilities";
import chroma from "chroma-js";

/**
 * Adjust accent color to be visible on a light or dark background.
 */
export function adjustAccentColor(color: string, dark = false) {
  let accentColor = chroma(color);

  if (dark) {
    if (accentColor.luminance() < 0.25) {
      accentColor = accentColor.luminance(0.25);
    }
  } else {
    if (accentColor.luminance() > 0.3) {
      accentColor = accentColor.luminance(0.3);
    }
  }

  return accentColor.hex();
}

/**
 * Returns an appropriate text color readable over a background of `color`.
 */
export function getTextColorForBackground(color: string | number | chroma.Color) {
  return chroma(color).luminance() > 0.5 ? "#000" : "#fff";
}

export type ThemeCSSVariables = ReturnType<Theme["toCSS"]>;

export class Theme {
  #dark: boolean;
  #muted: boolean;
  #hex: string;

  palettes: {
    // Primary
    p1: TonalPalette;
    p2: TonalPalette;
    p3: TonalPalette;

    // Neutral
    n1: TonalPalette;
    n2: TonalPalette;

    // Danger
    d1: TonalPalette;
  };

  constructor(hex: string, dark = false, muted = false) {
    this.#dark = dark;
    this.#hex = hex;
    this.#muted = muted;

    const harmonized = Blend.harmonize(argbFromHex(hex), argbFromHex(this.neutral));
    const hctPrimary = DislikeAnalyzer.fixIfDisliked(Hct.fromInt(harmonized));

    const hctDanger = Hct.fromInt(Blend.harmonize(argbFromHex("#ff0000"), hctPrimary.toInt()));

    const chroma = this.#muted
      ? Math.min(hctPrimary.chroma * 0.8, dark ? 44 : 24)
      : Math.max(6, dark ? Math.min(hctPrimary.chroma, 70) : hctPrimary.chroma);
    // Math.max(6, hctPrimary.chroma);

    this.palettes = {
      p1: TonalPalette.fromHueAndChroma(hctPrimary.hue, chroma),
      p2: TonalPalette.fromHueAndChroma(hctPrimary.hue, Math.min(hctPrimary.chroma * 0.7, dark ? 34 : 24)),
      p3: TonalPalette.fromHueAndChroma(hctPrimary.hue, Math.min(hctPrimary.chroma * 0.5, dark ? 24 : 8)),
      n1: TonalPalette.fromHueAndChroma(hctPrimary.hue, Math.min(hctPrimary.chroma / 3, dark ? 32 : 12)),
      n2: TonalPalette.fromHueAndChroma(hctPrimary.hue, Math.min(hctPrimary.chroma / 4, dark ? 24 : 8)),
      d1: TonalPalette.fromHueAndChroma(hctDanger.hue, hctDanger.chroma),
    };
  }

  get primary() {
    return hexFromArgb(this.#dark ? this.palettes.p1.tone(30) : this.palettes.p1.tone(78));
  }

  get onPrimary() {
    return this.onNeutral;
  }

  get onPrimaryAlt() {
    return hexFromArgb(this.#dark ? this.palettes.p1.tone(80) : this.palettes.p1.tone(20));
  }

  get onPrimaryInverse() {
    return this.#dark ? "#000" : "#fff";
  }

  get secondary(): string {
    return hexFromArgb(this.#dark ? this.palettes.p1.tone(48) : this.palettes.p1.tone(50));
  }

  get onSecondary(): string {
    return hexFromArgb(this.#dark ? this.palettes.p1.tone(99) : this.palettes.p1.tone(99));
  }

  get onSecondaryAlt(): string {
    return hexFromArgb(this.#dark ? this.palettes.p2.tone(80) : this.palettes.p1.tone(88));
  }

  get onSecondaryInverse(): string {
    return hexFromArgb(this.#dark ? this.palettes.p2.tone(6) : this.palettes.p2.tone(6));
  }

  get border(): string {
    return this.#dark ? "#554" : this.onNeutralAlt;
  }

  get background(): string {
    return this.neutral;
    // return this.#dark ? hexFromArgb(this.palettes.p2.tone(6)) : this.neutral;
  }

  get onBackground(): string {
    return this.#dark ? "#fff" : this.onNeutral;
  }

  get onBackgroundAlt(): string {
    return this.onNeutralAlt;
    // return this.#dark ? hexFromArgb(this.palettes.n2.tone(40)) : this.onNeutralAlt;
  }

  get onBackgroundInverse(): string {
    return this.#dark ? "#000" : "#fff";
  }

  get neutral(): string {
    return this.#dark ? "#000" : "#fdf7e9";
    // return this.#dark ? "#112" : "#fdf7e9";
  }

  get onNeutral(): string {
    return this.#dark ? "#fff" : "#000";
  }

  get onNeutralAlt(): string {
    return this.#dark ? "#868680" : "#9c8782";
    // return this.#dark ? "#998" : "#9C8782";
  }

  get onNeutralInverse(): string {
    return this.#dark ? "#000" : "#fff";
  }

  get mid(): string {
    return this.#dark ? "#232320" : "#f0e0cd";
    // return this.#dark ? hexFromArgb(this.palettes.p3.tone(12)) : "#F0E0CD";
  }

  get onMid(): string {
    return this.#dark ? "#fff" : "#000";
  }

  get onMidAlt(): string {
    return this.onNeutralAlt;
    // return this.#dark ? hexFromArgb(this.palettes.n2.tone(60)) : this.onNeutralAlt;
  }

  get onMidInverse(): string {
    return this.#dark ? "#000" : "#fff";
  }

  get favorite(): string {
    return this.#dark ? "#444" : "#9C8782";
  }

  get onFavorite(): string {
    return this.#dark ? "#fff" : "#9C8782";
  }

  get pin(): string {
    return this.#dark ? "#444" : "#9C8782";
  }

  get onPin(): string {
    return this.#dark ? "#444" : "#9C8782";
  }

  /*==========================*\
  ||      Danger Colors       ||
  \*==========================*/

  get danger(): string {
    return hexFromArgb(this.#dark ? this.palettes.d1.tone(48) : this.palettes.d1.tone(50));
  }

  get dangerHover(): string {
    return hexFromArgb(this.#dark ? this.palettes.d1.tone(60) : this.palettes.d1.tone(40));
  }

  get onDanger(): string {
    return hexFromArgb(this.#dark ? this.palettes.d1.tone(98) : this.palettes.d1.tone(96));
  }

  toCSS() {
    return {
      "--theme-border": this.border,

      "--theme-background": this.background,
      "--theme-onBackground": this.onBackground,
      "--theme-onBackgroundAlt": this.onBackgroundAlt,
      "--theme-onBackgroundInverse": this.onBackgroundInverse,

      "--theme-primary": this.primary,
      "--theme-onPrimary": this.onPrimary,
      "--theme-onPrimaryAlt": this.onPrimaryAlt,
      "--theme-onPrimaryInverse": this.onPrimaryInverse,

      "--theme-secondary": this.secondary,
      "--theme-onSecondary": this.onSecondary,
      "--theme-onSecondaryAlt": this.onSecondaryAlt,
      "--theme-onSecondaryInverse": this.onSecondaryInverse,

      "--theme-neutral": this.neutral,
      "--theme-onNeutral": this.onNeutral,
      "--theme-onNeutralAlt": this.onNeutralAlt,
      "--theme-onNeutralInverse": this.onNeutralInverse,

      "--theme-mid": this.mid,
      "--theme-onMid": this.onMid,
      "--theme-onMidAlt": this.onMidAlt,
      "--theme-onMidInverse": this.onMidInverse,

      "--theme-danger": this.danger,
      "--theme-dangerHover": this.dangerHover,
      "--theme-onDanger": this.onDanger,
    };
  }
}
