import { signal, HTTPStore, type StoreContext } from "@manyducks.co/dolla";
import { produce } from "immer";
import { ProjectInvite, type Project } from "schemas";
import { AuthStore } from "./AuthStore";
import { IOStore } from "./IOStore";

export function ProjectsStore(ctx: StoreContext) {
  const http = ctx.getStore(HTTPStore);
  // const auth = ctx.getStore(AuthStore);

  const [$cache, setCache] = signal<Project[]>([]);
  const [$invites, setInvites] = signal<ProjectInvite[]>([]);

  async function refreshList() {
    const [projects, invites] = await Promise.all([
      http.get<Project[]>("/api/projects"),
      http.get<ProjectInvite[]>("/api/projects/my-invites"),
    ]);

    setCache(projects.body);
    setInvites(invites.body);

    return projects.body;
  }

  async function ensureProjectLoaded(projectId: number) {
    const cached = $cache.get().find((p) => p.id === projectId);
    if (!cached) {
      try {
        const res = await http.get<Project>(`/api/projects/${projectId}`);
        setCache(
          produce((current) => {
            const existing = current.find((p) => p.id === projectId);

            if (existing) {
              Object.assign(existing, res.body);
            } else {
              current.push(res.body);
            }
          }),
        );
        return { exists: res.body != null };
      } catch (err) {
        ctx.error(err);
      }
    }
    return { exists: cached != null };
  }

  return {
    $cache,
    $invites,

    refreshList,
    ensureProjectLoaded,

    async createProject(options: Pick<Project, "name" | "color">) {
      const res = await http.post<Project>("/api/projects", {
        body: {
          name: options.name,
          color: options.color,
        },
      });

      setCache(
        produce((current) => {
          current.push(res.body);
        }),
      );

      return res.body;
    },

    async updateProject(projectId: number, options: Partial<Exclude<Project, "id" | "users">>) {
      await http.put(`/api/projects/${projectId}`, {
        body: options,
      });

      setCache(
        produce((current) => {
          const existing = current.find((p) => p.id === projectId);

          if (existing) {
            Object.assign(existing, options);
          } else {
            ctx.warn("no matching project to be updated", current, options);
          }
        }),
      );
    },

    async deleteProject(id: number) {
      await http.delete(`/api/projects/${id}`);

      setCache((projects) => {
        return projects.filter((p) => p.id !== id);
      });
    },

    async leaveProject(id: number) {
      await http.post(`/api/projects/${id}/leave`);

      setCache((projects) => {
        return projects.filter((p) => p.id !== id);
      });
    },

    async archiveProject(id: number) {
      setCache(
        produce((projects) => {
          const project = projects.find((p) => p.id === id);
          if (project) {
            project.archivedAt = new Date().toISOString();
          }
        }),
      );

      await http.put(`/api/projects/${id}/archived`);
    },

    async unarchiveProject(id: number) {
      setCache(
        produce((projects) => {
          const project = projects.find((p) => p.id === id);
          if (project) {
            project.archivedAt = null;
          }
        }),
      );

      await http.delete(`/api/projects/${id}/archived`);
    },

    projectUpdateReceived(project: Project, userId: number) {
      setCache(
        produce((projects) => {
          const current = projects.find((x) => x.id === project.id);

          if (current) {
            Object.assign(current, project);
          } else {
            projects.push(project);
          }
        }),
      );
    },

    projectDeleteReceived: (id: number) => {
      setCache((cache) => {
        return cache.filter((project) => project.id !== id);
      });
    },

    projectInviteReceived(invite: ProjectInvite) {
      setCache(
        produce((invites) => {
          const current = invites.find((x) => x.id === invite.id);

          if (current) {
            Object.assign(current, invite);
          } else {
            invites.push(invite);
          }
        }),
      );
    },

    updateCache(cache: Array<number>) {
      if (cache.length > 0) {
        setCache((projects) => projects.filter((project) => !cache.includes(project.id)));
      }
    },
  };
}
