import { type SettableSignal, type ViewContext } from "@manyducks.co/dolla";
import { nanoid } from "nanoid";
import styles from "./ColorInput.module.css";

interface ColorInputProps {
  /**
   * Input 'id' for use in forms.
   */
  id?: string;

  /**
   * Input 'name' for use in forms.
   */
  name?: string;

  /**
   * Two-way bindable color value.
   */
  $$value: SettableSignal<string>;
}

/**
 * Provides a color picker to select a custom color.
 */
export function ColorInput(props: ColorInputProps, ctx: ViewContext) {
  const id = props.id ?? nanoid();

  return (
    <div class={styles.container}>
      <input id={id} class={styles.input} type="color" $$value={props.$$value} name={props.name} />
      <label for={id} class={styles.label} style={{ "--input-color": props.$$value }}>
        <span class={styles.fill} />
      </label>
    </div>
  );
}
