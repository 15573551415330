export default {
  "layout": "Home_layout_38f21",
  "homeHeader": "Home_homeHeader_38f21",
  "homeHeaderLayout": "Home_homeHeaderLayout_38f21",
  "headerDayAndTime": "Home_headerDayAndTime_38f21",
  "content": "Home_content_38f21",
  "contentWrap": "Home_contentWrap_38f21",
  "hidden": "Home_hidden_38f21",
  "searchContent": "Home_searchContent_38f21",
  "resultContent": "Home_resultContent_38f21",
  "menuContent": "Home_menuContent_38f21",
  "header": "Home_header_38f21",
  "searchBox": "Home_searchBox_38f21",
  "search": "Home_search_38f21",
  "section": "Home_section_38f21",
  "subsection": "Home_subsection_38f21",
  "taskList": "Home_taskList_38f21",
  "tileList": "Home_tileList_38f21",
  "projectLink": "Home_projectLink_38f21",
  "projectTitle": "Home_projectTitle_38f21",
  "projectMeta": "Home_projectMeta_38f21"
};import "ni:sha-256;QMuCHsnqqgj3Zz8c5yy5Y5LbEObcLhsurlpQvEerNo8";