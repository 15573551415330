import { relativePastTime } from "@helpers/relativePastTime";
import {
  DialogStore,
  LanguageStore,
  type MaybeSignal,
  RouterStore,
  type Signal,
  type ViewContext,
  cond,
  derive,
  repeat,
  signal,
  signalify,
} from "@manyducks.co/dolla";
import { type Renderable } from "@manyducks.co/dolla/lib/types";
import { ClockStore } from "@stores/ClockStore";
import { NotesStore } from "@stores/NotesStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { ConfirmDelete } from "@views/@dialogs";
import Paperclip from "@icons/Paperclip";
import PinIcon from "@icons/Pin";
import TimeIcon from "@icons/Time";
import TrashIcon from "@icons/Trash";
import { MoreMenu } from "@views/MoreMenu";
import prettyBytes from "pretty-bytes";
import { type Note } from "schemas";
import styles from "./NoteListItem.module.css";

interface NoteListItemProps {
  $note: Signal<Note>;
  $orderBy?: Signal<string>;
  scrollToItem?: (noteId: number) => void;

  interactive?: MaybeSignal<boolean>;
  $charms?: Signal<Renderable>;
  $theme?: Signal<any>;

  onClick: () => void;
}

export function NoteListItem(props: NoteListItemProps, ctx: ViewContext) {
  const clock = ctx.getStore(ClockStore);
  const notes = ctx.getStore(NotesStore);
  const dialog = ctx.getStore(DialogStore);
  const projects = ctx.getStore(ProjectsStore);
  const router = ctx.getStore(RouterStore);
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);

  const { $note, $orderBy, scrollToItem } = props;

  const $noteId = derive([$note], (n) => n.id);
  const $defaultTitle = translate("views.noteListItem.unnamedFallbackTitle");
  const $title = derive([$note, $defaultTitle], (note, defaultTitle) => note.title || defaultTitle);
  const $isPinned = derive([$note], (n) => n.isPinned);
  const $isTitled = derive([$note], (note) => !!note.title);
  const $isUntitled = derive([$title, $defaultTitle], (title, defaultTitle) => title === defaultTitle);

  const $isSelected = derive([router.$pattern, router.$params, $noteId], (pattern, params, id) => {
    return (pattern?.startsWith("/projects/{#projectId}/notes/{#noteId}") ?? false) && params.noteId === id;
  });

  const $projectColor = derive(
    [$note, projects.$cache],
    (note, projects) => projects.find((p) => p.id === note.projectId)?.color,
  );

  const $interactive = signalify(props.interactive ?? true);

  const $date = derive([$note, $orderBy, $currentLanguage, clock.$minute], (note, orderBy, lang) => {
    let timestamp!: string | null;

    switch (orderBy) {
      case "created-newest":
      case "created-oldest":
      case "alphabetical-newest":
      case "alphabetical-oldest":
        timestamp = relativePastTime(lang!, note.createdAt);
        break;
      case "updated-newest":
      case "updated-oldest":
      default:
        timestamp = relativePastTime(lang!, note.updatedAt);
        break;
    }

    if (timestamp == null) {
      return translate("relativeTime.now").get();
    } else {
      return timestamp;
    }
  });
  // TODO: Find a better way to handle localized plural/singular/etc.
  // const $contentLength = computed([$note, $currentLanguage], ([note, lang]) => {
  //   const values = {
  //     characterCount: note.characterCount,
  //     wordCount: note.wordCount,
  //   };

  //   if (lang === "ja") {
  //     if (note.characterCount === 1) {
  //       return translate("contentLength.singular", values).get();
  //     } else {
  //       return translate("contentLength.plural", values).get();
  //     }
  //   } else {
  //     if (note.wordCount === 1) {
  //       return translate("contentLength.singular", values).get();
  //     } else {
  //       return translate("contentLength.plural", values).get();
  //     }
  //   }
  // });

  const [$menuIsOpen, setMenuIsOpen] = signal(false);
  const [$highlight, setHighlight] = signal(false);

  // const $noteState = computed(notesCard.$cardState, (s) => ({
  //   justActiveNoteId: s.justActiveNoteId,
  //   noteState: s.noteState,
  // }));

  // // Trigger highlight animation when going back to list.
  // ctx.observe($noteState, (state) => {
  //   const note = $note.get();
  //   if (state.justActiveNoteId && state.noteState == undefined && note.id === state.justActiveNoteId) {
  //     $$highlight.set(true);
  //     scrollToItem(note.id);
  //     setTimeout(() => {
  //       $$highlight.set(false);
  //       notesCard.updateState({
  //         justActiveNoteId: undefined,
  //       });
  //     }, 1000);
  //   }
  // });

  return (
    <li class={styles.noteListItem} data-note-id={$noteId} style={props.$theme}>
      <button
        class={[
          styles.note,
          {
            [styles.highlight]: $highlight,
            [styles.focused]: $menuIsOpen,
            [styles.selected]: $isSelected,
          },
        ]}
        draggable
        onDragStart={(e) => {
          ctx.log("dragstart", e);

          e.dataTransfer!.setData("data/note-id", String($note.get().id));
          e.dataTransfer!.effectAllowed = "move";
        }}
        onClick={(e) => {
          e.preventDefault();
          props.onClick();
        }}
      >
        {cond(props.$charms, <div class={styles.charmsBar}>{props.$charms}</div>)}

        <div class={styles.noteLayout}>
          <div class={styles.noteText}>
            <span class={{ [styles.untitled]: $isUntitled, [styles.noteTitle]: $isTitled }} draggable={false}>
              {$title}
            </span>

            {cond(
              derive([$note], (n) => n.tags.length > 0),
              <ul class={styles.noteTagList}>
                {repeat(
                  derive([$note], (n) => [...n.tags].sort()),
                  (t) => t,
                  ($tag) => {
                    return <li class={styles.noteTag}>{$tag}</li>;
                  },
                )}
              </ul>,
            )}

            <ul class={styles.metaList}>
              <li>
                <div class={styles.metaIcon}>
                  <TimeIcon />
                </div>
                <span class={styles.metaLabel}>{$date}</span>
              </li>

              {cond(
                derive([$note], (n) => n.isPinned),
                <li>
                  <div class={styles.metaIcon}>
                    <PinIcon />
                  </div>
                  <span class={styles.metaLabel}>{translate("views.noteListItem.pinnedLabel")}</span>
                </li>,
              )}

              {cond(
                derive([$note], (n) => n.attachments.length > 0),
                <li>
                  <div class={styles.metaIcon}>
                    <Paperclip />
                  </div>
                  <span class={styles.metaLabel}>
                    {derive([$note], (n) => n.attachments.length)} (
                    {derive([$note], (n) =>
                      prettyBytes(n.attachments.reduce((sum, a) => sum + a.sizeInBytes, 0)),
                    )}
                    )
                  </span>
                </li>,
              )}
            </ul>
          </div>

          {cond(
            $interactive,
            <div class={styles.noteControls}>
              <MoreMenu
                $$open={signal.toSettable($menuIsOpen, setMenuIsOpen)}
                size="small"
                color={$projectColor}
                options={[
                  {
                    label: derive([$isPinned], (isPinned) =>
                      isPinned
                        ? translate("views.noteListItem.actions.pin.unpinLabel")
                        : translate("views.noteListItem.actions.pin.pinLabel"),
                    ),
                    icon: <PinIcon />,
                    callback: () => {
                      notes.setPinned($noteId.get(), !$isPinned.get());
                    },
                  },
                  {
                    label: translate("common.delete"),
                    variant: "destructive",
                    icon: <TrashIcon />,
                    callback: () => {
                      dialog.open(ConfirmDelete, {
                        message: translate("views.noteListItem.deleteDialog.message"),
                        itemName: $note.get().title,
                        onConfirm: () => {
                          const noteId = $noteId.get();
                          notes.deletePage(noteId).then(() => {
                            ctx.log("deleted note", noteId);
                          });
                        },
                      });
                    },
                  },
                ]}
              />
            </div>,
          )}
        </div>
      </button>
    </li>
  );
}
