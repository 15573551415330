import { derive, LanguageStore, RouterStore, ViewContext } from "@manyducks.co/dolla";
import { ProjectsStore } from "@stores/ProjectsStore";
import { PlaceLayout } from "Workspace/PlaceLayout/PlaceLayout";
// import NoteIcon from "@icons/Note";
import TaskIcon from "@icons/Task";
import CalendarIcon from "@icons/Calendar";
import PeopleIcon from "@icons/User";
import SettingsIcon from "@icons/Settings";
import OverviewIcon from "@icons/Coffee";
import NoteIcon from "@icons/Note";
import FilesIcon from "@icons/Paperclip";

export function Project(props: {}, ctx: ViewContext) {
  const { translate } = ctx.getStore(LanguageStore);
  const router = ctx.getStore(RouterStore);
  const projects = ctx.getStore(ProjectsStore);

  const $projectId = derive([router.$params], (params) =>
    params.projectId ? Number(params.projectId) : null,
  );
  const $project = derive([$projectId, projects.$cache], (id, projects) => projects.find((p) => p.id === id));

  ctx.watch([$projectId], (id) => {
    ctx.log("project id", id);
  });

  ctx.onConnected(() => {
    ctx.watch([$projectId], async (projectId) => {
      if (projectId != null) {
        const { exists } = await projects.ensureProjectLoaded(projectId);
        if (!exists) {
          return router.navigate("/");
        }
      }
    });
  });

  return (
    <PlaceLayout
      color={derive([$project], (p) => p?.color)}
      title={derive([$project], (p) => p?.name ?? "???")}
      tabs={[
        {
          icon: <OverviewIcon />,
          href: derive([$projectId], (id) => {
            ctx.log("project id changed: " + `/projects/${id}`);
            return `/projects/${id}`;
          }),
          active: (pattern) => pattern === "/projects/{#projectId}",
          text: translate("workspace.project.overview.tabName"),
        },
        {
          icon: <NoteIcon />,
          href: derive([$projectId], (id) => {
            ctx.log("project id changed: " + `/projects/${id}/notes`);
            return `/projects/${id}/notes`;
          }),
          active: (pattern) => pattern.startsWith("/projects/{#projectId}/notes"),
          text: translate("workspace.project.notes.tabName"),
        },
        {
          icon: <TaskIcon />,
          href: derive([$projectId], (id) => `/projects/${id}/tasks`),
          active: (pattern) => pattern.startsWith("/projects/{#projectId}/tasks"),
          text: translate("workspace.project.tasks.tabName"),
        },
        {
          icon: <CalendarIcon />,
          href: derive([$projectId], (id) => `/projects/${id}/calendar`),
          active: (pattern) => pattern.startsWith("/projects/{#projectId}/calendar"),
          text: translate("workspace.project.calendar.tabName"),
        },
        // {
        //   icon: <PeopleIcon />,
        //   href: derive([$projectId], (id) => `/projects/${id}/people`),
        //   active: (pattern) => pattern === "/projects/{#projectId}/people",
        //   text: translate("workspace.project.people.tabName"),
        // },
        // {
        //   icon: <SettingsIcon />,
        //   href: derive([$projectId], (id) => `/projects/${id}/settings`),
        //   active: (pattern) => pattern === "/projects/{#projectId}/settings",
        //   text: translate("workspace.project.settings.tabName"),
        // },
        {
          icon: <FilesIcon />,
          href: derive([$projectId], (id) => `/projects/${id}/files`),
          active: (pattern) => pattern === "/projects/{#projectId}/files",
          text: translate("workspace.project.files.tabName"),
        },
      ]}
    >
      {ctx.outlet()}
    </PlaceLayout>
  );
}
