import {
  cond,
  derive,
  HTTPStore,
  LanguageStore,
  repeat,
  RouterStore,
  signal,
  Signal,
  type ViewContext,
} from "@manyducks.co/dolla";
import styles from "./Home.module.css";
import { ProjectsStore } from "@stores/ProjectsStore";
import { ThemeStore } from "@stores/ThemeStore";
import { AuthStore } from "@stores/AuthStore";
import { Note, Project, Task } from "schemas";
import { ClockStore } from "@stores/ClockStore";
import { FavoriteListItem } from "./FavoriteListItem/FavoriteListItem";
import { NavStore } from "@stores/NavStore";
import { makeDebouncer } from "@helpers/makeDebouncer";
import { NoteListItem } from "@views/NoteListItem";
import { ProjectCharm } from "@views/Charms";
import { TaskListItem } from "@views/TaskListItem";
import { CardContent } from "@views/Card";
import { TasksStore } from "@stores/TasksStore";
import { addWeeks, subDays } from "date-fns";

interface GenericSearchResult<Type, Schema> {
  rank: number;
  type: Type;
  data: Schema;
}

export type SearchResult = NoteSearchResult | TaskSearchResult;
export type NoteSearchResult = GenericSearchResult<"note", Note>;
export type TaskSearchResult = GenericSearchResult<"task", Task>;

export function Home(props: {}, ctx: ViewContext) {
  const router = ctx.getStore(RouterStore);
  const auth = ctx.getStore(AuthStore);
  const theme = ctx.getStore(ThemeStore);
  const projects = ctx.getStore(ProjectsStore);
  const tasks = ctx.getStore(TasksStore);
  const clock = ctx.getStore(ClockStore);
  const nav = ctx.getStore(NavStore);
  const http = ctx.getStore(HTTPStore);
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);

  const $myTasks = derive([auth.$me, tasks.$cache], (me, tasks) => {
    if (me == null) {
      return [];
    }
    return [...tasks.values()].filter(
      (t) => t.assignedUserId === me.id && (t.dueDate != null || t.completedAt != null),
    );
  });

  function sortTasksByDateAsc(a: Task, b: Task) {
    if (a.dueDate! < b.dueDate!) {
      return -1;
    } else if (a.dueDate! > b.dueDate!) {
      return +1;
    } else {
      if (a.createdAt < b.createdAt) {
        return -1;
      } else if (a.createdAt > b.createdAt) {
        return +1;
      } else {
        return 0;
      }
    }
  }

  function dateToString(reference: Date) {
    const y = reference.getFullYear();
    const m = reference.getMonth() + 1;
    const d = reference.getDate();

    return `${y}-${m.toString().padStart(2, "0")}-${d.toString().padStart(2, "0")}`;
  }

  // Tasks that are due before today.
  const $overdueTasks = derive([$myTasks, clock.$hour], (tasks, _) => {
    const today = dateToString(new Date());
    return tasks.filter((t) => t.dueDate != null && t.dueDate < today).sort(sortTasksByDateAsc);
  });

  // Tasks that are due today.
  const $todayTasks = derive([$myTasks, clock.$hour], (tasks, _) => {
    const today = dateToString(new Date());
    return tasks.filter((t) => t.dueDate === today).sort(sortTasksByDateAsc);
  });

  // Tasks that are due within one week from today.
  const $upcomingTasks = derive([$myTasks], (tasks) => {
    const today = dateToString(new Date());
    const oneWeekFromNow = dateToString(addWeeks(new Date(), 1));
    return tasks
      .filter((t) => t.dueDate != null && t.dueDate > today && t.dueDate <= oneWeekFromNow)
      .sort(sortTasksByDateAsc);
  });

  // Tasks that have been finished in the last two days.
  const $recentlyFinishedTasks = derive([$myTasks], (tasks) => {
    const oldestRecentDate = dateToString(subDays(new Date(), 2));
    console.log(
      oldestRecentDate,
      tasks.filter((t) => t.completedAt != null),
    );
    return tasks
      .filter((t) => t.completedAt != null && t.completedAt >= oldestRecentDate)
      .sort((a, b) => {
        if (a.completedAt! < b.completedAt!) {
          return +1;
        } else if (a.completedAt! > b.completedAt!) {
          return -1;
        } else {
          return 0;
        }
      });
  });

  ctx.onMount(() => {
    tasks.fetchToDos();
  });

  const $sortedProjects = derive([projects.$cache], (projects) => {
    return projects
      .filter((p) => p.archivedAt == null)
      .sort((a, b) => {
        const aName = a.name.toLocaleLowerCase();
        const bName = b.name.toLocaleLowerCase();
        if (aName < bName) {
          return -1;
        } else if (aName > bName) {
          return +1;
        } else {
          return 0;
        }
      });
  });

  const $ownedProjects = derive([$sortedProjects, auth.$me], (projects, me) => {
    return projects.filter((p) => p.ownerId === me?.id);
  });

  const $joinedProjects = derive([$sortedProjects, auth.$me], (projects, me) => {
    return projects.filter((p) => p.ownerId !== me?.id);
  });

  const searchDebouncer = makeDebouncer(300);
  const [$searchTerm, setSearchTerm] = signal("");
  const [$searchResults, setSearchResults] = signal<SearchResult[]>([]);

  ctx.watch([$searchTerm], (value) => {
    searchDebouncer.queue(() => {
      http
        .get<SearchResult[]>(`/api/search?search=${encodeURIComponent(value)}&type=notes,tasks`)
        .then((res) => {
          ctx.log("received search response", res.body);
          setSearchResults(res.body);
        });
    });
  });

  return (
    <div class={styles.layout}>
      {/* <div class={styles.homeHeader}>
        <div class={styles.homeHeaderLayout}>
          <span class={styles.headerDayAndTime}>
            {derive([$currentLanguage, clock.$minute], (lang, _) => {
              const formatter = new Intl.DateTimeFormat([lang!, "en-US"], {
                hour: "numeric",
                minute: "2-digit",
                dayPeriod: "narrow",
                weekday: "short",
              });
              return formatter.format(new Date());
            })}
          </span>
        </div>
      </div> */}

      <div class={styles.header}>
        <span class={styles.headerDayAndTime}>
          {derive([$currentLanguage, clock.$minute], (lang, _) => {
            const formatter = new Intl.DateTimeFormat([lang!, "en-US"], {
              hour: "numeric",
              minute: "2-digit",
              dayPeriod: "narrow",
              weekday: "short",
            });
            return formatter.format(new Date());
          })}
        </span>
        <div class={styles.searchBox}>
          <input
            type="text"
            class={styles.search}
            placeholder={"Search everything..."}
            $$value={signal.toSettable($searchTerm, setSearchTerm)}
          />
        </div>
      </div>

      <div class={[styles.content]}>
        <div class={styles.contentWrap}>
          <div
            class={[
              styles.searchContent,
              { [styles.hidden]: derive([$searchTerm], (term) => term.trim() === "") },
            ]}
          >
            <ul class={styles.resultContent}>
              {repeat(
                $searchResults,
                (item) => item.data.id,
                ($item, $index, ctx) => {
                  return derive([$item], (item) => {
                    switch (item.type) {
                      case "note":
                        return (
                          <NoteListItem
                            interactive={false}
                            $note={derive([$item], (item) => item.data as Note)}
                            $charms={derive([$item, projects.$cache], (item, projects) => {
                              const project = projects.find((p) => p.id === item.data.projectId);

                              return (
                                <>
                                  <ProjectCharm project={project} />
                                  {/* {item.isPinned ? (
                                    <Charm icon={"📌"} label={translate("views.searchMenu.pinnedBadgeLabel")} />
                                  ) : null} */}
                                </>
                              );
                            })}
                            onClick={() => {
                              const note = $item.get().data as Note;
                              router.navigate(`/projects/${note.projectId}/notes/${note.id}`);
                              // props.onSelected($item.get());
                            }}
                          />
                        );
                      case "task":
                        return (
                          <TaskListItem
                            interactive={false}
                            $task={derive([$item], (i) => i.data as Task)}
                            $charms={derive([$item, projects.$cache, $currentLanguage], (item, projects) => {
                              const project = projects.find((p) => p.id === item.data.projectId);

                              return (
                                <>
                                  <ProjectCharm project={project} />
                                </>
                              );
                            })}
                            onClick={() => {
                              const task = $item.get().data as Task;
                            }}
                          />
                        );
                    }
                  });
                },
              )}
            </ul>
          </div>

          <div
            class={[
              styles.menuContent,
              { [styles.hidden]: derive([$searchTerm], (term) => term.trim() !== "") },
            ]}
          >
            <section class={styles.section}>
              <ul>
                {/* <li>
                <a href="/my-tasks">{translate("workspace.me.tasks.tabName")}</a>
              </li> */}
                <li>
                  <a href="/settings">{translate("workspace.settings.title")}</a>
                </li>
              </ul>
            </section>

            {cond(
              derive([$myTasks], (tasks) => tasks.length),
              <section class={styles.section}>
                <header>
                  <h2>My Tasks</h2>
                </header>

                {cond(
                  derive([$overdueTasks], (tasks) => tasks.length > 0),
                  <section class={styles.subsection}>
                    <header>
                      <h3>Overdue</h3>
                    </header>

                    <ul class={styles.taskList}>
                      {repeat(
                        $overdueTasks,
                        (t) => t.id,
                        ($task) => (
                          <TaskListItem $task={$task} card showProjectName />
                        ),
                      )}
                    </ul>
                  </section>,
                )}

                {cond(
                  derive([$todayTasks], (tasks) => tasks.length > 0),
                  <section class={styles.subsection}>
                    <header>
                      <h3>Today</h3>
                    </header>

                    <ul class={styles.taskList}>
                      {repeat(
                        $todayTasks,
                        (t) => t.id,
                        ($task) => (
                          <TaskListItem $task={$task} card showProjectName />
                        ),
                      )}
                    </ul>
                  </section>,
                )}

                {cond(
                  derive([$upcomingTasks], (tasks) => tasks.length > 0),
                  <section class={styles.subsection}>
                    <header>
                      <h3>Upcoming</h3>
                    </header>

                    <ul class={styles.taskList}>
                      {repeat(
                        $upcomingTasks,
                        (t) => t.id,
                        ($task) => (
                          <TaskListItem $task={$task} card showProjectName />
                        ),
                      )}
                    </ul>
                  </section>,
                )}

                {cond(
                  derive([$recentlyFinishedTasks], (tasks) => tasks.length > 0),
                  <section class={styles.subsection}>
                    <header>
                      <h3>Recently Finished</h3>
                    </header>

                    <ul class={styles.taskList}>
                      {repeat(
                        $recentlyFinishedTasks,
                        (t) => t.id,
                        ($task) => (
                          <TaskListItem $task={$task} card showProjectName />
                        ),
                      )}
                    </ul>
                  </section>,
                )}
              </section>,
            )}

            <div class={styles.splitIfWideEnough}>
              <section class={styles.section}>
                <header>
                  <h2>Favorites</h2>
                </header>

                {cond(
                  derive([nav.$favorites], (f) => f.length == 0),
                  <span>Notes and tasks marked as favorites will appear here.</span>,
                  <ul class={styles.tileList}>
                    {repeat(
                      nav.$favorites,
                      (f) => f.id,
                      ($favorite) => (
                        <FavoriteListItem $favorite={$favorite} />
                      ),
                    )}
                  </ul>,
                )}
              </section>
            </div>

            <section class={styles.section}>
              <header>
                <h2>Projects</h2>
              </header>

              {/* <section class={styles.subsection}>
    <header>
      <h3>Invites</h3>
    </header>

    <ul></ul>
  </section> */}

              <section class={styles.subsection}>
                <header>
                  <h3>Owned</h3>
                </header>

                <ul class={styles.tileList}>
                  {repeat(
                    $ownedProjects,
                    (p) => p.id,
                    ($project) => {
                      return <ProjectTile $project={$project} />;
                    },
                  )}
                </ul>
              </section>

              <section class={styles.subsection}>
                <header>
                  <h3>Joined</h3>
                </header>

                <ul class={styles.tileList}>
                  {repeat(
                    $joinedProjects,
                    (p) => p.id,
                    ($project) => {
                      return <ProjectTile $project={$project} />;
                    },
                  )}
                </ul>
              </section>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

interface ProjectTileProps {
  $project: Signal<Project>;
}

function ProjectTile(props: ProjectTileProps, ctx: ViewContext) {
  const theme = ctx.getStore(ThemeStore);
  const { $project } = props;

  const $theme = derive([$project], (p) => theme.getTheme$(p?.color));

  return (
    <li style={$theme}>
      <a class={styles.projectLink} href={derive([$project], (p) => `/projects/${p.id}`)}>
        <span class={styles.projectTitle}>{derive([$project], (p) => p.name)}</span>

        <ul class={styles.projectMeta}>
          <li>
            {derive([$project], (p) => {
              if (p.users.length === 1) {
                return `Just you`;
              } else {
                return `${p.users.length} people`;
              }
            })}
          </li>
          <li>
            {derive([$project], (p) => {
              return `47 tasks`;
            })}
          </li>
          <li>
            {derive([$project], (p) => {
              return `91 notes`;
            })}
          </li>
        </ul>
      </a>
    </li>
  );
}
