import { arrow, autoUpdate, computePosition, flip, offset, Placement, shift } from "@floating-ui/dom";
import {
  cond,
  derive,
  type MaybeSignal,
  portal,
  Ref,
  ref,
  type SettableSignal,
  type Signal,
  signal,
  signalify,
  type ViewContext,
} from "@manyducks.co/dolla";
import { ThemeStore } from "@stores/ThemeStore";
import styles from "./HoverMenu.module.css";

export interface Point {
  x: number;
  y: number;
}

export type HoverMenuHorizontalAlignment = "left" | "right" | "center";
export type HoverMenuVerticalAlignment = "above" | "below";

interface HoverMenuProps {
  $$open: SettableSignal<boolean>;

  /**
   * Optional accent color.
   */
  color?: string | Signal<string> | Signal<string | undefined>;

  /**
   * The anchor element that the notch points to.
   */
  anchorRef: Ref<HTMLElement>;

  /**
   * Distance from anchor point.
   */
  distanceFromAnchor?: MaybeSignal<number>;

  /**
   * Prefer to align menu left, right, or center of anchor if space allows.
   */
  preferHorizontalAlignment?: MaybeSignal<HoverMenuHorizontalAlignment>;

  /**
   * Prefer to align menu above or below the anchor if space allows.
   */
  preferVerticalAlignment?: MaybeSignal<HoverMenuVerticalAlignment>;

  closeOnClickOutside?: MaybeSignal<boolean>;

  onClose?: () => void;
}

const NOTCH_INSET = 20;

export function HoverMenu(props: HoverMenuProps, ctx: ViewContext) {
  const $closeOnClickOutside = signalify(props.closeOnClickOutside);
  const $preferHorizontalAlignment = signalify(props.preferHorizontalAlignment ?? "left");
  const $preferVerticalAlignment = signalify(props.preferVerticalAlignment ?? "below");
  const $distanceFromAnchor = signalify(props.distanceFromAnchor ?? 0);

  const render = ctx.getStore("render");
  const theme = ctx.getStore(ThemeStore);

  const [$showMenu, setShowMenu] = signal(false);
  const [$scaled, setScaled] = signal(false);
  let isClosing = false;

  async function close() {
    if (isClosing) return;
    isClosing = true;
    props.$$open.set(false);
    props.onClose?.();
    setScaled(false);
    setTimeout(() => {
      setShowMenu(false);
      isClosing = false;
    }, 350);
  }

  ctx.watch([props.$$open], (open) => {
    if (open) {
      setShowMenu(true);
      render.read(() => {
        setScaled(true);
      });
    } else {
      close();
      if (props.onClose) {
        props.onClose();
      }
    }
  });

  const $themeStyles = theme.getTheme$(props.color);

  const menuElement = ref<HTMLDivElement>();
  const notchElement = ref<HTMLDivElement>();

  const [$positionInfo, setPositionInfo] = signal({
    horizontalAlignment: $preferHorizontalAlignment.get(),
    verticalAlignment: $preferVerticalAlignment.get(),
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    notchOffset: 0,
    notchAdjustment: 0,
  });

  let cleanup: (() => void) | undefined;

  function updatePosition() {
    const anchorEl = props.anchorRef.get();
    const menuEl = menuElement.node;
    const notchEl = notchElement.node!;

    if (!$showMenu.get() || !anchorEl || !menuEl) return;

    const vAlign = $preferVerticalAlignment.get();
    const hAlign = $preferHorizontalAlignment.get();
    const distance = $distanceFromAnchor.get();

    let placement = vAlign === "above" ? "top" : "bottom";
    let offsetOptions = { mainAxis: distance ?? 0, crossAxis: 0 };
    if (hAlign === "left") {
      offsetOptions.crossAxis = 8;
      placement += "-end";
    } else if (hAlign === "right") {
      offsetOptions.crossAxis = -8;
      placement += "-start";
    }

    computePosition(anchorEl, menuEl, {
      placement: placement as Placement,
      middleware: [offset(offsetOptions), shift(), flip({ crossAxis: false }), arrow({ element: notchEl })],
    }).then(({ x, y, middlewareData }) => {
      const menuRect = menuEl.getBoundingClientRect();
      setPositionInfo((current) => ({
        ...current,
        x,
        y,
        width: menuRect.width,
        height: menuRect.height,
        notchOffset: middlewareData.arrow?.x ?? 0,
        verticalAlignment: vAlign === "below" && middlewareData.flip?.index ? "above" : vAlign,
      }));
    });
  }

  // Adjust above/below and left/right positioning based on proximity to screen edge.
  ctx.watch(
    [props.$$open, props.anchorRef, menuElement, $distanceFromAnchor],
    (open, anchorEl, menuEl, _distance) => {
      if (!open || !anchorEl) {
        if (cleanup) {
          cleanup();
          cleanup = undefined;
        }
        return;
      }

      if (menuEl) {
        cleanup = autoUpdate(anchorEl, menuEl, updatePosition);
      }
    },
  );

  ctx.beforeDisconnect(() => {
    if (cleanup) {
      cleanup();
    }
  });

  return cond(
    $showMenu,
    portal(
      <div
        class={styles.container}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if ($closeOnClickOutside.get() !== false) {
            close();
          }
        }}
        style={$themeStyles}
      >
        <div
          ref={menuElement}
          class={styles.menu}
          style={{
            transform: derive([$positionInfo], ({ x, y }) => `translate(${x}px, ${y}px)`),
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div
            class={[styles.bubble, { [styles.open]: $scaled }]}
            style={{
              transformOrigin: derive([$distanceFromAnchor, $positionInfo], (d, p) => {
                let x = 0;
                let y = 0;

                if (p.verticalAlignment === "above") {
                  y = p.height + 5;
                } else {
                  y = -5;
                }

                if (p.horizontalAlignment === "left") {
                  x = p.width - NOTCH_INSET - p.notchAdjustment - 4;
                } else if (p.horizontalAlignment === "right") {
                  x = NOTCH_INSET - p.notchAdjustment + 4;
                } else {
                  x = p.width / 2 - p.notchAdjustment;
                }

                return `${x}px ${y}px`;
              }),
            }}
          >
            <div class={styles.content}>{ctx.outlet()}</div>
            <div
              ref={notchElement}
              class={styles.notch}
              style={derive([$positionInfo], ({ notchOffset, verticalAlignment }) => ({
                transform: `translateX(${notchOffset}px) ${
                  verticalAlignment === "below" ? `rotate(180deg)` : ""
                }`,
                top: verticalAlignment === "below" ? "-6px" : "unset",
                bottom: verticalAlignment === "above" ? "-6px" : "unset",
              }))}
            >
              <div class={styles.shape} />
            </div>
          </div>
        </div>
      </div>,
      document.body,
    ),
  );
}
