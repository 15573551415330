export default {
  "container": "Details_container_3063e",
  "emptyPlaceholder": "Details_emptyPlaceholder_3063e",
  "loader": "Details_loader_3063e",
  "show": "Details_show_3063e",
  "navHeader": "Details_navHeader_3063e",
  "meta": "Details_meta_3063e",
  "metaGroup": "Details_metaGroup_3063e",
  "mobileBackButtonGroup": "Details_mobileBackButtonGroup_3063e",
  "metaButton": "Details_metaButton_3063e",
  "active": "Details_active_3063e",
  "open": "Details_open_3063e",
  "metaButtonIcon": "Details_metaButtonIcon_3063e",
  "metaButtonLabel": "Details_metaButtonLabel_3063e",
  "metaDivider": "Details_metaDivider_3063e",
  "metaContent": "Details_metaContent_3063e",
  "metaSection": "Details_metaSection_3063e",
  "metaSubSection": "Details_metaSubSection_3063e",
  "metaAttachmentsList": "Details_metaAttachmentsList_3063e",
  "metaAttachmentUpload": "Details_metaAttachmentUpload_3063e",
  "metaAttachmentUploadInput": "Details_metaAttachmentUploadInput_3063e",
  "metaAttachmentUploadLabel": "Details_metaAttachmentUploadLabel_3063e",
  "outlineList": "Details_outlineList_3063e",
  "outlineItem": "Details_outlineItem_3063e",
  "metaCheckbox": "Details_metaCheckbox_3063e",
  "metaCheckboxLabel": "Details_metaCheckboxLabel_3063e",
  "timestampList": "Details_timestampList_3063e",
  "timestampListItem": "Details_timestampListItem_3063e",
  "timestampListItemMain": "Details_timestampListItemMain_3063e",
  "timestampListItemSecondary": "Details_timestampListItemSecondary_3063e",
  "content": "Details_content_3063e"
};import "ni:sha-256;_HPmO-zQrhcloCVB59e6Bsn3YotoYseJQdyFPcXfm2M";