export default {
  "layout": "Workspace_layout_4d11b",
  "main": "Workspace_main_4d11b",
  "chat": "Workspace_chat_4d11b",
  "open": "Workspace_open_4d11b",
  "chatContent": "Workspace_chatContent_4d11b",
  "chatTab": "Workspace_chatTab_4d11b",
  "chatTabIcon": "Workspace_chatTabIcon_4d11b",
  "chatTabLabel": "Workspace_chatTabLabel_4d11b",
  "chatTabClickZone": "Workspace_chatTabClickZone_4d11b",
  "chatTabMeta": "Workspace_chatTabMeta_4d11b",
  "chatTabMetaName": "Workspace_chatTabMetaName_4d11b",
  "animating": "Workspace_animating_4d11b"
};import "ni:sha-256;4f-VQ-m-jU0DIKTadNnoBCsOYYcvZZlqSGRhLfimhiw";