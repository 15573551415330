import { sticky } from "@helpers/sticky";
import { derive, HTTPStore, signal, StoreContext } from "@manyducks.co/dolla";
import { Favorite, File } from "schemas";
import { BreakpointStore } from "./BreakpointStore";

export function NavStore(ctx: StoreContext) {
  const http = ctx.getStore(HTTPStore);
  const breakpoint = ctx.getStore(BreakpointStore);

  /*=================================*\
  |*            Favorites            *|
  \*=================================*/

  const [$favorites, setFavorites] = signal<Favorite[]>([]);

  async function fetchFavorites() {
    const res = await http.get<Favorite[]>("/api/favorites");
    setFavorites(res.body);
  }

  async function addFavorite(path: string) {
    let highestOrder = 0;
    for (const favorite of $favorites.get()) {
      if (favorite.order > highestOrder) {
        highestOrder = favorite.order;
      }
    }
    const res = await http.post<Favorite>("/api/favorites", { body: { path } });
    setFavorites((current) => [...current, res.body]);
  }

  async function removeFavorite(id: number) {
    setFavorites((current) => current.filter((f) => f.id !== id));

    await http.delete(`/api/favorites/${id}`);
  }

  async function reorderFavorites(favorites: Favorite[]) {
    // TODO: Implement reordering
    localStorage.setItem("favorites", JSON.stringify($favorites.get()));
  }

  /*=================================*\
  |*           File Viewer           *|
  \*=================================*/

  const [$fileViewerIsOpen, setFileViewerIsOpen] = signal(false);
  const [$fileViewerContent, setFileViewerContent] = signal<File>();

  function openFileViewer(file: File) {
    setFileViewerContent(file);
    setFileViewerIsOpen(true);
  }

  function closeFileViewer() {
    setFileViewerIsOpen(false);
  }

  /*=================================*\
  |*               Chat              *|
  \*=================================*/

  const [$chatIsOpen, setChatIsOpen] = signal(localStorage.getItem("chat.isOpen") === "true");

  function openChat() {
    setChatIsOpen(true);
  }

  function closeChat() {
    setChatIsOpen(false);
  }

  function toggleChat() {
    setChatIsOpen((current) => !current);
  }

  ctx.watch([$chatIsOpen], (value) => {
    localStorage.setItem("chat.isOpen", JSON.stringify(value));
  });

  return {
    $favorites,
    fetchFavorites,
    addFavorite,
    removeFavorite,
    reorderFavorites,

    $fileViewerIsOpen: derive(
      [$fileViewerIsOpen, $fileViewerContent],
      (open, content) => open && content != null,
    ),
    $fileViewerContent,
    openFileViewer,
    closeFileViewer,

    $chatIsOpen,
    openChat,
    closeChat,
    toggleChat,
  };
}
