import { relativePastTime } from "@helpers/relativePastTime";
import { Synchronizer } from "@helpers/Synchronizer";
import {
  cond,
  derive,
  DialogStore,
  HTTPStore,
  LanguageStore,
  ref,
  repeat,
  RouterStore,
  signal,
  Signal,
  type ViewContext,
} from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { FilesStore } from "@stores/FilesStore";
import { IOStore } from "@stores/IOStore";
import { NotesStore } from "@stores/NotesStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { ConfirmDelete } from "@views/@dialogs";
import EditIcon from "@icons/Edit";
import Outline from "@icons/Outline";
import Paperclip from "@icons/Paperclip";
import Pin from "@icons/Pin";
import PinSolid from "@icons/PinSolid";
import PlusIcon from "@icons/Plus";
import TagIcon from "@icons/Tag";
import TimeIcon from "@icons/Time";
import TrashIcon from "@icons/Trash";
import { ColorDivider } from "@views/ColorDivider";
import { DocMetaMenu } from "@views/DocMeta";
import { DuckLoader, makeLoaderState } from "@views/DuckLoader";
import { FileAttachment } from "@views/FileAttachment";
import { Point } from "@views/HoverMenu";
import { MoreMenu } from "@views/MoreMenu";
import { Switch } from "@views/Switch";
import { TagInput } from "@views/TagInput";
import { TextEditor } from "@views/TextEditor";
import { produce } from "immer";
import { Note, Project } from "schemas";
import { InputFile } from "Workspace/Chat/ChatEditor/ChatEditor";
import { Awareness } from "y-protocols/awareness";
import * as Y from "yjs";
import styles from "./Details.module.css";
import HeartLine from "@icons/HeartLine";
import Heart from "@icons/Heart";
import { NavStore } from "@stores/NavStore";
import { IconButton } from "@views/IconButton";
import ArrowLeft from "@icons/ArrowLeft";

type MetaSection = "attachments" | "tags" | "outline" | "timestamps";

interface DetailsProps {
  $project: Signal<Project | undefined>;
  $note: Signal<Note | undefined>;
}

export function Details(props: DetailsProps, ctx: ViewContext) {
  ctx.name = "ProjectNotes/Details";

  const auth = ctx.getStore(AuthStore);
  const router = ctx.getStore(RouterStore);
  const notes = ctx.getStore(NotesStore);
  const dialog = ctx.getStore(DialogStore);
  const projects = ctx.getStore(ProjectsStore);
  const io = ctx.getStore(IOStore);
  const http = ctx.getStore(HTTPStore);
  const nav = ctx.getStore(NavStore);
  const { uploadFile } = ctx.getStore(FilesStore);
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);

  const $projectId = derive([props.$project], (n) => n?.id);
  const $tags = derive([props.$note], (n) => (n ? [...n.tags].sort() : []));
  const $tagCount = derive([$tags], (t) => t.length);
  const $attachments = derive([props.$note], (n) => n?.attachments ?? []);
  const $attachmentCount = derive([$attachments], (a) => a.length);

  const metaContainer = ref<HTMLElement>();
  const [$openMetaSection, setOpenMetaSection] = signal<MetaSection>();
  const [$menuAnchor, setMenuAnchor] = signal<Point>({ x: 0, y: 0 });
  const [$fileUploads, setFileUploads] = signal<InputFile[]>([]);

  const $favorite = derive([nav.$favorites, props.$note], (favorites, note) =>
    favorites.find((f) => f.path.startsWith(`/projects/${note?.projectId}/notes/${note?.id}`)),
  );

  const $userRole = derive([$projectId, projects.$cache, auth.$me], (projectId, projectsCache, me) => {
    const project = projectsCache.find((p) => p.id === projectId);
    if (!project || !me || project.archivedAt != null) return "viewer";
    const user = project.users.find((u) => u.id === me.id);
    return user?.role ?? "viewer";
  });

  const $isViewer = derive([$userRole], (role) => role === "viewer");

  function setAnchor(e: Event) {
    const containerRect = metaContainer.node!.getBoundingClientRect();
    const rect = (e.currentTarget as HTMLElement).getBoundingClientRect();

    setMenuAnchor({
      x: rect.left + rect.width / 2 - containerRect.left,
      y: rect.top + rect.height / 2 - containerRect.top,
    });
  }

  function addFiles(files: FileList | File[]) {
    for (const file of Array.from(files)) {
      const events = uploadFile(file);
      let uploadId: string;

      events.on("started", (e) => {
        ctx.log("upload started", e);
        uploadId = e.data.uploadId;
        const inputFile: InputFile = {
          file,
          uploadId,
          progress: 0,
          complete: false,
          events,
        };
        setFileUploads(
          produce((current) => {
            current.push(inputFile);
          }),
        );
      });
      events.on("progress", (e) => {
        ctx.log("upload progress", e);
        setFileUploads(
          produce((current) => {
            const found = current.find((f) => f.uploadId === uploadId);
            if (found) {
              found.progress = e.data.percent;
            }
          }),
        );
      });
      events.on("error", (e) => {
        ctx.log("upload error", e);
        setFileUploads(
          produce((current) => {
            const found = current.find((f) => f.uploadId === uploadId);
            if (found) {
              found.error = e.data.error;
            }
          }),
        );
      });
      events.on("complete", (e) => {
        ctx.log("upload complete", e);

        const note = props.$note.get()!;
        http
          .post(`/api/notes/${note.id}/attachments`, { body: { uuids: [uploadId] } })
          .then((res) => {
            ctx.info("Attachment added", res);
            setFileUploads(
              produce((current) => {
                return current.filter((f) => f.uploadId !== uploadId);
              }),
            );
          })
          .catch((err) => {
            ctx.error(err);
          });
      });
    }
  }

  const $$menuIsOpen = signal.settable(false);

  const loader = makeLoaderState(false);

  // Stores the current connection info for the selected doc.
  const [$noteConnectionDetails, setNoteConnectionDetails] = signal<{
    id: number;
    ydoc: Y.Doc;
    awareness: Awareness;
    sync: Synchronizer;
  } | null>();

  ctx.watch([props.$note, $noteConnectionDetails], (note, connection) => {
    if (note == null && connection != null) {
      // Clean up existing connection before opening a new one.
      connection.ydoc.off("update", updateLocalData);
      connection.sync.disconnect();

      setNoteConnectionDetails(null);
    }
  });

  // Keep synchronizer edit mode setting up to date with card state
  ctx.watch([$noteConnectionDetails, $userRole], (details, role) => {
    if (details) {
      details.sync.setEditMode(role === "viewer" ? false : true);
    }
  });

  // Keep awareness user up to date with user settings
  ctx.watch([$noteConnectionDetails, auth.$me], (details, me) => {
    if (details && me) {
      details.awareness.setLocalStateField("user", {
        name: me.name,
        color: me.color,
      });
    }
  });

  // Update local doc metadata when the document changes.
  function updateLocalData() {
    const details = $noteConnectionDetails.get();
    if (details) {
      notes.updatePage(details.id, details.ydoc.getText("content"));
    }
  }

  // Configure the document and socket connection before opening details view.
  async function prepareNote(id: number) {
    ctx.info("preparing note " + id);

    const { exists } = await notes.ensureNoteIsLoaded(id);

    if (!exists) {
      ctx.error("Note not found");
      return router.navigate(`/projects/${$projectId.get()}/notes`);
    }

    const currentDetails = $noteConnectionDetails.get();

    if (currentDetails) {
      if (currentDetails.id === id) {
        return; // We are already connected
      } else {
        // Clean up existing connection before opening a new one.
        currentDetails.ydoc.off("update", updateLocalData);
        currentDetails.sync.disconnect();
      }
    }

    const ydoc = new Y.Doc();
    const awareness = new Awareness(ydoc);
    const socket = io.socket(`/notes/${id}`);
    const sync = new Synchronizer(socket, ydoc, awareness);

    await sync.connect();

    ydoc.on("update", updateLocalData);

    setNoteConnectionDetails({ id, ydoc, awareness, sync });
  }

  ctx.watch([props.$note, $noteConnectionDetails], async (note, details) => {
    if (note != null && note.id != details?.id) {
      ctx.info("NOTE MISMATCH; PREPARING", note);
      await loader.show();
      await prepareNote(note.id);
      await loader.hide();
    }
  });

  interface OutlineHeading {
    title: string;
    anchor: string;
    level: number;
    parent?: OutlineHeading;
    children: OutlineHeading[];
  }

  const [$headings, setHeadings] = signal<OutlineHeading[]>([]);

  // TODO: Parse note into OutlineHeading array
  function updateHeadings() {
    const headings: OutlineHeading[] = [];

    let buffer: string = "";
    let level: number = 1;

    const details = $noteConnectionDetails.get();
    const delta = details?.ydoc.getText("content").toDelta();

    console.log(delta);

    for (const op of delta) {
      if (typeof op.insert === "string") {
        if (op.insert === "\n" && typeof op.attributes?.header == "number") {
          if (op.attributes.header > level) {
            // Starting subheading
            level = op.attributes.header;
          } else if (op.attributes.header <= level) {
            // Higher level heading
            level = op.attributes.header;
          }

          // Heading terminator. Load buffer as heading.
          if (buffer.length > 0) {
            headings.push({
              title: buffer,
              anchor: "",
              level,
              children: [],
            });
          }
          buffer = "";
        } else {
          buffer = op.insert.split("\n").at(-1);
        }
      }
    }

    setHeadings(headings);

    return headings;
  }

  return cond(
    props.$note,
    <div class={styles.container}>
      {/* <header class={styles.navHeader}>
        <IconButton onClick={() => {}}>
          <ArrowLeft />
        </IconButton>
        <h1>Notes/{derive([props.$note], (n) => n?.title)}</h1>
      </header> */}
      <div class={styles.meta} ref={metaContainer}>
        <div class={styles.metaGroup}>
          {/* <div class={styles.mobileBackButtonGroup}>
              <IconButton label={"Back"}>
                <ArrowLeft />
              </IconButton>

              <div class={styles.metaDivider} />
            </div> */}

          {/* <div class={styles.metaGroup}> */}
          <button
            class={[
              styles.metaButton,
              {
                [styles.active]: $attachmentCount,
                [styles.open]: derive([$openMetaSection], (s) => s === "attachments"),
              },
            ]}
            title={derive([$attachments], (attachments) => {
              if (attachments.length > 0) {
                return translate("workspace.project.notes.details.meta.attachmentsLabel_value", {
                  value: attachments.length,
                });
              } else {
                return translate("workspace.project.notes.details.meta.attachmentsLabel_empty");
              }
            })}
            disabled={$isViewer}
            onClick={(e) => {
              setAnchor(e);
              setOpenMetaSection((current) => (current === "attachments" ? undefined : "attachments"));
            }}
          >
            <div class={styles.metaButtonIcon}>
              <Paperclip />
            </div>
            {cond($attachmentCount, <span class={styles.metaButtonLabel}>{$attachmentCount}</span>)}
          </button>

          {/* Tags */}

          <button
            class={[
              styles.metaButton,
              {
                [styles.active]: $tagCount,
                [styles.open]: derive([$openMetaSection], (s) => s === "tags"),
              },
            ]}
            title={derive([$tags], (tags) => {
              if (tags.length > 0) {
                return translate("workspace.project.notes.details.meta.tagsLabel_value", {
                  value: tags.join(", "),
                });
              } else {
                return translate("workspace.project.notes.details.meta.tagsLabel_empty");
              }
            })}
            disabled={$isViewer}
            onClick={(e) => {
              setAnchor(e);
              setOpenMetaSection((current) => (current === "tags" ? undefined : "tags"));
            }}
          >
            <div class={styles.metaButtonIcon}>
              <TagIcon />
            </div>
            {cond($tagCount, <span class={styles.metaButtonLabel}>{$tagCount}</span>)}
          </button>

          {/* Outline */}

          <button
            class={[styles.metaButton, { [styles.open]: derive([$openMetaSection], (s) => s === "outline") }]}
            title={translate("workspace.project.notes.details.meta.outlineLabel")}
            onClick={(e) => {
              setAnchor(e);
              updateHeadings();
              setOpenMetaSection((current) => (current === "outline" ? undefined : "outline"));
            }}
          >
            <div class={styles.metaButtonIcon}>
              <Outline />
            </div>
          </button>

          {/* Timestamps */}

          <button
            class={[
              styles.metaButton,
              { [styles.open]: derive([$openMetaSection], (s) => s === "timestamps") },
            ]}
            title={translate("workspace.project.notes.details.meta.timestampsLabel")}
            onClick={(e) => {
              setAnchor(e);
              setOpenMetaSection((current) => (current === "timestamps" ? undefined : "timestamps"));
            }}
          >
            <div class={styles.metaButtonIcon}>
              <TimeIcon />
            </div>
          </button>
        </div>

        {/* <div class={styles.metaDivider} /> */}

        <div class={styles.metaGroup}>
          <Switch
            $value={derive([$favorite], (f) => f != null)}
            offIcon={<HeartLine />}
            onIcon={<Heart />}
            // title={}
            onChange={(value) => {
              const f = $favorite.get();
              const note = props.$note.get()!;
              if (!value && f) {
                nav.removeFavorite(f.id);
              } else if (value && !f) {
                nav.addFavorite(`/projects/${note.projectId}/notes/${note.id}`);
              }
            }}
          />
          <Switch
            $value={derive([props.$note], (x) => x?.isPinned ?? false)}
            offIcon={<Pin />}
            onIcon={<PinSolid />}
            onChange={(value) => {
              notes.setPinned(props.$note.get()!.id, value);
            }}
          />
          {cond(
            derive([$isViewer], (isViewer) => !isViewer),
            <MoreMenu
              $$open={$$menuIsOpen}
              preferVerticalAlignment="below"
              preferHorizontalAlignment="left"
              color={derive([props.$project], (p) => p?.color)}
              options={[
                {
                  label: translate("common.delete"),
                  variant: "destructive",
                  icon: <TrashIcon />,
                  callback: () => {
                    const note = props.$note.get()!;

                    dialog.open(ConfirmDelete, {
                      message: translate("workspace.project.notes.deleteDialog.message"),
                      itemName: note.title,
                      onConfirm: function () {
                        notes
                          .deletePage(note.id)
                          .catch((err) => ctx.error(err))
                          .then(() => {
                            router.navigate(`/projects/${note.projectId}/notes`);
                          });
                      },
                    });
                  },
                },
              ]}
            />,
          )}
        </div>
      </div>

      {/* Menu: Attachments */}

      <DocMetaMenu
        $anchorPoint={$menuAnchor}
        $open={derive([$openMetaSection], (s) => s === "attachments")}
        onClose={() => {
          setOpenMetaSection(undefined);
        }}
      >
        <section class={[styles.metaSection, styles.metaAttachmentsSection]}>
          <label>{translate("workspace.project.notes.details.meta.attachmentsTitle")}</label>
          <ul class={styles.metaAttachmentsList}>
            {repeat(
              $attachments,
              (a) => a.id,
              ($a) => (
                <li>
                  <FileAttachment
                    $attachment={$a}
                    onDelete={(file) => {
                      const note = props.$note.get()!;
                      http.delete(`/api/notes/${note.id}/attachments/${file.id}`);
                    }}
                  />
                </li>
              ),
            )}
          </ul>
          <div class={styles.metaAttachmentUpload}>
            <input
              id="attachmentUploadInput"
              class={styles.metaAttachmentUploadInput}
              type="file"
              multiple
              onChange={(e) => {
                const target = e.currentTarget as HTMLInputElement;
                const files = Array.from(target.files ?? []);

                addFiles(files);
              }}
            />
            <label for="attachmentUploadInput" class={styles.metaAttachmentUploadLabel}>
              {translate("workspace.project.notes.details.meta.attachmentsUploadLabel")}
            </label>
          </div>
        </section>
      </DocMetaMenu>

      {/* Menu: Tags */}

      <DocMetaMenu
        $anchorPoint={$menuAnchor}
        $open={derive([$openMetaSection], (s) => s === "tags")}
        onClose={() => {
          setOpenMetaSection(undefined);
        }}
      >
        <section class={styles.metaSection}>
          <label>{translate("workspace.project.notes.details.meta.tagsTitle")}</label>
          <TagInput
            $value={$tags}
            onChange={({ tags }) => {
              const note = props.$note.get()!;
              notes.setTags(note.id, tags);
            }}
          />
        </section>
      </DocMetaMenu>

      {/* Menu: Outline */}

      <DocMetaMenu
        $anchorPoint={$menuAnchor}
        $open={derive([$openMetaSection], (s) => s === "outline")}
        onClose={() => {
          setOpenMetaSection(undefined);
        }}
      >
        <section class={styles.metaSection}>
          {cond(
            derive([$headings], (h) => h.length > 0),
            <ul class={styles.outlineList}>
              {repeat(
                $headings,
                (h, i) => i,
                ($heading) => {
                  return (
                    <li class={styles.outlineItem} data-level={derive([$heading], (h) => h.level)}>
                      <a href={derive([$heading], (h) => h.anchor)}>{derive([$heading], (h) => h.title)}</a>
                    </li>
                  );
                },
              )}
            </ul>,
            <p class={styles.emptyMessage}>No headings.</p>,
          )}
        </section>
      </DocMetaMenu>

      {/* Menu: Timestamps */}

      <DocMetaMenu
        $anchorPoint={$menuAnchor}
        $open={derive([$openMetaSection], (s) => s === "timestamps")}
        onClose={() => {
          setOpenMetaSection(undefined);
        }}
      >
        <section class={styles.metaSection}>
          <ul class={styles.timestampList}>
            <li class={styles.timestampListItem}>
              <div class={styles.timestampListItemMain}>
                <PlusIcon />
                <span>
                  {derive([props.$note, $currentLanguage], (note, lang) => {
                    if (note == null) return;

                    const timestamp = relativePastTime(lang!, note.createdAt);
                    if (timestamp) {
                      return translate("relativeTime.createdAtTimestamp", { timestamp }).get();
                    } else {
                      return translate("relativeTime.createdNow").get();
                    }
                  })}
                </span>
              </div>
              <span class={styles.timestampListItemSecondary}>
                {derive([props.$note, $currentLanguage], (note, lang) => {
                  if (note == null) return;

                  return formatTimestamp(note.createdAt, lang!);
                })}
              </span>
            </li>

            <li class={[styles.timestampListItem]}>
              <div class={styles.timestampListItemMain}>
                <EditIcon />
                <span>
                  {derive([props.$note, $currentLanguage], (note, lang) => {
                    if (note == null) return;

                    const timestamp = relativePastTime(lang!, note.updatedAt);
                    if (timestamp) {
                      return translate("relativeTime.updatedAtTimestamp", { timestamp }).get();
                    } else {
                      return translate("relativeTime.updatedNow").get();
                    }
                  })}
                </span>
              </div>
              <span class={styles.timestampListItemSecondary}>
                {derive([props.$note, $currentLanguage], (note, lang) => {
                  if (note == null) return;

                  return formatTimestamp(note.updatedAt, lang!);
                })}
              </span>
            </li>
          </ul>
        </section>
      </DocMetaMenu>

      {/* <ColorDivider /> */}

      <div class={styles.content}>
        {derive([$noteConnectionDetails], (details) => {
          ctx.info("text editor re-rendered", details);

          if (details) {
            return (
              <TextEditor
                content={details.ydoc.getText("content")}
                accentColor={derive([props.$project], (p) => p?.color ?? "#888")}
                awareness={details.awareness}
                readOnly={$isViewer}
                scrollable
              />
            );
          }
        })}
      </div>

      <DuckLoader state={loader} />
    </div>,
    <div class={styles.emptyPlaceholder}>Select a note.</div>,
  );
}

function formatTimestamp(date: Date | string, lang: string) {
  return new Intl.DateTimeFormat([lang, "en-US"], { dateStyle: "medium", timeStyle: "short" }).format(
    new Date(date),
  );
}
