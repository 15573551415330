import { derive, portal, type ViewContext } from "@manyducks.co/dolla";
import { NavStore } from "@stores/NavStore";
import Close from "@icons/Close";
import { IconButton } from "@views/IconButton";
import { ToolBar, ToolBarGroup } from "@views/ToolBar";
import styles from "./FileViewer.module.css";
import Download from "@icons/Download";
import { downloadFile } from "@helpers/downloadFile";
import { ThemeStore } from "@stores/ThemeStore";

export function FileViewer(props: {}, ctx: ViewContext) {
  const nav = ctx.getStore(NavStore);
  const theme = ctx.getStore(ThemeStore);

  return portal(
    <div class={[styles.container]} style={theme.$userTheme}>
      <div
        class={[styles.toolbar, styles.top]}
        style={{ backgroundImage: "url(/textures/inspiration-geometry.png)" }}
      >
        <ToolBar>
          <ToolBarGroup>
            <IconButton
              onClick={() => {
                // nav.closeFileViewer();
                const file = nav.$fileViewerContent.get();
                if (file) {
                  downloadFile({ path: `/static/files/${file.fileName}`, name: file.fileDisplayName });
                }
              }}
            >
              <Download />
            </IconButton>
          </ToolBarGroup>
          <ToolBarGroup>
            <IconButton
              onClick={() => {
                nav.closeFileViewer();
              }}
            >
              <Close />
            </IconButton>
          </ToolBarGroup>
        </ToolBar>
      </div>
      <div class={styles.content}>
        {derive([nav.$fileViewerContent], (file) => {
          ctx.log(file);
          if (file?.mimeType.startsWith("image")) {
            return <img src={`/static/files/${file.fileName}`} alt="" class={styles.contentImage} />;
          } else {
            return "UNSUPPORTED MIME TYPE: " + file?.mimeType;
          }
        })}
      </div>
      {/* <div
        class={[styles.toolbar, styles.bottom]}
        style={{ backgroundImage: "url(/textures/inspiration-geometry.png)" }}
      >
        <ToolBar>
          <ToolBarGroup></ToolBarGroup>
          <ToolBarGroup>
            <IconButton
              onClick={() => {
                // nav.closeFileViewer();
              }}
            >
              <Download />
            </IconButton>
          </ToolBarGroup>
        </ToolBar>
      </div> */}
    </div>,
    document.body,
  );
}
