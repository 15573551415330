import { derive, LanguageStore, signal, type ViewContext } from "@manyducks.co/dolla";
import { TasksStore } from "@stores/TasksStore";
import { ThemeStore } from "@stores/ThemeStore";
// import { History } from "./History/History";
import { ColorDivider } from "@views/ColorDivider";
import { PlaceLayout } from "Workspace/PlaceLayout/PlaceLayout";
import { Settings as SettingsMenu } from "../Nav/Settings/Settings";
import styles from "./Settings.module.css";

interface MyTasksProps {}

export function Settings(props: MyTasksProps, ctx: ViewContext) {
  const tasks = ctx.getStore(TasksStore);
  const theme = ctx.getStore(ThemeStore);
  const { translate } = ctx.getStore(LanguageStore);

  const $color = derive([theme.$userTheme], (t) => t["--theme-primary"]);
  const $taskCount = derive([tasks.$myTasks], (tasks) => {
    let count = 0;
    for (let i = 0; i < tasks.length; i++) {
      if (tasks[i].completedAt == null) {
        count += 1;
      }
    }
    return count;
  });

  const [$selectedTab, setSelectedTab] = signal<"todo" | "calendar">("todo");

  return (
    <PlaceLayout
      color={$color}
      title={translate("workspace.me.settings.tabName")}
      // tabs={[
      //   {
      //     href: "/my-tasks/list",
      //     active: (pattern) => pattern.startsWith("/my-tasks/list"),
      //     text: translate("workspace.me.tasks.todo.tabName"),
      //   },
      //   {
      //     href: "/my-tasks/calendar",
      //     active: (pattern) => pattern.startsWith("/my-tasks/calendar"),
      //     text: translate("workspace.project.tasks.calendar.tabName"),
      //   },
      // ]}
    >
      <div class={styles.layout}>
        <SettingsMenu />
      </div>
    </PlaceLayout>
  );
}
