import { derive, type Signal, type MaybeSignal, type ViewContext } from "@manyducks.co/dolla";
import { type Renderable } from "@manyducks.co/dolla/lib/types";
import styles from "./CardDetailStack.module.css";
import { BreakpointStore } from "@stores/BreakpointStore";

interface CardDetailStackProps {
  $showDetail: Signal<boolean>;
  listContent: Renderable;
  detailContent: Renderable;

  /** Minimum container width to display contents in expanded mode. */
  expandedThreshold?: MaybeSignal<number>;

  expandedListWidth?: MaybeSignal<number>;
}

/**
 * Implements a navigation stack for a list view and a detail view with an animated transition.
 */
export function CardDetailStack(props: CardDetailStackProps, ctx: ViewContext) {
  const breakpoint = ctx.getStore(BreakpointStore);

  const $expandedBreakpoint = derive([breakpoint.$width, props.expandedThreshold], (width, threshold) => {
    return threshold != null && width > threshold;
  });

  const $showDetail = derive([props.$showDetail, $expandedBreakpoint], (show, expanded) => show || expanded);

  return (
    <div
      class={[styles.layout, { [styles.expanded]: $expandedBreakpoint }]}
      style={{ "--expanded-list-width": derive([props.expandedListWidth], (value) => `${value ?? 400}px`) }}
    >
      <div class={[styles.frame, styles.listFrame, { [styles.hidden]: props.$showDetail }]}>
        {props.listContent}
      </div>
      <div class={[styles.shade, { [styles.show]: props.$showDetail }]} />
      <div class={[styles.frame, styles.detailFrame, { [styles.show]: props.$showDetail }]}>
        {props.detailContent}
      </div>
    </div>
  );
}
