import { makeDebouncer } from "@helpers/makeDebouncer";
import {
  derive,
  LanguageStore,
  RouterStore,
  cond,
  repeat,
  type ViewContext,
  signal,
  ref,
} from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { NotesStore } from "@stores/NotesStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { ThemeStore } from "@stores/ThemeStore";
import SortAsc from "@icons/SortAsc";
import { CardContent, CardToolbar } from "@views/Card";
import { ColorDivider } from "@views/ColorDivider";
import { MoreMenu } from "@views/MoreMenu";
import { NoteListItem } from "@views/NoteListItem";
import { SearchOrCreateInput } from "@views/SearchOrCreateInput";
import { SquiggleDivider } from "@views/SquiggleDivider";
import { TagFilter } from "@views/TagFilter/TagFilter";
import { Note } from "schemas";
import styles from "./List.module.css";

interface ListProps {}

type NotesListOrder =
  | "updated-newest"
  | "updated-oldest"
  | "created-newest"
  | "created-oldest"
  | "alphabetical-newest"
  | "alphabetical-oldest";

export function List(props: ListProps, ctx: ViewContext) {
  ctx.name = "@cards/Notes/List";

  const auth = ctx.getStore(AuthStore);
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);
  const router = ctx.getStore(RouterStore);
  const projects = ctx.getStore(ProjectsStore);
  const theme = ctx.getStore(ThemeStore);
  const notes = ctx.getStore(NotesStore);

  const $projectId = derive([router.$params], (params) => Number(params.projectId));

  const [$orderBy, setOrderBy] = signal<NotesListOrder>("updated-newest");

  const $$filterTags = signal.settable<string[]>([]);

  const $projectColor = derive(
    [$projectId, projects.$cache],
    (id, projects) => projects.find((p) => p.id === id)?.color ?? "#888",
  );

  const $tags = derive([$projectId, notes.$cache], (projectId, notes) => {
    const tags: { [tag: string]: number } = {};

    if (projectId != null) {
      for (const note of notes.values()) {
        if (note.projectId == projectId) {
          for (const tag of note.tags) {
            tags[tag] = (tags[tag] ?? 0) + 1;
          }
        }
      }
    }

    return tags;
  });

  const $project = derive([projects.$cache, $projectId], (projects, id) => projects.find((p) => p.id === id));
  const $userRole = derive([$project, auth.$me], (project, me) => {
    if (!project || !me || project.archivedAt != null) return "viewer";
    return project.users.find((u) => u.id === me.id)?.role ?? "viewer";
  });
  const $canInteract = derive([$userRole], (role) => role !== "viewer");

  const scrollElement = ref<HTMLElement>();
  const scrollDebouncer = makeDebouncer(100);

  function onScroll(e: Event) {
    // const el = $$scrollElement.get()!;
    // scrollDebouncer.queue(() => {
    //   thisCard.updateState({
    //     listScrollPosition: el.scrollTop,
    //   });
    // });
  }

  ctx.onConnected(() => {
    const el = scrollElement.node!;
    el.addEventListener("scroll", onScroll);
  });

  ctx.beforeDisconnect(() => {
    const el = scrollElement.node!;
    el.removeEventListener("scroll", onScroll);
    scrollDebouncer.cancel();
    // thisCard.updateState({
    //   listScrollPosition: el.scrollTop,
    // });
  });

  const $$inputText = signal.settable<string>("");

  const $notes = derive(
    [notes.$cache, $projectId, $$inputText, $orderBy, $$filterTags],
    (notes, projectId, inputText, order, tags) => {
      const searchTerm = inputText.trim() === "" ? null : inputText.trim().toLowerCase();
      let results = [...notes.values()].filter(
        (n) => n.projectId === projectId && !n.deletedAt && tags.every((t) => n.tags.includes(t)),
      );

      if (searchTerm) {
        results = results.filter((r) => r.title.toLowerCase().includes(searchTerm));
      }

      return results.sort(makeSortByOrder(order));
    },
  );

  const $pinned = derive([$notes], (notes) => notes.filter((n) => n.isPinned));
  const $unpinned = derive([$notes], (notes) => [...notes.values()].filter((n) => !n.isPinned));

  const $$orderMenuOpen = signal.settable(false);

  const getDraggedNote = (e: DragEvent, list = notes.$cache.get()) => {
    const data = e.dataTransfer!.getData("data/note-id");
    if (data) {
      const noteId = Number(data);
      return list.get(noteId);
    }
  };

  const onSortChange = (e: Event) => {
    const newValue = (e.currentTarget as HTMLOptionElement).value as any;
    setOrderBy(newValue);
  };

  const scrollToItem = (noteId: number) => {
    const scrollEl = scrollElement.node!;
    const itemEl = scrollEl.querySelector(`[data-note-id='${noteId}']`);

    if (itemEl) {
      // Take measurements
      const scrollRect = scrollEl.getBoundingClientRect();
      const itemRect = itemEl?.getBoundingClientRect();

      // Calculate required movement to place item within viewport
      const boundsTop = scrollRect.top + 48;
      const boundsBottom = scrollRect.top + scrollRect.height - 8;

      // Perform movement
      if (itemRect.top < boundsTop) {
        // Scroll up
        ctx.log("MUST SCROLL UP BY", boundsTop - itemRect.top);
        scrollEl.scrollTo({
          top: scrollEl.scrollTop - (boundsTop - itemRect.top),
          behavior: "smooth",
        });
      } else if (itemRect.bottom > boundsBottom) {
        // Scroll down
        ctx.log("MUST SCROLL DOWN BY", itemRect.bottom - boundsBottom);
        scrollEl.scrollTo({
          top: scrollEl.scrollTop + (itemRect.bottom - boundsBottom),
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <div class={styles.container}>
      <div class={styles.searchGroup}>
        <SearchOrCreateInput
          $$value={$$inputText}
          $canInteract={$canInteract}
          onSubmit={async (value) => {
            const projectId = $projectId.get();

            await notes
              .createNote({
                projectId: projectId,
                delta: [{ insert: value }, { insert: "\n", attributes: { header: 1 } }, { insert: "\n" }],
                tags: $$filterTags.get(),
              })
              .then((note) => {
                router.navigate(`/projects/${projectId}/notes/${note.id}`);
              });
          }}
          inputPlaceholder={translate("workspace.project.notes.list.searchOrCreate.inputPlaceholder")}
          buttonLabel={translate("workspace.project.notes.list.searchOrCreate.buttonText")}
        />
      </div>

      {/* <ColorDivider /> */}

      <CardContent ref={scrollElement}>
        <div class={styles.content}>
          {cond(
            derive([$notes], (x) => x.length === 0),

            // Show a message if there are no notes.
            <div class={styles.emptyMessage}>
              <span>
                {cond(
                  derive([$$inputText], (t) => t.trim().length > 0),
                  translate("workspace.project.notes.list.emptySearchResultsMessage"),
                  translate("workspace.project.notes.list.emptyListMessage"),
                )}
              </span>
            </div>,

            // Otherwise show pages.
            <>
              {cond(
                derive([$pinned], (x) => x.length > 0),

                <ul class={[styles.noteList, styles.pinnedList]}>
                  {repeat(
                    $pinned,
                    (note) => note.id,
                    ($note) => (
                      <NoteListItem
                        $note={$note}
                        $orderBy={$orderBy}
                        scrollToItem={scrollToItem}
                        onClick={() => {
                          const note = $note.get();
                          router.navigate(`/projects/${note.projectId}/notes/${note.id}`);
                        }}
                        interactive={$canInteract}
                      />
                    ),
                  )}
                </ul>,
              )}

              {cond(
                derive([$pinned, $unpinned], (p, u) => p.length > 0 && u.length > 0),
                <div class={styles.squiggleDivider}>
                  <SquiggleDivider />
                </div>,
              )}

              {cond(
                derive([$unpinned], (x) => x.length > 0),

                <ul class={[styles.noteList, styles.unpinnedList]}>
                  {repeat(
                    $unpinned,
                    (note) => note.id,
                    ($note) => {
                      return (
                        <NoteListItem
                          $note={$note}
                          $orderBy={$orderBy}
                          scrollToItem={scrollToItem}
                          onClick={() => {
                            const note = $note.get();
                            router.navigate(`/projects/${note.projectId}/notes/${note.id}`);
                          }}
                          interactive={$canInteract}
                        />
                      );
                    },
                  )}
                </ul>,
              )}
            </>,
          )}
        </div>
      </CardContent>

      <CardToolbar>
        <div class={styles.filterSortGroup}>
          <div class={styles.tagFilter}>
            <TagFilter $suggestions={$tags} $$tags={$$filterTags} $color={$projectColor} />
          </div>

          <MoreMenu
            $$open={$$orderMenuOpen}
            icon={<SortAsc />}
            options={[
              {
                label: translate("workspace.project.notes.list.sortOrder.createdNewest"),
                disabled: derive([$orderBy], (x) => x === "created-newest"),
                callback: () => {
                  setOrderBy("created-newest");
                },
              },
              {
                label: translate("workspace.project.notes.list.sortOrder.createdOldest"),
                disabled: derive([$orderBy], (x) => x === "created-oldest"),
                callback: () => {
                  setOrderBy("created-oldest");
                },
              },
              {
                label: translate("workspace.project.notes.list.sortOrder.updatedNewest"),
                disabled: derive([$orderBy], (x) => x === "updated-newest"),
                callback: () => {
                  setOrderBy("updated-newest");
                },
              },
              {
                label: translate("workspace.project.notes.list.sortOrder.updatedOldest"),
                disabled: derive([$orderBy], (x) => x === "updated-oldest"),
                callback: () => {
                  setOrderBy("updated-oldest");
                },
              },
              {
                label: translate("workspace.project.notes.list.sortOrder.alphabeticalNewest"),
                disabled: derive([$orderBy], (x) => x === "alphabetical-newest"),
                callback: () => {
                  setOrderBy("alphabetical-newest");
                },
              },
              {
                label: translate("workspace.project.notes.list.sortOrder.alphabeticalOldest"),
                disabled: derive([$orderBy], (x) => x === "alphabetical-oldest"),
                callback: () => {
                  setOrderBy("alphabetical-oldest");
                },
              },
            ]}
          />
        </div>
      </CardToolbar>
    </div>
  );
}

function makeSortByOrder(order: NotesListOrder) {
  return function (a: Note, b: Note) {
    switch (order) {
      case "created-newest":
        if (a.createdAt > b.createdAt) {
          return -1;
        } else if (a.createdAt < b.createdAt) {
          return 1;
        } else {
          return 0;
        }
      case "created-oldest":
        if (a.createdAt < b.createdAt) {
          return -1;
        } else if (a.createdAt > b.createdAt) {
          return 1;
        } else {
          return 0;
        }
      case "updated-newest":
        if (a.updatedAt > b.updatedAt) {
          return -1;
        } else if (a.updatedAt < b.updatedAt) {
          return 1;
        } else {
          return 0;
        }
      case "updated-oldest":
        if (a.updatedAt < b.updatedAt) {
          return -1;
        } else if (a.updatedAt > b.updatedAt) {
          return 1;
        } else {
          return 0;
        }
      case "alphabetical-newest":
        if (a.title < b.title) {
          return -1;
        } else if (a.title > b.title) {
          return 1;
        } else {
          return 0;
        }
      case "alphabetical-oldest":
        if (a.title > b.title) {
          return -1;
        } else if (a.title < b.title) {
          return 1;
        } else {
          return 0;
        }
      default:
        return 0;
    }
  };
}
