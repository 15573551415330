import { adjustAccentColor } from "@helpers/makeTheme";
import { relativePastTime } from "@helpers/relativePastTime";
import {
  cond,
  derive,
  DialogStore,
  LanguageStore,
  type MaybeSignal,
  ref,
  repeat,
  RouterStore,
  signal,
  type Signal,
  signalify,
  type ViewContext,
} from "@manyducks.co/dolla";
import { type Renderable } from "@manyducks.co/dolla/lib/types";
import { AuthStore } from "@stores/AuthStore";
import { ClockStore } from "@stores/ClockStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { TasksStore } from "@stores/TasksStore";
import { ThemeStore } from "@stores/ThemeStore";
import { ConfirmDelete } from "@views/@dialogs";
import ArrowUpDoubleLine from "@icons/ArrowUpDoubleLine";
import ArrowUpSingleLine from "@icons/ArrowUpSingleLine";
import Calendar from "@icons/Calendar";
import ErrorCircle from "@icons/ErrorCircle";
import Paperclip from "@icons/Paperclip";
import TimeIcon from "@icons/Time";
import TrashIcon from "@icons/Trash";
import UserIcon from "@icons/User";
import { Checkbox } from "@views/Checkbox";
import { HoverMenu } from "@views/HoverMenu";
import { MoreMenu } from "@views/MoreMenu";
import { addDays, isBefore, isSameDay } from "date-fns";
import prettyBytes from "pretty-bytes";
import { type Task } from "schemas";
import { AssignMenu } from "./AssignMenu";
import { TaskEditDialog } from "./TaskEditDialog/TaskEditDialog";
import styles from "./TaskListItem.module.css";

interface TaskListItemProps {
  $task: Signal<Task>;

  $timestamp?: Signal<string>;

  $theme?: Signal<any>;

  interactive?: MaybeSignal<boolean>;

  showProjectName?: MaybeSignal<boolean>;

  card?: MaybeSignal<boolean>;

  onClick?: (e: MouseEvent) => void;

  scrollToTask?: (taskId: number) => void;
}

export function TaskListItem(props: TaskListItemProps, ctx: ViewContext) {
  const projects = ctx.getStore(ProjectsStore);
  const tasks = ctx.getStore(TasksStore);
  const auth = ctx.getStore(AuthStore);
  const theme = ctx.getStore(ThemeStore);
  const clock = ctx.getStore(ClockStore);
  const dialog = ctx.getStore(DialogStore);
  const router = ctx.getStore(RouterStore);
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);

  const { $task, scrollToTask } = props;

  const $isSelected = derive([router.$params, $task], (params, task) => params.taskId == task.id);

  const listItemElement = ref<HTMLLIElement>();

  const $interactive = signalify(props.interactive ?? true);
  const $noninteractive = derive([$interactive], (x) => !x);

  const $timestamp = signalify(props.$timestamp);
  const $defaultTitle = translate("views.taskListItem.unnamedFallbackTitle");
  const $title = derive([$task, $defaultTitle], (task, defaultTitle) => task.title || defaultTitle);
  const $status = derive([$task], (t) => t.status);
  const $priority = derive([$task], (t) => t.priority);
  const $isTitled = derive([$task], (task) => !!task.title);
  const $isUntitled = derive([$title, $defaultTitle], (title, defaultTitle) => title === defaultTitle);

  const $assignedUser = derive([$task, auth.$users], (task, users) =>
    users.find((u) => u.id === task.assignedUserId),
  );

  const [$menuOpen, setMenuOpen] = signal(false);

  const [$stageMenuOpen, setStageMenuOpen] = signal(false);

  const $highlighted = derive([$task, tasks.$highlightTaskId], (t, id) => t.id === id);
  ctx.watch([$highlighted], (highlighted) => {
    if (highlighted) {
      const button = listItemElement.node!.querySelector("button");

      if (button) {
        button.addEventListener(
          "animationend",
          () => {
            tasks.setHighlightedTaskId(null);
          },
          { once: true },
        );
      }
    }
  });

  const [$assignMenuIsOpen, setAssignMenuIsOpen] = signal(false);
  const assignMenuAnchor = ref<HTMLElement>();

  function openAssignMenu(e: Event) {
    assignMenuAnchor.node = e.currentTarget as HTMLElement;
    setAssignMenuIsOpen(true);
  }

  const $project = derive([$task, projects.$cache], (task, projects) => {
    return projects.find((p) => p.id === task.projectId);
  });

  const $projectColor = derive([$project, theme.$isDark], (project, dark) => {
    return adjustAccentColor(project!.color, dark);
  });

  // const $assignedUserColor = derive([$task, auth.$users, theme.$isDark], (task, users, dark) => {
  //   if (task.assignedUserId != null) {
  //     const user = users.find((u) => u.id === task.assignedUserId);
  //     if (user) {
  //       return adjustAccentColor(user.color, dark);
  //     }
  //   }
  //   return undefined;
  // });

  const $assignedUserColor = derive(
    [$task, auth.$users, theme.$isDark, theme.$placeColor],
    (task, users, dark, placeColor) => {
      if (task.assignedUserId != null) {
        const user = users.find((u) => u.id === task.assignedUserId);
        if (user) {
          return theme.getBlendedColor$(adjustAccentColor(user.color, dark), placeColor).get();
        }
      }
      return undefined;
    },
  );

  return (
    <li
      class={styles.taskListItem}
      style={theme.getTheme$($projectColor)}
      ref={listItemElement}
      data-task-id={derive([$task], (t) => t.id)}
    >
      <button
        class={[
          styles.task,
          {
            [styles.completed]: derive([$task], (t) => t.completedAt != null),
            [styles.focused]: derive([$menuOpen, $stageMenuOpen], (a, b) => a || b),
            [styles.highlighted]: $highlighted,
            [styles.selected]: $isSelected,
            [styles.card]: props.card,
          },
        ]}
        onClick={(e) => {
          props.onClick?.(e);

          if (!e.defaultPrevented) {
            e.preventDefault();

            const interactive = $interactive.get();
            if (!interactive) {
              return;
            }

            const task = $task.get();
            dialog.open(TaskEditDialog, {
              projectId: task.projectId,
              task,
              color: $projectColor.get(),
            });
          }
        }}
        onDragStart={(e) => {
          ctx.log("dragstart", e);

          e.dataTransfer!.setData("data/task-id", String($task.get().id));
          e.dataTransfer!.effectAllowed = "move";
        }}
        draggable={true}
      >
        <div class={styles.taskLayout}>
          <div class={styles.taskCheckbox} onClick={(e) => e.stopPropagation()}>
            <Checkbox
              disabled={$noninteractive}
              $value={derive([$task], (t) => t.completedAt != null)}
              onChange={(value) => {
                const task = $task.get();
                tasks.setCompleted(task.id, value);
              }}
            />
          </div>

          <div class={styles.taskContent} draggable={false}>
            <div class={styles.mainContent}>
              <span class={styles.taskTitle}>
                {derive([$priority], (p) => {
                  // TODO: Switch to new render helper

                  switch (p) {
                    case null:
                      return null;
                    case 1:
                      return (
                        <span class={[styles.priorityBadge, styles.prioritySoon]}>
                          <ArrowUpSingleLine />
                        </span>
                      );
                    case 2:
                      return (
                        <span class={[styles.priorityBadge, styles.prioritySoon]}>
                          <ArrowUpDoubleLine />
                        </span>
                      );
                  }
                })}
                <span class={{ [styles.untitled]: $isUntitled, [styles.taskTitle]: $isTitled }}>
                  {$title}
                </span>
              </span>

              {cond($status, <p class={styles.taskDescription}>{$status}</p>)}

              {cond(
                derive([$task], (t) => t.tags.length > 0),
                <ul class={styles.taskTagList}>
                  {repeat(
                    derive([$task], (t) => [...t.tags].sort()),
                    (tag) => tag,
                    ($tag) => {
                      return <li class={styles.taskTag}>{$tag}</li>;
                    },
                  )}
                </ul>,
              )}

              <section class={styles.taskQuickOptions}>
                <button
                  class={[
                    styles.taskAssignedUser,
                    {
                      [styles.menuOpen]: $assignMenuIsOpen,
                      [styles.interactive]: $interactive,
                      [styles.unassigned]: derive([$assignedUser], (u) => u == null),
                    },
                  ]}
                  disabled={$noninteractive}
                  style={{ "--assigned-user-color": $assignedUserColor }}
                  onClick={(e) => {
                    e.stopPropagation();
                    openAssignMenu(e);
                  }}
                >
                  <div class={styles.colorDot} />
                  {derive(
                    [$assignedUser, translate("views.taskListItem.meta.notAssignedLabel")],
                    (u, defaultLabel) => u?.name ?? defaultLabel,
                  )}
                </button>
              </section>
            </div>

            <div class={styles.secondaryContent}>
              <ul class={[styles.meta]}>
                {cond(
                  props.showProjectName,
                  <li>
                    <span>{derive([$project], (p) => p?.name)}</span>
                  </li>,
                )}

                {cond(
                  derive([$task, $timestamp], (task, timestamp) => task.completedAt != null || timestamp),
                  <li>
                    <span>
                      {derive([$timestamp, $task, $currentLanguage], (timestamp, task, lang) => {
                        if (timestamp) {
                          return timestamp;
                        } else {
                          const timestamp = relativePastTime(lang!, task.completedAt!);
                          if (timestamp) {
                            return translate("relativeTime.completedAtTimestamp", { timestamp }).get();
                          } else {
                            return translate("relativeTime.completedNow").get();
                          }
                        }
                      })}
                    </span>
                  </li>,
                  cond(
                    derive([$task, clock.$hour], (t, _) => t.dueDate != null),
                    <li
                      class={[
                        styles.taskDueDate,
                        { [styles.inPast]: derive([$task], (t) => isDateInPast(t.dueDate!)) },
                      ]}
                    >
                      {/* {cond(
                      derive([$task, clock.$hour], (t, _) => isDateInPast(t.dueDate!)),
                      <ErrorCircle />,
                    )} */}

                      <span>
                        {derive([$task, $currentLanguage, clock.$hour], (task, lang, _) => {
                          const [y, m, d] = task.dueDate!.split("-").map((n) => Number(n));
                          const date = new Date(y, m - 1, d);
                          const now = new Date();

                          if (isSameDay(date, now)) {
                            return translate("views.taskListItem.meta.dueDateLabel_today").get();
                          } else if (isSameDay(date, addDays(now, 1))) {
                            return translate("views.taskListItem.meta.dueDateLabel_tomorrow").get();
                          } else {
                            const value = new Intl.DateTimeFormat([lang!, "en"], {
                              month: "short",
                              day: "numeric",
                            }).format(date);
                            return translate("views.taskListItem.meta.dueDateLabel_value", { value }).get();
                          }
                        })}
                      </span>
                    </li>,
                  ),
                )}

                {cond(
                  derive([$task], (t) => t.attachments.length > 0),
                  <li>
                    <span>
                      {derive([$task], (t) => t.attachments.length)} (
                      {derive([$task], (t) => {
                        const totalBytes = t.attachments.reduce((sum, f) => sum + f.sizeInBytes, 0);
                        return prettyBytes(totalBytes);
                      })}
                      )
                    </span>
                  </li>,
                )}
              </ul>
            </div>
          </div>

          {cond(
            $interactive,
            <div class={styles.taskMenu}>
              <MoreMenu
                $$open={signal.toSettable($menuOpen, setMenuOpen)}
                size="small"
                color={$projectColor}
                options={[
                  {
                    label: translate("common.delete"),
                    icon: <TrashIcon />,
                    variant: "destructive",
                    callback: () => {
                      dialog.open(ConfirmDelete, {
                        message: translate("views.taskListItem.deleteDialog.message"),
                        itemName: $task.get().title,
                        onConfirm: () => {
                          tasks.deleteTask($task.get().id);
                        },
                      });
                    },
                  },
                ]}
              />
            </div>,
          )}
        </div>
      </button>

      <HoverMenu
        $$open={signal.toSettable($assignMenuIsOpen, setAssignMenuIsOpen)}
        anchorRef={assignMenuAnchor}
        color={$projectColor}
        distanceFromAnchor={14}
        preferHorizontalAlignment="center"
        preferVerticalAlignment="below"
      >
        <div class={styles.assignMenu}>
          <AssignMenu
            $projectId={derive([$task], (t) => t.projectId)}
            $value={derive([$task], (t) => t.assignedUserId)}
            onChange={(user) => {
              const task = $task.get();
              tasks.assignTo(user?.id ?? null, task.id);
              setAssignMenuIsOpen(false);
            }}
          />
        </div>
      </HoverMenu>
    </li>
  );
}

function isDateInPast(dateString: string) {
  const [y, m, d] = dateString.split("-").map((n) => Number(n));
  const date = new Date(y, m - 1, d);
  const now = new Date();

  return !isSameDay(now, date) && isBefore(date, now);
}
