import { signal, type StoreContext } from "@manyducks.co/dolla";

/**
 * Tracks the current time. Good for synchronizing time-sensitive events.
 */
export function ClockStore(ctx: StoreContext) {
  const [$second, setSecond] = signal(0);
  const [$minute, setMinute] = signal(0);
  const [$hour, setHour] = signal(0);

  function tick() {
    const now = new Date();
    const offset = 1000 - now.getMilliseconds();
    setSecond(now.getSeconds());
    setMinute(now.getMinutes());
    setHour(now.getHours());

    setTimeout(tick, offset);
  }

  ctx.onConnected(tick);

  return {
    $second,
    $minute,
    $hour,
  };
}
