import { signal, type StoreContext } from "@manyducks.co/dolla";

/**
 * Manages the app badge (unread count) for mobile home screen apps.
 */
export function BadgeStore(ctx: StoreContext) {
  const [$number, setNumber] = signal(0);

  ctx.watch([$number], (value) => {
    (navigator as any).setAppBadge?.(value);
    (navigator as any).setClientBadge?.(value);
  });

  return {
    increment: () => {
      setNumber((n) => n + 1);
    },
    set: (value: number) => {
      setNumber(value);
    },
    clear: () => {
      setNumber(0);
    },
  };
}
