import {
  cond,
  derive,
  DialogStore,
  LanguageStore,
  repeat,
  RouterStore,
  Signal,
  signal,
  ViewContext,
} from "@manyducks.co/dolla";
import styles from "./ProjectOverview.module.css";
import { CardContent } from "@views/Card";
import { AuthStore } from "@stores/AuthStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { EditProject } from "./EditProject/EditProject";
import { Button } from "@views/Button";
import FolderIcon from "@icons/Folder";
import { ThemeStore } from "@stores/ThemeStore";
import { UserAvatar } from "@views/UserAvatar";
import { Project, User } from "schemas";
import Mail from "@icons/Mail";
import Time from "@icons/Time";

interface ProjectUser extends User {
  role: Project["users"][0]["role"];
  joinedAt: string;
}

export function ProjectOverview(props: {}, ctx: ViewContext) {
  const auth = ctx.getStore(AuthStore);
  const projects = ctx.getStore(ProjectsStore);
  const dialog = ctx.getStore(DialogStore);
  const router = ctx.getStore(RouterStore);
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);

  const $project = derive([router.$params, projects.$cache], (params, projects) => {
    const id = Number(params.projectId);
    return projects.find((p) => p.id === id)!;
  });

  const $$moreMenuIsOpen = signal.settable(false);
  const [$isEditing, setIsEditing] = signal(false);

  const $userRole = derive([auth.$me, $project], (me, project) => {
    if (!me || !project) return null;
    return project.users.find((u) => u.id === me.id)?.role ?? null;
  });

  const $isOwner = derive([$project, auth.$me], (project, me) => project?.ownerId === me?.id);
  const $isAdmin = derive(
    [$project, auth.$me],
    (project, me) =>
      project != null && me != null && !!project.users.find((u) => u.id === me.id && u.role === "admin"),
  );

  const $people: Signal<ProjectUser[]> = derive([auth.$users, $project], (users, project) => {
    return project.users.map((pu) => {
      return {
        ...users.find((u) => u.id === pu.id)!,
        role: pu.role,
        joinedAt: pu.joinedAt,
      };
    });
  });

  return (
    <div class={styles.layout}>
      <CardContent>
        <header class={styles.projectHeader}>
          <div class={styles.projectHeaderIcon}>
            <FolderIcon />
          </div>
          {cond(
            $isEditing,
            <div class={styles.projectEdit}>
              <EditProject
                project={$project}
                onSave={(updated) => {
                  const project = $project.get();
                  projects.updateProject(project.id, updated);
                  setIsEditing(false);
                }}
                onCancel={() => {
                  setIsEditing(false);
                }}
              />
            </div>,
            <>
              <h2 class={styles.projectHeaderText}>{derive([$project], (p) => p?.name)}</h2>
              {cond(
                $isAdmin,
                <div class={styles.projectEditButton}>
                  <Button
                    onClick={() => {
                      setIsEditing(true);
                    }}
                  >
                    {translate("common.edit")}
                  </Button>
                </div>,
              )}
            </>,
          )}
        </header>

        <section>
          <header>People</header>

          <UsersList $users={$people} />
        </section>

        <h2>O V E R V I E W</h2>
        <ul>
          <li>Unread chat message count banner (click to open chat to this project)</li>
          <li>
            <s>Name, color and</s> description (with edit button to open edit dialog)
          </li>
          <li>Recent activity (notes and tasks)</li>
          <li>Upcoming tasks (by due date)</li>
          <li>
            <s>Member list</s>
          </li>
        </ul>
      </CardContent>
    </div>
  );
}

interface UsersListProps {
  $users: Signal<ProjectUser[]>;
}

function UsersList(props: UsersListProps, ctx: ViewContext) {
  const { $currentLanguage } = ctx.getStore(LanguageStore);

  return cond(
    derive([props.$users], (x) => x.length === 0),
    <p class={styles.emptyUserListMessage}>No users.</p>,
    <ul class={styles.userList}>
      {repeat(
        props.$users,
        (u) => u.id,
        ($user, $index, ctx) => {
          const theme = ctx.getStore(ThemeStore);

          const $name = derive([$user], (x) => x.name);
          const $color = derive([$user], (x) => x.color);
          const $themeVars = theme.getTheme$($color);

          return (
            <li class={styles.userListItem}>
              <div class={styles.userLayout}>
                <div class={styles.userInfo}>
                  <div class={styles.userIcon}>
                    <UserAvatar src={derive([$user], (u) => u.avatar ?? "")} />
                  </div>

                  <div class={styles.userText}>
                    <span class={styles.userName}>
                      <span class={styles.userNameLabel} style={$themeVars}>
                        {$name}
                      </span>
                    </span>

                    <ul class={styles.userMeta}>
                      <li>
                        <Mail /> {derive([$user], (u) => u.email)}
                      </li>
                      <li>
                        <Time />{" "}
                        {derive([$user, $currentLanguage], (u, lang) => formatJoinDate(u.joinedAt, lang!))}
                      </li>
                    </ul>
                  </div>
                </div>

                {/* {cond(
            $($userRole, (role) => role === "admin"), // Includes owner
            <div class={styles.userActions}>
              <MoreMenu
                $$open={$$menuIsOpen}
                size="small"
                options={[
                  // {
                  //   label: translate("common.edit"),
                  //   icon: <EditIcon />,
                  //   callback: () => {
                  //     $$editIsOpen.set(true);
                  //   },
                  // },
                  {
                    label: translate("common.delete"),
                    icon: <Trash />,
                    variant: "destructive",
                    callback: async () => {
                      // await projects.deleteProject($project.get().id);
                    },
                  },
                ]}
              />
            </div>,
          )} */}
              </div>

              <div class={styles.projectDivider} />
            </li>
          );
        },
      )}
    </ul>,
  );
}

function formatJoinDate(date: string | Date, lang: string) {
  return new Intl.DateTimeFormat([lang], {
    year: "numeric",
    month: "long",
  }).format(new Date(date));
}

function formatInviteDate(date: string | Date, lang: string) {
  return new Intl.DateTimeFormat([lang], {
    dateStyle: "long",
  }).format(new Date(date));
}
