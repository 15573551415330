import { repeat, type Signal, type ViewContext } from "@manyducks.co/dolla";
import { FileAttachment } from "@views/FileAttachment";
import PhotoSwipe from "photoswipe";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import { type File } from "schemas";
import styles from "./ChatMessageAttachments.module.css";

interface MessageAttachmentsProps {
  $attachments: Signal<File[]>;
}

export function ChatMessageAttachments(props: MessageAttachmentsProps, ctx: ViewContext) {
  const lightbox = new PhotoSwipeLightbox({
    gallery: ".gallery_" + ctx.uid,
    children: `a.${styles.attachment}`,
    pswpModule: PhotoSwipe,
    showHideAnimationType: "fade",
  });

  lightbox.addFilter("uiElement", (element: HTMLElement, data: any) => {
    switch (data.name) {
      case "close":
        element.innerHTML = `
          <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="pswp__icn" width="24" height="24" viewBox="0 0 24 24">
            <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z" />
          </svg>
        `;
        break;
      case "zoom":
        element.innerHTML = `
          <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="pswp__icn pswp__icn--zoom-in" width="24" height="24" viewBox="0 0 24 24">
           <path d="M11 6H9v3H6v2h3v3h2v-3h3V9h-3z"></path><path d="M10 2c-4.411 0-8 3.589-8 8s3.589 8 8 8a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8zm0 14c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6z"></path>
          </svg>
        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="pswp__icn pswp__icn--zoom-out" width="24" height="24" viewBox="0 0 24 24" ><path d="M6 9h8v2H6z"></path><path d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"></path>
        </svg>
        `;
        break;
      case "arrowPrev":
      case "arrowNext":
        element.innerHTML = `
          <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="pswp__icn" width="24" height="24" viewBox="0 0 24 24">
            <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"></path>
          </svg>
        `;
        break;
    }

    return element;
  });

  ctx.onConnected(() => {
    lightbox.init();
  });

  return (
    <ul class={[styles.messageAttachments, "gallery_" + ctx.uid]}>
      {repeat(
        props.$attachments,
        (a) => a.id,
        ($attachment) => (
          <li>
            <FileAttachment $attachment={$attachment} />
          </li>
        ),
      )}
    </ul>
  );
}

/**
 * Returns the widget type "video", "audio", "file", etc. to handle this file based on the mime type.
 */
export function getTypeFromMime(type: string) {
  if (type.startsWith("image/")) {
    return "image";
  } else if (type.startsWith("video/")) {
    return "video";
  } else if (type.startsWith("audio/")) {
    return "audio";
  } else {
    return "file";
  }
}
