import { derive, RouterStore, type ViewContext } from "@manyducks.co/dolla";
import { List } from "./List/List";
import styles from "./ProjectTasks.module.css";
import { CardDetailStack } from "@views/CardDetailStack";
// import { Details } from "./Details/Details";
import { ProjectsStore } from "@stores/ProjectsStore";

export interface ProjectTasksProps {}

export function ProjectTasks(props: ProjectTasksProps, ctx: ViewContext) {
  ctx.name = "ProjectTasks";
  const router = ctx.getStore(RouterStore);
  const projects = ctx.getStore(ProjectsStore);

  const $projectId = derive([router.$params], (p) => (p.projectId != null ? Number(p.projectId) : undefined));
  const $taskId = derive([router.$params], (p) => (p.taskId != null ? Number(p.taskId) : undefined));

  const $project = derive([projects.$cache, $projectId], (projects, id) => projects.find((p) => p.id === id));

  return (
    <div class={styles.layout}>
      <CardDetailStack
        $showDetail={derive([$taskId], (id) => id != null)}
        listContent={<List />}
        detailContent={<h1>TODO</h1> /*<Details $project={$project} $taskId={$taskId} />*/}
      />
    </div>
  );
}
