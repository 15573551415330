export default {
  "taskListItem": "TaskListItem_taskListItem_26fde",
  "task": "TaskListItem_task_26fde",
  "focused": "TaskListItem_focused_26fde",
  "selected": "TaskListItem_selected_26fde",
  "card": "TaskListItem_card_26fde",
  "highlighted": "TaskListItem_highlighted_26fde",
  "highlightTask": "TaskListItem_highlightTask_26fde",
  "taskLayout": "TaskListItem_taskLayout_26fde",
  "taskContent": "TaskListItem_taskContent_26fde",
  "taskCheckbox": "TaskListItem_taskCheckbox_26fde",
  "taskTitle": "TaskListItem_taskTitle_26fde",
  "taskDescription": "TaskListItem_taskDescription_26fde",
  "untitled": "TaskListItem_untitled_26fde",
  "taskTitleText": "TaskListItem_taskTitleText_26fde",
  "priorityBadge": "TaskListItem_priorityBadge_26fde",
  "taskTagList": "TaskListItem_taskTagList_26fde",
  "taskTag": "TaskListItem_taskTag_26fde",
  "taskQuickOptions": "TaskListItem_taskQuickOptions_26fde",
  "taskAssignedUser": "TaskListItem_taskAssignedUser_26fde",
  "colorDot": "TaskListItem_colorDot_26fde",
  "unassigned": "TaskListItem_unassigned_26fde",
  "interactive": "TaskListItem_interactive_26fde",
  "menuOpen": "TaskListItem_menuOpen_26fde",
  "taskDueDate": "TaskListItem_taskDueDate_26fde",
  "inPast": "TaskListItem_inPast_26fde",
  "meta": "TaskListItem_meta_26fde",
  "assignMenu": "TaskListItem_assignMenu_26fde",
  "editButtonBar": "TaskListItem_editButtonBar_26fde",
  "taskMenu": "TaskListItem_taskMenu_26fde"
};import "ni:sha-256;yg8xlF6t3JuuqbXHFZPIc8idrNE3F3LoHdX1zPcTYtY";