import { adjustAccentColor } from "@helpers/makeTheme";
import {
  cond,
  derive,
  repeat,
  signal,
  signalify,
  type MaybeSignal,
  type Renderable,
  type SettableSignal,
  type Signal,
  type ViewContext,
} from "@manyducks.co/dolla";
import { ThemeStore } from "@stores/ThemeStore";
import ChevronRightIcon from "@icons/ChevronRight";
import chroma from "chroma-js";
import styles from "./CollapsibleListSection.module.css";

interface CollapsibleListControl {
  id: string | number;
  icon: Renderable;
  title: MaybeSignal<string>;
  disabled?: MaybeSignal<boolean>;
  hidden?: MaybeSignal<boolean>;
  onClick: (e: Event) => void;
}

interface CollapsibleListSectionProps {
  $$open?: SettableSignal<boolean>;
  $title: Signal<string>;
  $itemCount?: Signal<number>;
  $headerColor?: Signal<string>;
  controls?: CollapsibleListControl[];
}

export function CollapsibleListSection(props: CollapsibleListSectionProps, ctx: ViewContext) {
  const theme = ctx.getStore(ThemeStore);

  const $$open = props.$$open ?? signal.settable(true);

  const $styles = derive([props.$headerColor, theme.$isDark], (color, dark) => {
    if (color) {
      const accent = adjustAccentColor(color, dark);
      return {
        "--color-header-background": dark
          ? chroma(accent).luminance(0.2).hex()
          : chroma(accent).luminance(0.8).hex(),
        "--color-header-background-hover": dark
          ? chroma(accent).luminance(0.24).hex()
          : chroma(accent).luminance(0.76).hex(),
        "--color-header-text": dark ? "#fff" : accent,
      };
    }
  });

  const $controls = signalify(props.controls ?? []);

  return (
    <section class={styles.collapsibleListSection}>
      <header style={$styles}>
        <button
          class={[
            styles.listSectionHeaderBackground,
            {
              [styles.open]: $$open,
              [styles.withControls]: derive([$controls], (c) => c != null && c.length > 0),
            },
          ]}
          type="button"
          onClick={() => {
            $$open.set((open) => !open);
          }}
        >
          <span class={styles.listSectionTitle}>
            <span class={[styles.listSectionOpenIndicator, { [styles.open]: $$open }]} />
            {props.$title}
          </span>
          {cond(
            derive([props.$itemCount], (ic) => ic != null),
            <span class={styles.listSectionCount}>{props.$itemCount}</span>,
          )}
        </button>
        {cond(
          $controls,
          <ul class={styles.controls}>
            {repeat(
              $controls,
              (control) => control.id,
              ($control) => {
                const $title = derive([$control], (c) => c.title);
                const $icon = derive([$control], (c) => c.icon);
                const $disabled = derive([$control], (c) => c.hidden ?? false);
                const $hidden = derive([$control], (c) => c.hidden ?? false);

                return (
                  <>
                    {cond(
                      $hidden,
                      null,
                      <li>
                        <button
                          class={[styles.controlButton]}
                          disabled={$disabled}
                          title={$title}
                          type="button"
                          onClick={(e) => {
                            const { onClick } = $control.get();
                            onClick(e);
                          }}
                        >
                          {$icon}
                        </button>
                      </li>,
                    )}
                  </>
                );
              },
            )}
          </ul>,
        )}
      </header>
      <div class={[styles.listSectionContent, { [styles.show]: $$open }]}>{ctx.outlet()}</div>
    </section>
  );
}
