import {
  LanguageStore,
  type Signal,
  type MaybeSignal,
  type ViewContext,
  signal,
  ref,
  derive,
} from "@manyducks.co/dolla";
import Calendar from "@icons/Calendar";
import DownArrow from "@icons/DownArrow";
import { CalendarBlockView } from "@views/CalendarInput";
import { HoverMenu } from "@views/HoverMenu";
import styles from "./DatePicker.module.css";

// Works with values in YYYY-MM-DD format.

interface DatePickerProps {
  $value: Signal<string | null> | Signal<string | undefined>;
  $min?: Signal<Date> | Signal<Date | undefined>;
  onChange: (newValue: string | null) => void;
  $disabled?: Signal<boolean>;
  placeholder?: MaybeSignal<string>;
  accentColor?: MaybeSignal<string>;
}

export function DatePicker(props: DatePickerProps, ctx: ViewContext) {
  const { $currentLanguage } = ctx.getStore(LanguageStore);

  const [$menuIsOpen, setMenuIsOpen] = signal(false);
  const menuAnchor = ref<HTMLElement>();

  const $valueAsDate = derive([props.$value], (date) => {
    if (date) {
      const [y, m, d] = date.split("-").map((n) => Number(n));
      return new Date(y, m - 1, d);
    }
  });

  const setValue = signal.createSetter($valueAsDate, (next) => {
    props.onChange(next ? next.toISOString().split("T")[0] : null);
  });

  return (
    <div class={styles.container}>
      <button
        type="button"
        ref={menuAnchor}
        class={styles.button}
        onClick={(e) => {
          setMenuIsOpen(true);
        }}
      >
        <div class={styles.icon}>
          <Calendar />
        </div>
        <div class={styles.label}>
          {derive([props.$value, props.placeholder, $currentLanguage], (value, placeholder, lang) => {
            if (value) {
              const [y, m, d] = value.split("-").map((x) => Number(x));
              return new Intl.DateTimeFormat([lang!, "en-US"], {
                year: "numeric",
                month: "short",
                day: "numeric",
                weekday: "short",
              }).format(new Date(y, m - 1, d));
            } else {
              return placeholder;
            }
          })}
        </div>
        <div class={styles.indicator}>
          <DownArrow />
        </div>
      </button>

      <HoverMenu
        $$open={signal.toSettable($menuIsOpen, setMenuIsOpen)}
        anchorRef={menuAnchor}
        color={props.accentColor}
        distanceFromAnchor={14}
        preferHorizontalAlignment="center"
        preferVerticalAlignment="below"
      >
        <div class={styles.calendar}>
          <CalendarBlockView $$date={signal.toSettable($valueAsDate, setValue)} $min={props.$min} />
        </div>
      </HoverMenu>
    </div>
  );
}
