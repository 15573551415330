export default {
  "layout": "DayDetails_layout_33d2e",
  "content": "DayDetails_content_33d2e",
  "taskList": "DayDetails_taskList_33d2e",
  "emptyListMessage": "DayDetails_emptyListMessage_33d2e",
  "searchOrCreate": "DayDetails_searchOrCreate_33d2e",
  "hourGroup": "DayDetails_hourGroup_33d2e",
  "first": "DayDetails_first_33d2e",
  "last": "DayDetails_last_33d2e",
  "hourGroupIcon": "DayDetails_hourGroupIcon_33d2e"
};import "ni:sha-256;aqVgrSRyMPGhYFwkqqPFHiFAKZ4gFZygnXW3xiSsa8k";