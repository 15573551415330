export default {
  "button": "IconButton_button_c6b27",
  "icon": "IconButton_icon_c6b27",
  "numberBadge": "IconButton_numberBadge_c6b27",
  "small": "IconButton_small_c6b27",
  "filled": "IconButton_filled_c6b27",
  "destructive": "IconButton_destructive_c6b27",
  "transparent": "IconButton_transparent_c6b27",
  "withLabel": "IconButton_withLabel_c6b27",
  "label": "IconButton_label_c6b27"
};import "ni:sha-256;INymdxHGTzhiIz5J85w8IR-Zl3U6EHekffnEns8_Kdc";