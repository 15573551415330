import { derive, signalify, type MaybeSignal, type Signal, type ViewContext } from "@manyducks.co/dolla";
import DownArrowIcon from "@icons/DownArrow";
import styles from "./Select.module.css";

interface SelectOption<T> {
  label: MaybeSignal<string>;
  value: T;
}

interface SelectProps<T> {
  id?: string;
  required?: MaybeSignal<boolean>;
  placeholder?: MaybeSignal<string>;
  value: MaybeSignal<T>;
  options: SelectOption<T>[];
  onChange: (value: T) => void;
  size?: "normal" | "small" | Signal<"normal" | "small">;
}

export function Select<T>({ id, options, onChange, ...props }: SelectProps<T>, ctx: ViewContext) {
  const $value = signalify(props.value);
  const $size = signalify(props.size ?? "normal");
  const $selected = derive([$value], (value) => {
    return options.findIndex((o) => o.value === value);
  });

  return (
    <div class={[styles.container, { [styles.small]: derive([$size], (s) => s === "small") }]}>
      <select
        id={id}
        class={[styles.select, { [styles.empty]: derive([$selected], (s) => s === -1) }]}
        value={derive([$selected], String)}
        required={props.required}
        onchange={(e: any) => {
          const index = Number(e.target.value);
          const value = options[index]?.value;
          onChange(value);
        }}
      >
        {derive([props.placeholder], (placeholder) => {
          if (placeholder) {
            return (
              <option value="-1" disabled selected={derive([$selected], (s) => s === -1)}>
                {placeholder}
              </option>
            );
          }
        })}
        {options.map((o, i) => {
          return (
            <option value={String(i)} selected={derive([$selected], (s) => s === i)}>
              {o.label}
            </option>
          );
        })}
      </select>

      <div class={styles.icon}>
        <DownArrowIcon />
      </div>
    </div>
  );
}
