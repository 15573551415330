import { cond, derive, type Signal, type ViewContext } from "@manyducks.co/dolla";
import styles from "./FileAttachment.module.css";
import { File } from "schemas";
import Download from "@icons/Download";
import prettyBytes from "pretty-bytes";
import Close from "@icons/Close";
import { NavStore } from "@stores/NavStore";

interface AttachmentProps {
  $attachment: Signal<File>;
  onDelete?: (file: File) => void | Promise<void>;
}

export function FileAttachment({ $attachment, onDelete }: AttachmentProps, ctx: ViewContext) {
  const nav = ctx.getStore(NavStore);
  const $fileDisplayName = derive([$attachment], (a) => a.fileDisplayName);

  return (
    <div class={styles.attachment}>
      {cond(
        derive([$attachment], (x) => x.mimeType.startsWith("image")),
        <div class={styles.imageGroup}>
          <button
            class={styles.imageButton}
            onClick={(e) => {
              nav.openFileViewer($attachment.get());
            }}
          >
            <img src={derive([$attachment], (x) => `/api/thumbnails/${x.id}`)} alt="" />
          </button>
          <div class={styles.buttonGroup}>
            <button
              class={styles.mainButton}
              style={{
                maxWidth: onDelete ? `calc(100% - 88px)` : `calc(100% - 44px)`,
              }}
              onClick={(e) => {
                e.preventDefault();
                nav.openFileViewer($attachment.get());
              }}
            >
              <span class={styles.fileName} title={$fileDisplayName}>
                {$fileDisplayName}
              </span>
              <div class={styles.fileMeta}>
                <span>{derive([$attachment], (a) => prettyBytes(a.sizeInBytes))}</span>
                <span>{derive([$attachment], (a) => a.mimeType)}</span>
              </div>
            </button>
            <button
              class={styles.secondaryButton}
              onClick={(e) => {
                e.preventDefault();

                // Download file
                const attachment = $attachment.get();
                const a = document.createElement("a");
                const url = new URL(`/static/files/${attachment.fileName}`, window.location.origin).href;
                console.log(url);
                a.style.display = "none";
                a.href = url;
                a.download = attachment.fileDisplayName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              }}
            >
              <Download />
            </button>
            {cond(
              onDelete,
              <button
                class={styles.secondaryButton}
                onClick={(e) => {
                  e.preventDefault();
                  onDelete?.($attachment.get());
                }}
              >
                <Close />
              </button>,
            )}
          </div>
        </div>,
        <div class={styles.buttonGroup}>
          <button
            class={styles.mainButton}
            style={{
              maxWidth: onDelete ? `calc(100% - 88px)` : `calc(100% - 44px)`,
            }}
            onClick={(e) => {
              e.preventDefault();

              const attachment = $attachment.get();

              if (attachment.mimeType.startsWith("image")) {
                // Open in file viewer
                nav.openFileViewer(attachment);
              } else {
                // Open in new tab
                const a = document.createElement("a");
                const url = new URL(`/static/files/${attachment.fileName}`, window.location.origin).href;
                console.log(url);
                a.style.display = "none";
                a.href = url;
                a.target = "_blank";
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              }
            }}
          >
            <span class={styles.fileName} title={$fileDisplayName}>
              {$fileDisplayName}
            </span>
            <div class={styles.fileMeta}>
              <span>{derive([$attachment], (a) => prettyBytes(a.sizeInBytes))}</span>
              <span>{derive([$attachment], (a) => a.mimeType)}</span>
            </div>
          </button>
          <button
            class={styles.secondaryButton}
            onClick={(e) => {
              e.preventDefault();

              // Download file
              const attachment = $attachment.get();
              const a = document.createElement("a");
              const url = new URL(`/static/files/${attachment.fileName}`, window.location.origin).href;
              console.log(url);
              a.style.display = "none";
              a.href = url;
              a.download = attachment.fileDisplayName;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }}
          >
            <Download />
          </button>
          {cond(
            onDelete,
            <button
              class={styles.secondaryButton}
              onClick={(e) => {
                e.preventDefault();
                onDelete?.($attachment.get());
              }}
            >
              <Close />
            </button>,
          )}
        </div>,
      )}
    </div>
  );
}
