import { promptForFiles } from "@helpers/promptForFiles";
import {
  cond,
  derive,
  DialogProps,
  HTTPStore,
  LanguageStore,
  ref,
  repeat,
  signal,
  signalify,
  type ViewContext,
} from "@manyducks.co/dolla";
import { ClockStore } from "@stores/ClockStore";
import { FilesStore } from "@stores/FilesStore";
import { NavStore } from "@stores/NavStore";
import { TasksStore, UpdateTaskMetaData } from "@stores/TasksStore";
import ArrowUpDoubleLine from "@icons/ArrowUpDoubleLine";
import ArrowUpSingleLine from "@icons/ArrowUpSingleLine";
import Calendar from "@icons/Calendar";
import Close from "@icons/Close";
import Paperclip from "@icons/Paperclip";
import Plus from "@icons/Plus";
import Star from "@icons/Star";
import TagIcon from "@icons/Tag";
import UserIcon from "@icons/User";
import { Button } from "@views/Button";
import { CollapsibleListSection } from "@views/CollapsibleListSection";
import { DatePicker } from "@views/DatePicker";
import { Dialog, DialogContent, DialogFooter, DialogHeader } from "@views/Dialog";
import { FileAttachment } from "@views/FileAttachment";
import { SegmentButton } from "@views/SegmentButton";
import { TagInput } from "@views/TagInput";
import { UserPicker } from "@views/UserPicker";
import { InputFile } from "Workspace/Chat/ChatEditor/ChatEditor";
import { startOfDay, subDays } from "date-fns";
import { produce } from "immer";
import { Task } from "schemas";
import styles from "./TaskEditDialog.module.css";

interface TaskEditDialogProps extends DialogProps {
  projectId: number;
  task?: Task;
  color?: string;
  onSubmitted?: () => void;
  onToListClicked?: () => void;
}

export function TaskEditDialog(props: TaskEditDialogProps, ctx: ViewContext) {
  const { translate } = ctx.getStore(LanguageStore);
  const clock = ctx.getStore(ClockStore);
  const tasks = ctx.getStore(TasksStore);
  const nav = ctx.getStore(NavStore);
  const { uploadFile } = ctx.getStore(FilesStore);
  const http = ctx.getStore(HTTPStore);

  const $task = derive([tasks.$cache], (tasks) => {
    if (props.task) {
      return tasks.get(props.task.id);
    }
  });
  const $taskExists = derive([$task], (t) => t != null);

  // Close dialog if task was deleted.
  ctx.watch([$taskExists], (exists) => {
    if (!exists) {
      props.dialog.$$open.set(false);
    }
  });

  const $taskIsFavorite = derive([$task, nav.$favorites], (task, favorites) => {
    if (task == null) return false;
    const { projectId, id } = task;
    return favorites.some((f) => f.path === `/projects/${projectId}/tasks/${id}`);
  });

  const [$submitting, setSubmitting] = signal(false);

  const [$title, setTitle] = signal(props.task?.title ?? "");
  const [$status, setStatus] = signal(props.task?.status ?? "");
  const [$assignedUserId, setAssignedUserId] = signal(props.task?.assignedUserId ?? null);
  const [$priority, setPriority] = signal(props.task?.priority ?? null);
  const [$dueDate, setDueDate] = signal(props.task?.dueDate || null);
  const [$tags, setTags] = signal(props.task?.tags ?? []);

  const [$deletedAttachments, setDeletedAttachments] = signal<string[]>([]);
  const $attachments = derive(
    [$task, $deletedAttachments],
    (task, deletedUUIDs) => task?.attachments.filter((f) => !deletedUUIDs.includes(f.uuid)) ?? [],
  );

  const [$fileUploads, setFileUploads] = signal<InputFile[]>([]);

  const titleElement = ref<HTMLSpanElement>();
  const descriptionElement = ref<HTMLSpanElement>();

  const [$assignedUserEnabled, setAssignedUserEnabled] = signal($assignedUserId.get() != null);
  const [$priorityEnabled, setPriorityEnabled] = signal($priority.get() != null);
  const [$dueDateEnabled, setDueDateEnabled] = signal($dueDate.get() != null);
  const [$tagsEnabled, setTagsEnabled] = signal($tags.get().length > 0);
  const [$attachmentsEnabled, setAttachmentsEnabled] = signal($attachments.get().length > 0);

  // Things that can be edited: title, due date, assigned user, tags, status, priority

  ctx.beforeConnect(() => {
    const titleNode = titleElement.node;
    if (titleNode) {
      titleNode.textContent = $title.get();
    }
    const descriptionNode = descriptionElement.node;
    if (descriptionNode) {
      descriptionNode.textContent = $status.get();
    }
  });

  function addFiles(files: FileList | File[]) {
    for (const file of Array.from(files)) {
      const events = uploadFile(file);
      let uploadId: string;

      events.on("started", (e) => {
        ctx.log("upload started", e);
        uploadId = e.data.uploadId;
        const inputFile: InputFile = {
          file,
          uploadId,
          progress: 0,
          complete: false,
          events,
        };
        setFileUploads(
          produce((current) => {
            current.push(inputFile);
          }),
        );
      });
      events.on("progress", (e) => {
        ctx.log("upload progress", e);
        setFileUploads(
          produce((current) => {
            const found = current.find((f) => f.uploadId === uploadId);
            if (found) {
              found.progress = e.data.percent;
            }
          }),
        );
      });
      events.on("error", (e) => {
        ctx.log("upload error", e);
        setFileUploads(
          produce((current) => {
            const found = current.find((f) => f.uploadId === uploadId);
            if (found) {
              found.error = e.data.error;
            }
          }),
        );
      });
      events.on("complete", (e) => {
        ctx.log("upload complete", e);

        const task = $task.get()!;
        http
          .post<Task>(`/api/tasks/${task.id}/attachments`, { body: { uuids: [uploadId] } })
          .then((res) => {
            ctx.info("Attachment added", res);
            setFileUploads(
              produce((current) => {
                return current.filter((f) => f.uploadId !== uploadId);
              }),
            );
          })
          .catch((err) => {
            ctx.error(err);
          });
      });
    }
  }

  return (
    <Dialog
      onsubmit={async () => {
        if ($submitting.get()) return;

        setSubmitting(true);

        let title = $title.get();
        let status = $status.get();
        let assignedUserId = $assignedUserId.get();
        let priority = $priority.get();
        let dueDate = $dueDate.get();
        let tags = $tags.get();
        let attachments = $attachments.get();

        const data: UpdateTaskMetaData = {
          title: title.trim(),
          status: status.trim() || null,
          assignedUserId: $assignedUserEnabled.get() ? assignedUserId : null,
          priority: $priorityEnabled.get() ? priority : null,
          dueDate: $dueDateEnabled.get() ? dueDate : null,
          tags: $tagsEnabled.get() ? tags : [],
          attachments: $attachmentsEnabled.get() ? attachments.map((f) => f.uuid) : [],
        };

        if (props.task) {
          await tasks.updateTaskMeta(props.task.id, data);
        } else {
          // tasks.createTask(props.projectId, data);
        }

        setSubmitting(false);
        props.dialog.$$open.set(false);
      }}
      transitionIn={props.dialog.transitionIn}
      transitionOut={props.dialog.transitionOut}
      accentColor={props.color}
    >
      <DialogHeader
        $$open={props.dialog.$$open}
        $title={translate("views.taskListItem.editDialog.dialogTitle")}
        buttons={[
          cond(
            props.task,
            <button
              class={[styles.favoriteButton, { [styles.active]: $taskIsFavorite }]}
              onClick={(e) => {
                e.preventDefault();

                const { projectId, id } = props.task!;
                const path = `/projects/${projectId}/tasks/${id}`;

                if ($taskIsFavorite.get()) {
                  const favorite = nav.$favorites.get().find((f) => f.path === path);
                  if (favorite) {
                    nav.removeFavorite(favorite.id);
                  }
                } else {
                  nav.addFavorite(path);
                }
              }}
            >
              <Star />
            </button>,
          ),
        ]}
      />
      <DialogContent>
        <section class={styles.titleAndDescription}>
          <div class={styles.titleContainer}>
            {cond(
              derive([$title], (x) => x.trim().length === 0),
              <span class={styles.titlePlaceholder}>
                {translate("views.taskListItem.editDialog.titlePlaceholder")}
              </span>,
            )}
            <span
              ref={titleElement}
              class={styles.titleInput}
              contentEditable
              role="textbox"
              onInput={(e) => {
                const value = (e.currentTarget as HTMLElement).textContent ?? "";
                setTitle(value);
              }}
            ></span>
          </div>

          <div class={styles.descriptionContainer}>
            {cond(
              derive([$status], (x) => x.trim().length === 0),
              <span class={styles.descriptionPlaceholder}>
                {translate("views.taskListItem.editDialog.descriptionPlaceholder")}
              </span>,
            )}
            <span
              ref={descriptionElement}
              class={styles.descriptionInput}
              contentEditable
              role="textbox"
              onInput={(e) => {
                const value = (e.currentTarget as HTMLElement).textContent ?? "";
                setStatus(value);
              }}
            ></span>
          </div>
        </section>

        <section class={[styles.formGroup, { [styles.visible]: $assignedUserEnabled }]}>
          <CollapsibleListSection
            $title={translate("views.taskListItem.editDialog.fields.assign.label")}
            controls={[
              {
                id: "clear",
                icon: <Close />,
                title: translate("views.taskListItem.editDialog.clearLabel"),
                onClick: () => {
                  setAssignedUserId(null);
                  setAssignedUserEnabled(false);
                },
              },
            ]}
          >
            <div class={styles.formGroupContent}>
              <UserPicker
                $selectedId={derive([$assignedUserId], (id) => id ?? undefined)}
                $projectId={signalify(props.projectId)}
                onchange={(newUserId) => {
                  setAssignedUserId(newUserId ?? null);
                }}
              />
            </div>
          </CollapsibleListSection>
        </section>

        <section class={[styles.formGroup, { [styles.visible]: $dueDateEnabled }]}>
          <CollapsibleListSection
            $title={translate("views.taskListItem.editDialog.fields.dueDate.label")}
            controls={[
              {
                id: "clear",
                icon: <Close />,
                title: translate("views.taskListItem.editDialog.clearLabel"),
                onClick: () => {
                  setDueDate(null);
                  setDueDateEnabled(false);
                },
              },
            ]}
          >
            <div class={styles.formGroupContent}>
              <DatePicker
                $value={$dueDate}
                $min={derive([clock.$hour], () => startOfDay(subDays(new Date(), 1)))}
                onChange={(value) => {
                  setDueDate(value);
                }}
                accentColor={props.color}
                placeholder="---"
              />
            </div>
          </CollapsibleListSection>
        </section>

        <section class={[styles.formGroup, { [styles.visible]: $priorityEnabled }]}>
          <CollapsibleListSection
            $title={translate("views.taskListItem.editDialog.fields.priority.label")}
            controls={[
              {
                id: "clear",
                icon: <Close />,
                title: translate("views.taskListItem.editDialog.clearLabel"),
                onClick: () => {
                  setPriority(null);
                  setPriorityEnabled(false);
                },
              },
            ]}
          >
            <div class={styles.formGroupContent}>
              <SegmentButton
                value={$priority}
                onChange={(value) => {
                  setPriority(value);
                }}
                segments={[
                  {
                    value: 2,
                    content: (
                      <div class={styles.metaPriorityItem}>
                        <ArrowUpDoubleLine />
                        <span>{translate("views.taskListItem.priority.now.title")}</span>
                      </div>
                    ),
                  },
                  {
                    value: 1,
                    content: (
                      <div class={styles.metaPriorityItem}>
                        <ArrowUpSingleLine />
                        <span>{translate("views.taskListItem.priority.soon.title")}</span>
                      </div>
                    ),
                  },
                  {
                    value: null,
                    content: (
                      <div class={styles.metaPriorityItem}>
                        <span>{translate("views.taskListItem.priority.none.title")}</span>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </CollapsibleListSection>
        </section>

        <section class={[styles.formGroup, { [styles.visible]: $attachmentsEnabled }]}>
          <CollapsibleListSection
            $title={translate("views.taskListItem.editDialog.fields.attachments.label")}
            controls={[
              {
                id: "add",
                icon: <Plus />,
                title: "ADD",
                variant: "accent",
                onClick: () => {
                  // Select files to upload.
                  promptForFiles({ multiple: true, accept: "*" }).then((files) => {
                    addFiles(files);
                  });
                },
              },
              {
                id: "clear",
                icon: <Close />,
                title: translate("views.taskListItem.editDialog.clearLabel"),
                onClick: () => {
                  setDeletedAttachments($attachments.get().map((f) => f.uuid));
                  setAttachmentsEnabled(false);
                },
              },
            ]}
          >
            <div class={styles.formGroupContent}>
              {cond(
                derive([$attachments], (f) => f.length > 0),
                <ul class={styles.attachmentsList}>
                  {repeat(
                    $attachments,
                    (f) => f.id,
                    ($file) => (
                      <li>
                        <FileAttachment
                          $attachment={$file}
                          onDelete={() => {
                            const file = $file.get();
                            setDeletedAttachments((current) => [...current, file.uuid]);
                          }}
                        />
                      </li>
                    ),
                  )}
                </ul>,
                <p>No attachments.</p>,
              )}
            </div>
          </CollapsibleListSection>
        </section>

        <section class={[styles.formGroup, { [styles.visible]: $tagsEnabled }]}>
          <CollapsibleListSection
            $title={translate("views.taskListItem.editDialog.fields.tags.label")}
            controls={[
              {
                id: "clear",
                icon: <Close />,
                title: translate("views.taskListItem.editDialog.clearLabel"),
                onClick: () => {
                  setTags([]);
                  setTagsEnabled(false);
                },
              },
            ]}
          >
            <div class={styles.formGroupContent}>
              <TagInput
                $value={$tags}
                onChange={({ tags }) => {
                  setTags(tags);
                }}
              />
            </div>
          </CollapsibleListSection>
        </section>

        <ul class={styles.metaButtons}>
          <li>
            <button
              type="button"
              class={[styles.metaButton, { [styles.hidden]: $assignedUserEnabled }]}
              onClick={() => {
                setAssignedUserEnabled(true);
              }}
            >
              <div class={styles.metaButtonIcon}>
                <UserIcon />
              </div>
              {translate("views.taskListItem.editDialog.fields.assign.chipText")}
            </button>
          </li>
          <li>
            <button
              type="button"
              class={[styles.metaButton, { [styles.hidden]: $dueDateEnabled }]}
              onClick={() => {
                setDueDateEnabled(true);
              }}
            >
              <div class={styles.metaButtonIcon}>
                <Calendar />
              </div>
              {translate("views.taskListItem.editDialog.fields.dueDate.chipText")}
            </button>
          </li>
          <li>
            <button
              type="button"
              class={[styles.metaButton, { [styles.hidden]: $priorityEnabled }]}
              onClick={() => {
                setPriorityEnabled(true);
              }}
            >
              <div class={styles.metaButtonIcon}>
                <ArrowUpDoubleLine />
              </div>
              {translate("views.taskListItem.editDialog.fields.priority.chipText")}
            </button>
          </li>
          <li>
            <button
              type="button"
              class={[styles.metaButton, { [styles.hidden]: $attachmentsEnabled }]}
              onClick={() => {
                setAttachmentsEnabled(true);
              }}
            >
              <div class={styles.metaButtonIcon}>
                <Paperclip />
              </div>
              {translate("views.taskListItem.editDialog.fields.attachments.chipText")}
            </button>
          </li>
          <li>
            <button
              type="button"
              class={[styles.metaButton, { [styles.hidden]: $tagsEnabled }]}
              onClick={() => {
                setTagsEnabled(true);
              }}
            >
              <div class={styles.metaButtonIcon}>
                <TagIcon />
              </div>
              {translate("views.taskListItem.editDialog.fields.tags.chipText")}
            </button>
          </li>
        </ul>
      </DialogContent>
      <DialogFooter>
        {cond(
          props.onToListClicked,
          <Button
            type="button"
            disabled={$submitting}
            onClick={() => {
              props.dialog.$$open.set(false);
              props.onToListClicked?.();
            }}
          >
            {translate("views.taskListItem.editDialog.toListButtonText")}
          </Button>,
        )}
        <div style={{ flex: "1 1 100%" }} />
        <Button type="submit" disabled={$submitting}>
          {translate("views.taskListItem.editDialog.submitButtonText")}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
