import { derive, signalify, type Signal, type ViewContext } from "@manyducks.co/dolla";
import { type Project } from "schemas";
import { ThemeStore } from "@stores/ThemeStore";
import FolderIcon from "@icons/Folder";
import styles from "./ProjectCharm.module.css";

interface ProjectCharmProps {
  project: Project | Signal<Project | undefined> | undefined;
}

export function ProjectCharm(props: ProjectCharmProps, ctx: ViewContext) {
  const theme = ctx.getStore(ThemeStore);
  const $project = signalify(props.project);

  return (
    <div class={styles.projectCharm} style={theme.getTheme$(derive([$project], (p) => p?.color))}>
      <div class={styles.charmIcon}>
        <FolderIcon />
      </div>
      <span>{derive([$project], (p) => p?.name ?? "???")}</span>
    </div>
  );
}
