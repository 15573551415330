import { adjustAccentColor, Theme, type ThemeCSSVariables } from "@helpers/makeTheme";
import {
  derive,
  MaybeSignal,
  RouterStore,
  signal,
  type Signal,
  signalify,
  type StoreContext,
} from "@manyducks.co/dolla";
import { argbFromHex, Blend, hexFromArgb } from "@material/material-color-utilities";
import { Project, User } from "schemas";
import { AuthStore } from "./AuthStore";
import { ProjectsStore } from "./ProjectsStore";

export enum ColorSchemes {
  System = "system",
  Light = "light",
  Dark = "dark",
}

type ThemeSet = {
  light: ThemeCSSVariables;
  dark: ThemeCSSVariables;
};

// Store exactly one copy of each generated theme.
const themeCache = new Map<string, ThemeSet>();

function getThemeSet(hex: string): ThemeSet {
  const cached = themeCache.get(hex);

  if (cached) {
    return cached;
  } else {
    const light = new Theme(hex, false);
    const dark = new Theme(hex, true);
    const theme = {
      light: light.toCSS(),
      dark: dark.toCSS(),
    };
    themeCache.set(hex, theme);
    return theme;
  }
}

export function ThemeStore(ctx: StoreContext) {
  const router = ctx.getStore(RouterStore);
  const auth = ctx.getStore(AuthStore);
  const projects = ctx.getStore(ProjectsStore);

  const [$systemScheme, setSystemScheme] = signal<ColorSchemes>(ColorSchemes.Light);
  const [$selectedScheme, setSelectedScheme] = signal<ColorSchemes>(ColorSchemes.System);

  const $defaultColor = signalify("#2D79E9");

  const $placeColor = derive([router.$params, projects.$cache, auth.$me], (params, projects, me) => {
    if (params.projectId) {
      const project = projects.find((p) => p.id === params.projectId);
      if (project) {
        return project.color;
      }
    }
    return me?.color ?? "#888";
  });

  const $currentScheme = derive([$systemScheme, $selectedScheme], (system, selected) => {
    if (
      selected === ColorSchemes.Dark ||
      (selected === ColorSchemes.System && system === ColorSchemes.Dark)
    ) {
      return ColorSchemes.Dark;
    } else {
      return ColorSchemes.Light;
    }
  });

  const $cssVars = derive([$currentScheme, $defaultColor], (scheme, defaultColor) => {
    const theme = getThemeSet(defaultColor);
    if (scheme === ColorSchemes.Dark) {
      return theme.dark;
    } else {
      return theme.light;
    }
  });

  const $userThemeSet = derive([auth.$me, $defaultColor], (me, defaultColor) => {
    return getThemeSet(me?.color ?? defaultColor);
  });
  const $userTheme = derive([$userThemeSet, $currentScheme], (theme, scheme) => {
    if (scheme === ColorSchemes.Dark) {
      return theme.dark;
    } else {
      return theme.light;
    }
  });

  /**
   * Returns a theme build around `color` as a set of CSS variables. Automatically adapts for light and dark mode.
   */
  function getTheme$(color?: string | Signal<string | undefined>) {
    return derive([color, $defaultColor, $currentScheme], (c, defaultColor, scheme) => {
      const theme = getThemeSet(c ?? defaultColor);
      if (scheme === ColorSchemes.Dark) {
        return theme.dark;
      } else {
        return theme.light;
      }
    });
  }

  function getAdjustedColor$(color?: MaybeSignal<string | undefined>) {
    return derive([color, $defaultColor, $currentScheme], (c, defaultColor, scheme) => {
      return adjustAccentColor(c ?? defaultColor, scheme === ColorSchemes.Dark);
    });
  }

  function getBlendedColor$(
    color?: MaybeSignal<string | undefined>,
    sourceColor?: MaybeSignal<string | undefined>,
  ): Signal<string> {
    return derive([color, sourceColor], (c, s) => {
      const argbColor = argbFromHex(c ?? "#888");
      const argbSourceColor = argbFromHex(s ?? "#888");
      const blendedColor = Blend.harmonize(argbColor, argbSourceColor);
      return hexFromArgb(blendedColor);
    });
  }

  function getBlendedUserColor$(
    user?: MaybeSignal<User | undefined>,
    project?: MaybeSignal<Project | undefined>,
  ): Signal<string> {
    return derive([user, project], (u, p) => {
      const userColor = argbFromHex(u?.color ?? "#888");
      const projectColor = argbFromHex(p?.color ?? "#888");
      const blendedColor = Blend.harmonize(userColor, projectColor);
      return hexFromArgb(blendedColor);
    });
  }

  const $projectColor = derive([router.$params, projects.$cache, auth.$me], (params, projects, user) => {
    if (params.projectId) {
      const project = projects.find((p) => p.id === params.projectId);
      if (project) {
        return project.color;
      }
    }
  });

  // Adds CSS vars to document body when they change.
  ctx.watch([$projectColor, $currentScheme], (color, scheme) => {
    let metaThemeColor: string;

    if (color != null) {
      const theme = getThemeSet(color);
      const vars = scheme === ColorSchemes.Light ? theme.light : theme.dark;

      Object.keys(vars).forEach((key) => {
        document.body.style.setProperty(key, (vars as any)[key]);
      });

      metaThemeColor = vars["--theme-primary"];
    } else {
      const theme = getThemeSet("#888");
      const vars = scheme === ColorSchemes.Light ? theme.light : theme.dark;

      metaThemeColor = vars["--theme-neutral"];
    }

    // Update iOS status bar color.
    document.querySelector('meta[name="theme-color"]')?.setAttribute("content", metaThemeColor);

    const lightThemeAccent = document.querySelector("#light-theme-accent") as HTMLMetaElement;
    if (lightThemeAccent) lightThemeAccent.content = metaThemeColor;

    const darkThemeAccent = document.querySelector("#dark-theme-accent") as HTMLMetaElement;
    if (darkThemeAccent) lightThemeAccent.content = metaThemeColor;
  });

  // TODO: Add queueUpdate to store context (they can manipulate the DOM)
  // ctx.observe($userTheme, (theme) => {
  //   document.body.style.setProperty("--color-user-accent", theme.accent[0]);
  //   document.body.style.setProperty("--color-user-accent-contrast", theme.accentContrast);
  //   document.body.style.setProperty("--color-user-accent-1", theme.accent[1]);
  //   document.body.style.setProperty("--color-user-accent-2", theme.accent[2]);
  //   document.body.style.setProperty("--color-user-accent-3", theme.accent[3]);
  //   document.body.style.setProperty("--color-user-accent-4", theme.accent[4]);
  //   document.body.style.setProperty("--color-user-accent-5", theme.accent[5]);

  //   const lightThemeAccent = document.querySelector("#light-theme-accent") as HTMLMetaElement;
  //   if (lightThemeAccent) lightThemeAccent.content = theme.accent[1];

  //   const darkThemeAccent = document.querySelector("#dark-theme-accent") as HTMLMetaElement;
  //   if (darkThemeAccent) lightThemeAccent.content = theme.accent[1];
  // });

  ctx.watch([$currentScheme], (scheme) => {
    if (scheme === ColorSchemes.Dark) {
      document.body.classList.remove("color-scheme-light");
      document.body.classList.add("color-scheme-dark");
    } else {
      document.body.classList.add("color-scheme-light");
      document.body.classList.remove("color-scheme-dark");
    }
  });

  ctx.onConnected(async () => {
    const stored = localStorage.getItem("quack-color-scheme") as ColorSchemes;

    if (stored && Object.values(ColorSchemes).includes(stored)) {
      setSelectedScheme(stored);
    } else {
      setSelectedScheme(ColorSchemes.System);
    }

    // Persist the selected scheme to local storage.
    ctx.watch([$selectedScheme], (scheme) => {
      localStorage.setItem("quack-color-scheme", scheme);
    });

    // Watch the system color scheme for changes.
    const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");

    // Set system scheme to match the current OS setting.
    setSystemScheme(darkModeQuery.matches ? ColorSchemes.Dark : ColorSchemes.Light);

    // Update the system scheme when it changes.
    darkModeQuery.addEventListener("change", (e) => {
      setSystemScheme(e.matches ? ColorSchemes.Dark : ColorSchemes.Light);
    });
  });

  return {
    ColorSchemes,
    $selectedScheme,
    setSelectedScheme,
    $currentScheme,
    $cssVars,
    $isDark: derive([$currentScheme], (scheme) => scheme === ColorSchemes.Dark),
    $userTheme,

    $placeColor,

    getTheme$,
    getAdjustedColor$,
    getBlendedColor$,
    getBlendedUserColor$,
  };
}
