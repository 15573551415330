import { ref, signal, type MaybeSignal, type ViewContext } from "@manyducks.co/dolla";
import Quill, { DeltaOperation } from "quill";
// import MagicUrl from "quill-magic-url";
import markdownStyles from "@views/TextEditor/Markdown.module.css";
import Delta from "quill-delta";
import styles from "./ChatMessageContent.module.css";
// import { QuackLinkBlot } from "@views/TextEditor/blots/QuackLinkBlot";

// TODO: Pass a Y.js provider instead of a socket
interface ChatMessageContentProps {
  delta: MaybeSignal<DeltaOperation[]>;
  // cardId: string | Readable<string>;
}

export function ChatMessageContent(props: ChatMessageContentProps, ctx: ViewContext) {
  // const { translate } = ctx.getStore(LanguageStore);
  const editorElement = ref<HTMLDivElement>();

  let editor: Quill;

  ctx.onConnected(() => {
    editor = new Quill(editorElement.node!, {
      readOnly: true,
      formats: ["bold", "italic", "link", "list", "blockquote", "image", "indent", "code-block", "quackLink"],
    });

    // Set link blot context object so it can display live link data and open cards.
    // (editor.scroll as any).emitter.on("quack-link-attached", (blot: QuackLinkBlot) => {
    //   blot.setContext(ctx, props.cardId);
    // });
  });

  ctx.watch([props.delta ?? []], (ops) => {
    const delta = new Delta(ops);
    editor.setContents(delta, "silent");
  });

  return (
    <div
      class={styles.container}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div class={[styles.editor, markdownStyles.markdown]} ref={editorElement}></div>
    </div>
  );
}
