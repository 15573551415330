export default {
  "layout": "ProjectOverview_layout_ad48d",
  "projectHeader": "ProjectOverview_projectHeader_ad48d",
  "projectHeaderIcon": "ProjectOverview_projectHeaderIcon_ad48d",
  "projectHeaderText": "ProjectOverview_projectHeaderText_ad48d",
  "charmsBar": "ProjectOverview_charmsBar_ad48d",
  "userList": "ProjectOverview_userList_ad48d",
  "emptyUserListMessage": "ProjectOverview_emptyUserListMessage_ad48d",
  "userListItem": "ProjectOverview_userListItem_ad48d",
  "userLayout": "ProjectOverview_userLayout_ad48d",
  "userInfo": "ProjectOverview_userInfo_ad48d",
  "userIcon": "ProjectOverview_userIcon_ad48d",
  "roleBadge": "ProjectOverview_roleBadge_ad48d",
  "userText": "ProjectOverview_userText_ad48d",
  "userName": "ProjectOverview_userName_ad48d",
  "userNameLabel": "ProjectOverview_userNameLabel_ad48d",
  "userStatus": "ProjectOverview_userStatus_ad48d",
  "userActions": "ProjectOverview_userActions_ad48d",
  "userMeta": "ProjectOverview_userMeta_ad48d",
  "cardLauncherIcon": "ProjectOverview_cardLauncherIcon_ad48d",
  "menuPanelIcon": "ProjectOverview_menuPanelIcon_ad48d",
  "addUserSection": "ProjectOverview_addUserSection_ad48d",
  "justifyEnd": "ProjectOverview_justifyEnd_ad48d",
  "toolButtonIcon": "ProjectOverview_toolButtonIcon_ad48d",
  "editForm": "ProjectOverview_editForm_ad48d",
  "editFormGroup": "ProjectOverview_editFormGroup_ad48d",
  "inviteListItem": "ProjectOverview_inviteListItem_ad48d",
  "inviteListItemName": "ProjectOverview_inviteListItemName_ad48d"
};import "ni:sha-256;cJf047iOMA3TqFE5-mWzQh3G39xOyIxD3q2loIghHwg";