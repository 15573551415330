import {
  cond,
  derive,
  LanguageStore,
  type MaybeSignal,
  ref,
  signal,
  Signal,
  type ViewContext,
} from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { ThemeStore } from "@stores/ThemeStore";
import DownArrow from "@icons/DownArrow";
import { HoverMenu } from "@views/HoverMenu";
import { AssignMenu } from "@views/TaskListItem/AssignMenu";
import { UserAvatar } from "@views/UserAvatar";
import styles from "./UserPicker.module.css";

interface UserPickerProps {
  $selectedId: Signal<number | undefined>;
  $projectId: Signal<number>;
  onchange: (newId: number | undefined) => void;
  $disabled?: Signal<boolean>;
  emptyLabel?: MaybeSignal<string>;
}

export function UserPicker({ $selectedId, onchange, ...props }: UserPickerProps, ctx: ViewContext) {
  const { $users } = ctx.getStore(AuthStore);
  const projects = ctx.getStore(ProjectsStore);
  const theme = ctx.getStore(ThemeStore);
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);

  // const $disabled = readable(props.$disabled ?? false);
  const $selected = derive([$users, $selectedId], (users, id) => users?.find((u) => u.id === id));

  const $project = derive([props.$projectId, projects.$cache], (id, projects) =>
    projects.find((p) => p.id === id),
  );

  const [$assignMenuIsOpen, setAssignMenuIsOpen] = signal(false);
  const assignMenuAnchor = ref<HTMLElement>();

  return (
    <div
      class={styles.container}
      style={theme.getTheme$(derive([$selected, $project], (user, project) => user?.color ?? project?.color))}
    >
      <button
        type="button"
        ref={assignMenuAnchor}
        class={styles.button}
        onClick={(e) => {
          setAssignMenuIsOpen(true);
        }}
      >
        {cond(
          $selected,
          <div class={styles.avatar}>
            <UserAvatar src={derive([$selected], (user) => user?.avatar ?? "")} />
          </div>,
        )}
        <div class={styles.label}>
          {derive(
            [$selected, $currentLanguage],
            (user) => user?.name ?? translate("views.userPicker.notAssignedLabel").get(),
          )}
        </div>
        <div class={styles.indicator}>
          <DownArrow />
        </div>
      </button>

      <HoverMenu
        $$open={signal.toSettable($assignMenuIsOpen, setAssignMenuIsOpen)}
        anchorRef={assignMenuAnchor}
        color={derive([$project], (p) => p?.color)}
        distanceFromAnchor={14}
        preferHorizontalAlignment="center"
        preferVerticalAlignment="below"
      >
        <AssignMenu
          $projectId={props.$projectId}
          $value={derive([$selectedId], (id) => id ?? null)}
          onChange={(user) => {
            onchange(user?.id);
            setAssignMenuIsOpen(false);
          }}
        />
      </HoverMenu>

      {/* <Searchdown<number | null>
        size={props.size}
        color={computed($selected, (u) => u?.color ?? "#888")}
        $value={computed($selectedId, (id) => id ?? null)}
        suggestions={$suggestions}
        emptyLabel={props.emptyLabel}
        onInputChange={(value) => {
          ctx.log({ value });
        }}
        onSelect={(suggestion) => {
          ctx.log({ suggestion });
          onchange(suggestion.value as number);
        }}
      /> */}
    </div>
  );
}
