export default {
  "container": "TextEditor_container_188fa",
  "toolbar": "TextEditor_toolbar_188fa",
  "hidden": "TextEditor_hidden_188fa",
  "toolbarGroup": "TextEditor_toolbarGroup_188fa",
  "toolbarButton": "TextEditor_toolbarButton_188fa",
  "active": "TextEditor_active_188fa",
  "toolbarDivider": "TextEditor_toolbarDivider_188fa",
  "editor": "TextEditor_editor_188fa",
  "searchMenu": "TextEditor_searchMenu_188fa",
  "linkBlot": "TextEditor_linkBlot_188fa",
  "linkBlotIcon": "TextEditor_linkBlotIcon_188fa",
  "linkBlotTaskStage": "TextEditor_linkBlotTaskStage_188fa",
  "linkBlotTitle": "TextEditor_linkBlotTitle_188fa"
};import "ni:sha-256;xIcWEUzDoS800nSQhtpC0nFCrHdmIguy5AS3iq4Woes";