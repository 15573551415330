export default {
  "loader": "DuckLoader_loader_54825",
  "loaderPane": "DuckLoader_loaderPane_54825",
  "visible": "DuckLoader_visible_54825",
  "animating": "DuckLoader_animating_54825",
  "container": "DuckLoader_container_54825",
  "outerCircle": "DuckLoader_outerCircle_54825",
  "cardLoaderOuterSpin": "DuckLoader_cardLoaderOuterSpin_54825",
  "innerCircle": "DuckLoader_innerCircle_54825",
  "cardLoaderInnerSpin": "DuckLoader_cardLoaderInnerSpin_54825",
  "emoji": "DuckLoader_emoji_54825",
  "cardLoaderDuckFloat": "DuckLoader_cardLoaderDuckFloat_54825",
  "background": "DuckLoader_background_54825"
};import "ni:sha-256;x-2TaB_oqVsM7f3hPWA8Q0Bi6KuJWiBFOYDG_Ij8syA";