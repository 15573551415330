import {
  type InputType,
  type MaybeSignal,
  type Ref,
  type SettableSignal,
  type ViewContext,
  signalify,
} from "@manyducks.co/dolla";
import styles from "./TextInput.module.css";

interface TextInputProps {
  ref?: Ref<HTMLInputElement>;
  $$value: SettableSignal<string>;
  class?: MaybeSignal<any>;
  autofocus?: boolean;
  autocomplete?: boolean;
  disabled?: MaybeSignal<boolean>;
  id?: string;
  type?: InputType;
  name?: string;
  placeholder?: MaybeSignal<string>;
  minlength?: MaybeSignal<number>;
  onChange?: (value: Event) => void;
  onKeyDown?: (value: KeyboardEvent) => void;
}

export function TextInput(props: TextInputProps, ctx: ViewContext) {
  const $class = signalify(props.class);
  const $placeholder = signalify(props.placeholder ?? "");

  const { autofocus, autocomplete, onChange, onKeyDown, id, type, name } = props;

  return (
    <input
      ref={props.ref}
      className={[styles.input, $class]}
      id={id}
      type={type}
      name={name}
      placeholder={$placeholder}
      minLength={props.minlength}
      autofocus={autofocus}
      autocomplete={autocomplete}
      disabled={props.disabled}
      $$value={props.$$value}
      onKeyDown={(e) => {
        if (onKeyDown) {
          onKeyDown(e);
        }
      }}
      onChange={(e) => {
        if (onChange) {
          onChange(e);
        }
      }}
    />
  );
}
