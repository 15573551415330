import { type MaybeSignal, type Renderable, type ViewContext } from "@manyducks.co/dolla";
import styles from "./MenuList.module.css";

interface MenuListProps {}

export function MenuList(props: MenuListProps, ctx: ViewContext) {
  return <ul class={styles.menuList}>{ctx.outlet()}</ul>;
}

interface MenuListItemProps {
  icon: Renderable;
  label: MaybeSignal<string>;
  variant?: "normal" | "destructive";
  disabled?: MaybeSignal<boolean>;
  onClick: (e: MouseEvent) => void;
}

export function MenuListItem(props: MenuListItemProps, ctx: ViewContext) {
  return (
    <li class={styles.menuListItem}>
      <button
        class={[styles.menuListItemButton, styles[props.variant ?? "normal"]]}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        <div class={styles.menuListItemIcon}>{props.icon}</div>
        <span class={styles.menuListItemLabel}>{props.label}</span>
      </button>
    </li>
  );
}
