import { sticky } from "@helpers/sticky";
import {
  cond,
  derive,
  designalify,
  LanguageStore,
  type MaybeSignal,
  ref,
  type Renderable,
  repeat,
  RouterStore,
  signal,
  type Signal,
  type ViewContext,
} from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { BreakpointStore } from "@stores/BreakpointStore";
import { ChatStore } from "@stores/ChatStore";
import { NavStore } from "@stores/NavStore";
import { NotesStore } from "@stores/NotesStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { TasksStore } from "@stores/TasksStore";
import { ThemeStore } from "@stores/ThemeStore";
import ArrowLeft from "@icons/ArrowLeft";
import ArrowRight from "@icons/ArrowRight";
import HomeIcon from "@icons/Home";
import Search from "@icons/Search";
import { Chat } from "../Chat/Chat";
import styles from "./PlaceLayout.module.css";
import Menu from "@icons/Menu";
import MenuAlt from "@icons/MenuAlt";
import Dashboard from "@icons/Dashboard";
import Places from "@icons/Places";

export interface Tab {
  text: MaybeSignal<string>;
  href: MaybeSignal<string>;
  icon: Renderable;
  active: (pattern: string) => boolean;
  hidden?: MaybeSignal<boolean>;
}

interface PlaceLayoutProps {
  color?: string | Signal<string> | Signal<string | undefined>;
  title: MaybeSignal<string>;
  tabs?: MaybeSignal<Tab[]>;
}

export function PlaceLayout(props: PlaceLayoutProps, ctx: ViewContext) {
  const auth = ctx.getStore(AuthStore);
  const router = ctx.getStore(RouterStore);
  const nav = ctx.getStore(NavStore);
  const chat = ctx.getStore(ChatStore);
  const projects = ctx.getStore(ProjectsStore);
  const tasks = ctx.getStore(TasksStore);
  const notes = ctx.getStore(NotesStore);
  const theme = ctx.getStore(ThemeStore);
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);
  const breakpoint = ctx.getStore(BreakpointStore);

  const contentElement = ref<HTMLDivElement>();

  const [$contentWidth, setContentWidth] = signal(0);

  ctx.onMount(() => {
    const element = contentElement.node;

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setContentWidth(entry.contentRect.width);
      }
    });

    observer.observe(element!);

    ctx.onUnmount(() => {
      observer.disconnect();
    });
  });

  const $subtitle = derive(
    [router.$pattern, router.$params, tasks.$cache, notes.$cache, $currentLanguage],
    (pattern, params, tasks, notes, lang) => {
      if (pattern != null) {
        if (pattern.startsWith("/projects/{#projectId}/tasks/{#taskId}")) {
          const task = tasks.get(params.taskId as number);
          if (task) {
            return (
              <span>
                <span class={styles.deemphasized}>{translate("workspace.project.tasks.tabName")}/</span>
                {task.title}
              </span>
            );
          }
        }
        if (pattern.startsWith("/projects/{#projectId}/notes/{#noteId}")) {
          const note = notes.get(params.noteId as number);
          if (note) {
            return (
              <span>
                <span class={styles.deemphasized}>{translate("workspace.project.notes.tabName")}/</span>
                {note.title}
              </span>
            );
          }
        }
        if (pattern.startsWith("/projects/{#projectId}/calendar/{#year}/{#month}/{#day}")) {
          const { year, month, day } = params;
          if (year != null && month != null && day != null) {
            const formatted = new Intl.DateTimeFormat([lang!, "en-US"], {
              dateStyle: "medium",
            }).format(new Date(Number(year), Number(month) - 1, Number(day)));
            return (
              <span>
                <span class={styles.deemphasized}>{translate("workspace.project.calendar.tabName")}/</span>
                {formatted}
              </span>
            );
          }
        }
      }
    },
  );
  const $latestSubtitle = derive([$subtitle], sticky());

  const $withBackButton = derive([$subtitle, $contentWidth], (subtitle, width) => {
    return width < 1200 && subtitle != null;
  });

  function goBack() {
    const pattern = router.$pattern.get();

    if (pattern != null) {
      if (pattern.startsWith("/projects/{#projectId}/tasks")) {
        if (pattern.startsWith("/projects/{#projectId}/tasks/{#taskId}")) {
          const { projectId } = router.$params.get();
          return router.navigate(`/projects/${projectId}/tasks`);
        } else {
          const { projectId } = router.$params.get();
          return router.navigate(`/projects/${projectId}`);
        }
      } else if (pattern.startsWith("/projects/{#projectId}/notes")) {
        if (pattern.startsWith("/projects/{#projectId}/notes/{#noteId}")) {
          const { projectId } = router.$params.get();
          return router.navigate(`/projects/${projectId}/notes`);
        } else {
          const { projectId } = router.$params.get();
          return router.navigate(`/projects/${projectId}`);
        }
      } else if (pattern.startsWith("/projects/{#projectId}/calendar")) {
        if (pattern.startsWith("/projects/{#projectId}/calendar/{#year}/{#month}")) {
          const { projectId, year, month } = router.$params.get();
          return router.navigate(`/projects/${projectId}/calendar/${year}/${month}`);
        } else {
          const { projectId } = router.$params.get();
          return router.navigate(`/projects/${projectId}/calendar`);
        }
      }
    }

    // If all else fails, just go back.
    router.back();
  }

  const $menuBadgeNumber = derive([auth.$updateAvailable, projects.$invites], (hasUpdate, invites) => {
    let count = invites.length;
    if (hasUpdate) {
      count += 1;
    }
    return count;
  });

  const $currentPageIsFavorite = derive([router.$path, nav.$favorites], (path, favorites) =>
    favorites.some((f) => f.path === path),
  );

  return (
    <section class={[styles.layout]} style={theme.getTheme$(props.color)}>
      <header
        class={[
          styles.header,
          {
            [styles.withBackButton]: $withBackButton,
          },
        ]}
      >
        <button
          class={[styles.homeButton]}
          onClick={() => {
            router.navigate("/");
          }}
        >
          <div class={styles.homeButtonBackdrop} />
          {/* <MenuAlt /> */}
          {/* <Places /> */}
          <HomeIcon />
        </button>

        <div class={styles.titleArea}>
          <div class={[styles.title, { [styles.withBackButton]: $withBackButton }]}>
            <button
              class={[styles.iconButton, styles.backButton]}
              onClick={() => {
                goBack();
              }}
            >
              <ArrowLeft />
            </button>
            <div class={styles.titleContent}>
              <div class={styles.titleText}>
                <span class={styles.mainText}>{props.title}</span>
                <span class={styles.subText}>{$latestSubtitle}</span>
              </div>
            </div>
          </div>

          <ul class={styles.titleControls}>
            <li>
              <button class={styles.headerButton}>F/R</button>
            </li>
            <li>
              <button class={styles.headerButton}>USER</button>
            </li>
          </ul>
        </div>
      </header>

      <div class={styles.main}>
        <nav class={[styles.tabs]}>
          {cond(
            props.tabs,
            <ul>
              {repeat(
                props.tabs!,
                (tab) => designalify(tab.href),
                ($tab) => (
                  <li class={{ [styles.hidden]: derive([$tab], (t) => t.hidden) }}>
                    <a
                      href={derive([$tab], (t) => t.href)}
                      class={{
                        [styles.active]: derive([router.$pattern, $tab], (pattern, tab) =>
                          pattern ? tab.active(pattern) : false,
                        ),
                      }}
                    >
                      <div class={styles.tabBackdrop} />
                      <div class={styles.tabIcon}>{derive([$tab], (t) => t.icon)}</div>
                      {/* {derive([$tab], (t) => t.icon)} */}
                    </a>
                  </li>
                ),
              )}
            </ul>,
          )}
        </nav>

        <div class={styles.content} ref={contentElement}>
          {ctx.outlet()}
        </div>
      </div>
    </section>
  );
}
