export default {
  "noteListItem": "NoteListItem_noteListItem_48c2d",
  "itemDivider": "NoteListItem_itemDivider_48c2d",
  "note": "NoteListItem_note_48c2d",
  "highlight": "NoteListItem_highlight_48c2d",
  "focused": "NoteListItem_focused_48c2d",
  "selected": "NoteListItem_selected_48c2d",
  "noteLayout": "NoteListItem_noteLayout_48c2d",
  "noteText": "NoteListItem_noteText_48c2d",
  "noteControls": "NoteListItem_noteControls_48c2d",
  "noteTitle": "NoteListItem_noteTitle_48c2d",
  "untitled": "NoteListItem_untitled_48c2d",
  "noteTagList": "NoteListItem_noteTagList_48c2d",
  "noteTag": "NoteListItem_noteTag_48c2d",
  "metaList": "NoteListItem_metaList_48c2d",
  "metaIcon": "NoteListItem_metaIcon_48c2d",
  "charmsBar": "NoteListItem_charmsBar_48c2d"
};import "ni:sha-256;D-ls_VddzNDmHIxJ2sweAsNqi8rYbgbA_FTAxjlbTpU";