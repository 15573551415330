import {
  signal,
  LanguageStore,
  cond,
  repeat,
  type Markup,
  type ViewContext,
  type Signal,
  type SettableSignal,
  ref,
} from "@manyducks.co/dolla";
import chroma from "chroma-js";
import { produce } from "immer";
import { promptForFiles } from "@helpers/promptForFiles";
import { ToolBarGroup } from "@views/ToolBar";
import EyedropperIcon from "@icons/Eyedropper";
import GridIcon from "@icons/Grid";
import TrashIcon from "@icons/Trash";
import UploadIcon from "@icons/Upload";
import { ColorInput } from "@views/ColorInput";
import { MoreMenu } from "@views/MoreMenu";
import styles from "./PixelEditor.module.css";

export interface PixelEditorControls {
  /**
   * Returns the editor's contents as a data URL.
   */
  getImageData(): string;

  /**
   * Sets the editor's contents from a data URL.
   */
  setImageData(data: string): void;

  /**
   * Sets the editor's contents from a File object representing an image file.
   */
  setImageFile(file: File): void;

  /**
   * Clears the canvas, resetting every pixel to transparent.
   */
  clear(): void;
}

interface PixelEditorProps {
  size?: number;
  getInitialData?: () => string | undefined | null; // Data URL to load into the editor.
  $$controlsRef?: SettableSignal<PixelEditorControls | undefined>; // Control the pixel editor from outside.
}

export function PixelEditor(props: PixelEditorProps, ctx: ViewContext) {
  const { translate } = ctx.getStore(LanguageStore);

  const { getInitialData, $$controlsRef } = props;
  const size = props.size ?? 16;

  const gridLines: Markup[] = [];

  for (let i = 0; i <= size; i++) {
    gridLines.push(<div class={styles.gridLine} />);
  }

  const [$color, setColor] = signal("#ccc");
  const [$colorHistory, setColorHistory] = signal<string[]>([]);
  const [$showGrid, setShowGrid] = signal(false);
  const [$dropperIsOpen, setDropperIsOpen] = signal(false);
  const [$dragging, setDragging] = signal(false);

  const $$moreMenuIsOpen = signal.settable(false);

  const canvasElement = ref<HTMLCanvasElement>();
  const previewCanvasElement = ref<HTMLCanvasElement>();

  function onDragStart(e: any) {
    setDragging(true);

    ctx.log({
      canvasRef: canvasElement.node,
      previewCanvasRef: previewCanvasElement.node,
    });

    window.addEventListener("mouseup", onDragEnd);
    window.addEventListener("touchend", onDragEnd);
  }

  function onDragEnd(e: any) {
    setDragging(false);

    window.removeEventListener("mouseup", onDragEnd);
    window.removeEventListener("touchend", onDragEnd);
  }

  function addColorToHistory(color: string) {
    if (!$colorHistory.get().includes(color)) {
      setColorHistory(
        produce((history) => {
          history.unshift(color);
          while (history.length > 5) {
            history.pop();
          }
        }),
      );
    }
  }

  const controls: PixelEditorControls = {
    getImageData() {
      return canvasElement.node!.toDataURL();
    },

    setImageData(data: string) {
      const canvasCtx = canvasElement.node!.getContext("2d")!;

      const image = new Image();
      image.src = data;

      image.addEventListener("load", () => {
        canvasCtx.drawImage(image, 0, 0, size, size);
        ctx.log("loaded image from data URL");
      });
    },

    setImageFile(file: File) {
      const canvasCtx = canvasElement.node!.getContext("2d")!;
      const reader = new FileReader();
      const image = new Image();

      reader.addEventListener(
        "load",
        () => {
          image.src = String(reader.result);
        },
        false,
      );

      image.addEventListener("load", () => {
        canvasCtx.drawImage(image, 0, 0, size, size);
        ctx.log("loaded image from file");
      });

      reader.readAsDataURL(file);
    },

    clear() {
      const canvasCtx = canvasElement.node!.getContext("2d")!;
      canvasCtx.clearRect(0, 0, size, size);
    },
  };

  if ($$controlsRef) {
    $$controlsRef.set(controls);
  }

  ctx.onConnected(() => {
    const initialData = getInitialData && getInitialData();

    if (initialData) {
      controls.setImageData(initialData);
    }
  });

  return (
    <div class={styles.container}>
      <div class={styles.canvasContainer}>
        <canvas ref={canvasElement} class={styles.canvas} width={size} height={size} />
        <canvas
          ref={previewCanvasElement}
          class={[styles.canvas, styles.previewCanvas, { [styles.dropperHover]: $dropperIsOpen }]}
          width={size}
          height={size}
          onmousedown={(e) => {
            onDragStart(e);

            const canvas = canvasElement.node!;
            const canvasCtx = canvas.getContext("2d")!;

            const color = $color.get();

            const x = Math.floor((e.offsetX / canvas.clientWidth) * size);
            const y = Math.floor((e.offsetY / canvas.clientHeight) * size);

            if ($dropperIsOpen.get()) {
              // Pick color
              const color = canvasCtx.getImageData(x, y, 1, 1);
              const [r, g, b, a] = color.data;
              const hex = chroma([r, g, b, a], "rgb").hex();
              setColor(hex);
              setDropperIsOpen(false);
            } else {
              // Draw in current color
              canvasCtx.fillStyle = color;
              canvasCtx.fillRect(x, y, 1, 1);

              addColorToHistory(color);
            }
          }}
          ontouchstart={(e) => {
            e.preventDefault();

            onDragStart(e);

            const canvas = canvasElement.node!;
            const canvasCtx = canvas.getContext("2d")!;
            const canvasRect = canvas.getBoundingClientRect();
            const color = $color.get();

            const x = Math.floor(((e.touches[0].clientX - canvasRect.left) / canvas.clientWidth) * size);
            const y = Math.floor(((e.touches[0].clientY - canvasRect.top) / canvas.clientHeight) * size);

            if ($dropperIsOpen.get()) {
              // Pick color
              const color = canvasCtx.getImageData(x, y, 1, 1);
              const [r, g, b, a] = color.data;
              const hex = chroma([r, g, b, a], "rgb").hex();
              setColor(hex);
              setDropperIsOpen(false);
            } else {
              // Draw in current color
              canvasCtx.fillStyle = color;
              canvasCtx.fillRect(x, y, 1, 1);

              addColorToHistory(color);
            }
          }}
          onmousemove={(e) => {
            const canvas = previewCanvasElement.node!;
            const canvasCtx = canvas.getContext("2d")!;
            const color = $color.get();

            const x = Math.floor((e.offsetX / canvas.clientWidth) * size);
            const y = Math.floor((e.offsetY / canvas.clientHeight) * size);

            if (!$dropperIsOpen.get()) {
              canvasCtx.clearRect(0, 0, size, size);
              canvasCtx.fillStyle = color;
              canvasCtx.fillRect(x, y, 1, 1);
            }

            if ($dragging.get()) {
              const canvasCtx = canvasElement.node!.getContext("2d")!;

              canvasCtx.fillStyle = color;
              canvasCtx.fillRect(x, y, 1, 1);
            }
          }}
          ontouchmove={(e) => {
            if ($dragging.get()) {
              e.preventDefault();

              const canvas = canvasElement.node!;
              const canvasCtx = canvas.getContext("2d")!;
              const canvasRect = canvas.getBoundingClientRect();
              const color = $color.get();

              const x = Math.floor(((e.touches[0].clientX - canvasRect.left) / canvas.clientWidth) * size);
              const y = Math.floor(((e.touches[0].clientY - canvasRect.top) / canvas.clientHeight) * size);

              canvasCtx.fillStyle = color;
              canvasCtx.fillRect(x, y, 1, 1);
            }
          }}
          onmouseleave={(e) => {
            const canvas = previewCanvasElement.node!;
            const canvasCtx = canvas.getContext("2d")!;

            canvasCtx.clearRect(0, 0, size, size);
          }}
        />
        {cond(
          $showGrid,
          <div class={styles.grid}>
            <div class={styles.gridX}>{gridLines}</div>
            <div class={styles.gridY}>{gridLines}</div>
          </div>,
        )}
      </div>

      <div class={styles.colors}>
        <div class={styles.colorInput}>
          <ColorInput $$value={signal.toSettable($color, setColor)} />
        </div>

        <ul class={styles.colorHistory}>
          {repeat(
            $colorHistory,
            (c) => c,
            ($color) => {
              return (
                <li class={styles.historicalColor}>
                  <button
                    class={styles.historicalColorButton}
                    style={{ backgroundColor: $color }}
                    onClick={(e: any) => {
                      e.preventDefault();
                      setColor($color.get());
                    }}
                  />
                </li>
              );
            },
          )}
        </ul>

        <ToolBarGroup>
          <button
            class={[styles.squareButton, styles.eyeDropperButton, { [styles.on]: $dropperIsOpen }]}
            onClick={(e: any) => {
              e.preventDefault();
              setDropperIsOpen((x) => !x);
            }}
          >
            <EyedropperIcon />
          </button>

          <MoreMenu
            $$open={$$moreMenuIsOpen}
            options={[
              {
                label: translate("views.pixelEditor.toggleGrid"),
                icon: <GridIcon />,
                callback: () => {
                  setShowGrid((x) => !x);
                },
              },
              {
                label: translate("views.pixelEditor.uploadImage"),
                icon: <UploadIcon />,
                callback: () => {
                  promptForFiles({ accept: "image/*" }).then((files) => {
                    if (files[0]) {
                      controls.setImageFile(files[0]);
                    }
                  });
                },
              },
              {
                label: translate("views.pixelEditor.clearCanvas"),
                icon: <TrashIcon />,
                variant: "destructive",
                callback: () => {
                  controls.clear();
                },
              },
            ]}
          />
        </ToolBarGroup>
      </div>
    </div>
  );
}
