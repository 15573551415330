import {
  cond,
  derive,
  HTTPStore,
  LanguageStore,
  repeat,
  signal,
  type ViewContext,
} from "@manyducks.co/dolla";
import { ThemeStore } from "@stores/ThemeStore";
import CloseIcon from "@icons/Close";
import { CardContent } from "@views/Card";
import { CollapsibleListSection } from "@views/CollapsibleListSection";
import { MoreMenu } from "@views/MoreMenu";
import styles from "./Projects.module.css";

function html(...args: any[]) {}

export function Projects(props: {}, ctx: ViewContext) {
  const http = ctx.getStore(HTTPStore);
  const theme = ctx.getStore(ThemeStore);
  const { translate } = ctx.getStore(LanguageStore);

  const [$projects, setProjects] = signal<any[]>([]);

  const $activeProjects = derive([$projects], (projects) =>
    projects
      .filter((p) => p.archivedAt == null)
      .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)),
  );
  const $archivedProjects = derive([$projects], (projects) =>
    projects
      .filter((p) => p.archivedAt != null)
      .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)),
  );

  ctx.onConnected(async () => {
    const res = await http.get<any[]>("/api/admin/projects");

    setProjects(res.body);
  });

  // TODO: Show owner, list of members, number of notes, number of tasks, and date last accessed.

  return (
    <CardContent>
      <div class={styles.container}>
        <CollapsibleListSection
          $title={translate("workspace.admin.projects.headings.active")}
          $itemCount={derive([$activeProjects], (x) => x.length)}
        >
          <ul class={styles.projectsList}>
            {repeat(
              $activeProjects,
              (p) => p.id,
              ($project) => {
                const $$menuIsOpen = signal.settable(false);
                const $color = derive([$project], (x) => x.color);
                const $owner = derive([$project], (p) => p.members.find((user) => user.id === p.ownerId));
                const $otherMembers = derive([$project], (p) =>
                  p.members.filter((user) => user.id !== p.ownerId),
                );

                return (
                  <li class={styles.listItem} style={theme.getTheme$($color)}>
                    <div class={styles.listItemIcon}>
                      <img
                        src="/icons/projects.12.trans.png"
                        alt=""
                        width="24"
                        height="24"
                        style={{ imageRendering: "pixelated" }}
                      />
                    </div>
                    <div class={styles.listItemContent}>
                      <div class={styles.listItemContentMain}>
                        <span class={styles.projectName}>{derive([$project], (p) => p.name)}</span>
                      </div>
                      <ul class={styles.listItemMeta}>
                        <li style={theme.getTheme$(derive([$owner], (x) => x.color))}>
                          <span class={styles.metaLabel}>Members:</span>
                          {/* <div class={styles.metaIcon}>
                          <UserIcon />
                        </div> */}
                          <span class={styles.metaOwnerNameLabel}>{derive([$owner], (x) => x.name)}</span>
                          {cond(
                            derive([$otherMembers], (x) => x.length > 0),
                            <span class={styles.metaOtherUsersLabel}>
                              {derive([$otherMembers], (x) => {
                                if (x.length == 1) {
                                  return translate("workspace.admin.projects.otherUsersLabel_singular", {
                                    value: x.length,
                                  });
                                } else {
                                  return translate("workspace.admin.projects.otherUsersLabel_plural", {
                                    value: x.length,
                                  });
                                }
                              })}
                            </span>,
                          )}
                        </li>
                        <li>
                          <span class={styles.metaLabel}>Notes:</span>
                          {/* <div class={styles.metaIcon}>
                          <NoteIcon />
                        </div> */}
                          <span>{derive([$project], (p) => p.notesCount)}</span>
                        </li>
                        <li>
                          <span class={styles.metaLabel}>Tasks:</span>
                          {/* <div class={styles.metaIcon}>
                          <TaskIcon />
                        </div> */}
                          <span>{derive([$project], (p) => p.tasksCount)}</span>
                        </li>
                      </ul>
                    </div>
                    <MoreMenu
                      $$open={$$menuIsOpen}
                      preferHorizontalAlignment="left"
                      preferVerticalAlignment="below"
                      size="small"
                      color={$color}
                      options={[
                        {
                          label: translate("workspace.admin.projects.menu.archive"),
                          icon: <CloseIcon />,
                          disabled: true,
                          callback: () => {
                            // $$linkMenuIsOpen.set(true);
                          },
                        },
                      ]}
                    />
                  </li>
                );
              },
            )}
          </ul>
        </CollapsibleListSection>

        {cond(
          derive([$archivedProjects], (x) => x.length > 0),
          <CollapsibleListSection
            $title={translate("workspace.admin.projects.headings.archived")}
            $itemCount={derive([$archivedProjects], (x) => x.length)}
          >
            <ul class={styles.projectsList}>
              {repeat(
                $archivedProjects,
                (p) => p.id,
                ($project) => {
                  const $$menuIsOpen = signal.settable(false);
                  const $color = derive([$project], (x) => x.color);

                  return (
                    <li class={styles.listItem} style={theme.getTheme$($color)}>
                      <div class={styles.listItemIcon}>
                        <img
                          src="/icons/projects.12.trans.png"
                          alt=""
                          width="24"
                          height="24"
                          style={{ imageRendering: "pixelated" }}
                        />
                      </div>
                      <div class={styles.listItemContent}>
                        <div class={styles.listItemContentMain}>
                          <span>{derive([$project], (p) => p.name)}</span>
                        </div>
                        <div class={styles.listItemContentSub}>Subcontent here.</div>
                      </div>
                      <MoreMenu
                        $$open={$$menuIsOpen}
                        preferHorizontalAlignment="left"
                        preferVerticalAlignment="below"
                        size="small"
                        color={$color}
                        options={[
                          {
                            label: translate("workspace.admin.projects.menu.unarchive"),
                            icon: <CloseIcon />,
                            callback: () => {
                              // $$linkMenuIsOpen.set(true);
                            },
                          },
                        ]}
                      />
                    </li>
                  );
                },
              )}
            </ul>
          </CollapsibleListSection>,
        )}
      </div>
    </CardContent>
  );
}
