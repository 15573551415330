import { adjustAccentColor } from "@helpers/makeTheme";
import {
  DialogStore,
  HTTPStore,
  LanguageStore,
  RouterStore,
  derive,
  signal,
  watch,
} from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { NotesStore } from "@stores/NotesStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { TasksStore } from "@stores/TasksStore";
import { ThemeStore } from "@stores/ThemeStore";
import { TaskEditDialog } from "@views/TaskListItem/TaskEditDialog/TaskEditDialog";
import chroma from "chroma-js";
import Quill from "quill";
import styles from "../TextEditor.module.css";

const Embed = Quill.import("blots/embed");

export class QuackLinkBlot extends Embed {
  static blotName = "quackLink";
  static tagName = "A";
  static className = styles.linkBlot;

  stopCallbacks = [];
  isAttached = false;

  static create(data) {
    const node = super.create(null);
    const type = data.linkingId.split("_")[0];

    const icon = document.createElement("img");
    icon.classList.add(styles.linkBlotIcon);

    if (type === "doc" || type === "note") {
      icon.src = "/icons/notes.8.trans.png";
    } else if (type === "task") {
      icon.src = "/icons/tasks.8.trans.png";
    }

    const title = document.createElement("span");
    title.classList.add(styles.linkBlotTitle);
    title.textContent = data.title;

    node.appendChild(icon);

    if (type === "task") {
      const stage = document.createElement("span");
      stage.className = styles.linkBlotTaskStage;
      stage.textContent = "...";
      node.appendChild(stage);
    }

    node.appendChild(title);

    node.setAttribute("href", buildPermalink(data.linkingId));
    node.dataset.linkingId = data.linkingId;
    node.dataset.title = data.title;

    return node;
  }

  static value(domNode) {
    return domNode.dataset;
  }

  // position(index: number, inclusive: boolean) {
  //   console.log({ index, inclusive });
  //   return super.position(index, inclusive);
  // }

  attach() {
    super.attach();
    this.isAttached = true;

    setTimeout(() => {
      this.scroll.emitter.emit("quack-link-attached", this);
    }, 0);
  }

  setContext(ctx, cardId) {
    if (!this.isAttached) return;

    this.ctx = ctx;

    const http = ctx.getStore(HTTPStore);
    const { translate, $currentLanguage } = ctx.getStore(LanguageStore);
    const theme = ctx.getStore(ThemeStore);

    const auth = ctx.getStore(AuthStore);
    const projects = ctx.getStore(ProjectsStore);
    const tasks = ctx.getStore(TasksStore);
    const notes = ctx.getStore(NotesStore);
    const router = ctx.getStore(RouterStore);
    const dialog = ctx.getStore(DialogStore);

    const node = this.domNode;

    const stageEl = node.querySelector("." + styles.linkBlotTaskStage);
    const titleEl = node.querySelector("." + styles.linkBlotTitle);

    const [$linkData, setLinkData] = signal();

    // Make HTTP call for permalink info and update nodes.
    http.get(`/api/permalink/${node.dataset.linkingId}`).then((res) => {
      if (res.status === 200) {
        const link = res.body.data;
        setLinkData(link);

        titleEl.textContent = link.title;

        // Add event handler once link has been loaded. Now we know what we're working with.
        node.onclick = async (e) => {
          e.preventDefault();
          e.stopPropagation();

          if (link.type === "note") {
            router.navigate(`/projects/${link.projectId}/notes/${link.id}`);
          } else if (link.type === "task") {
            await tasks.ensureTaskIsLoaded(link.id);
            const project = projects.$cache.get().find((p) => p.id === link.projectId);
            const task = tasks.$cache.get().get(link.id);
            dialog.open(TaskEditDialog, {
              projectId: project?.id,
              task: task,
              color: project?.color,
              onToListClicked: () => {
                router.navigate(`/projects/${link.projectId}/tasks?task=${link.id}`);
              },
            });
          }
        };
      } else {
        console.warn(res);
      }
    });

    const $themeVars = derive(
      [$linkData, projects.$cache, theme.$isDark, auth.$me],
      (link, projects, isDark, me) => {
        if (!link) return {};

        if (link.type === "thought") {
          if (!me) return {};
          const color = adjustAccentColor(me.color, isDark);
          return {
            "--blot-bg-color": chroma(color)
              .luminance(isDark ? 0.03 : 0.88)
              .hex(),
            "--color-accent-0": chroma(color).hex(),
          };
        }

        const project = projects.find((p) => p.id === link.projectId);
        if (project) {
          const color = adjustAccentColor(project.color, isDark);
          return {
            "--blot-bg-color": chroma(color)
              .luminance(isDark ? 0.03 : 0.88)
              .hex(),
            "--color-accent-0": chroma(color).hex(),
          };
        }
        return {};
      },
    );

    this.stopCallbacks.push(
      watch([$themeVars], (vars) => {
        for (const key of Object.keys(vars)) {
          node.style.setProperty(key, vars[key]);
        }
      }),

      watch([$linkData, tasks.$cache, notes.$cache, $currentLanguage], (link, tasks, notes) => {
        if (!link) return;
        if (link.type === "task") {
          const task = tasks.get(link.id);
          if (titleEl) {
            titleEl.textContent = task?.title ?? link.title;
          }
          if (stageEl) {
            if (task?.completedAt != null) {
              stageEl.style.display = "unset";
              stageEl.textContent = translate("tasks.card.stages.done").get();
            } else {
              stageEl.style.display = "none";
            }
          }
        } else if (link.type === "note") {
          const note = notes.get(link.id);
          if (titleEl) {
            titleEl.textContent = note?.title ?? link.title;
          }
        }
      }),
    );
  }

  detach() {
    for (const callback of this.stopCallbacks) {
      callback();
    }
    this.stopCallbacks = [];

    super.detach();
    this.isAttached = false;
    this.ctx = undefined;

    setTimeout(() => {
      this.scroll.emitter.emit("quack-link-detached", this);
    }, 0);
  }
}

function buildPermalink(linkingId, location = window.location) {
  let link = location.protocol + "//" + location.hostname;

  if (location.hostname === "localhost") {
    link += ":" + location.port;
  }

  link += "/p/" + linkingId;

  return link;
}
