import { type ViewContext } from "@manyducks.co/dolla";
import styles from "./Card.module.css";
import { ColorDivider } from "@views/ColorDivider";

export function CardToolbar(props: {}, ctx: ViewContext) {
  return (
    <div
      class={styles.cardToolbar}
      style={
        {
          // backgroundImage: "url(/textures/inspiration-geometry.png)",
        }
      }
    >
      {/* <ColorDivider reverse /> */}
      <div class={styles.cardToolbarContent}>{ctx.outlet()}</div>
    </div>
  );
}
