import { sortTasks } from "@helpers/sortTasks";
import {
  cond,
  derive,
  LanguageStore,
  MaybeSignal,
  repeat,
  RouterStore,
  SettableSignal,
  signal,
  Signal,
  signalify,
  type ViewContext,
} from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { TasksStore } from "@stores/TasksStore";
import { CardContent, CardToolbar } from "@views/Card";
import { CollapsibleListSection } from "@views/CollapsibleListSection";
import { TagFilter } from "@views/TagFilter";
import { TaskListItem } from "@views/TaskListItem";
import { type Task, type User } from "schemas";
import styles from "./Assigned.module.css";
import { ColorDivider } from "@views/ColorDivider";

interface AssignedProps {
  $projectId: Signal<number>;
  $userRole: Signal<"viewer" | "collaborator" | "admin">;
  $projectColor: Signal<string | undefined>;
  $$filterTags: SettableSignal<string[]>;
}

interface UserTasks {
  user: User;
  tasks: Task[];
}

export function Assigned(props: AssignedProps, ctx: ViewContext) {
  const auth = ctx.getStore(AuthStore);
  const tasks = ctx.getStore(TasksStore);
  const projects = ctx.getStore(ProjectsStore);
  const router = ctx.getStore(RouterStore);
  const { translate } = ctx.getStore(LanguageStore);

  const $project = derive(
    [projects.$cache, props.$projectId],
    (projects, id) => projects.find((p) => p.id === id)!,
  );

  const $tags = derive([props.$projectId, tasks.$cache], (projectId, tasks) => {
    const tags: { [tag: string]: number } = {};

    if (projectId != null) {
      for (const task of tasks.values()) {
        if (task.projectId == projectId && task.completedAt == null && task.assignedUserId != null) {
          for (const tag of task.tags) {
            tags[tag] = (tags[tag] ?? 0) + 1;
          }
        }
      }
    }

    return tags;
  });

  const $$collapsedSections = signal.settable<string[]>([]);

  const $canInteract = derive([props.$userRole], (role) => role != "viewer");

  const $tasksByUser = derive(
    [$project, auth.$me, auth.$users, tasks.$cache, props.$$filterTags],
    (project, me, users, tasks, tags) => {
      const groups: UserTasks[] = [];
      const usersInProject = users.filter((u) =>
        project?.users.find((pu) => pu.id === u.id && pu.role !== "viewer"),
      );

      for (const user of usersInProject) {
        const group: UserTasks = { user, tasks: [] };
        for (const [id, task] of tasks) {
          if (
            task.projectId === project!.id &&
            task.assignedUserId === user.id &&
            tags.every((t) => task.tags.includes(t))
          ) {
            group.tasks.push(task);
          }
        }
        groups.push(group);
      }

      groups.sort((a, b) => {
        if (a.user.id === me!.id) {
          return -1;
        } else if (b.user.id === me!.id) {
          return +1;
        } else {
          return 0;
        }
      });

      return groups;
    },
  );

  /**
   * Creates a writable that toggles a section open/closed.
   */
  function proxySectionIsOpen$$(section: MaybeSignal<string>) {
    const $section = signalify(section);

    const $isOpen = derive([$$collapsedSections, $section], (sections, section) => {
      return !sections.includes(section);
    });

    const setIsOpen = signal.createSetter($isOpen, (isOpen, wasOpen) => {
      const section = $section.get();

      if (isOpen && !wasOpen) {
        $$collapsedSections.set((sections) => sections.filter((x) => x !== section));
      } else if (!isOpen && wasOpen) {
        $$collapsedSections.set((sections) => [...sections, section]);
      }
    });

    return signal.toSettable($isOpen, setIsOpen);
  }

  return (
    <div class={styles.container}>
      <CardContent>
        <div class={styles.content}>
          {repeat(
            $tasksByUser,
            (g) => g.user.id,
            ($group) => {
              const $user = derive([$group], (g) => g.user);
              const $tasks = derive([$group], (g) => g.tasks);
              const $$open = proxySectionIsOpen$$(derive([$user], (u) => `user:${u.id}`));

              return (
                <CollapsibleListSection
                  $$open={$$open}
                  $title={derive([$user], (u) => u.name)}
                  $headerColor={derive([$user], (u) => u.color)}
                  $itemCount={derive([$tasks], (tasks) => tasks.filter((t) => t.completedAt == null).length)}
                >
                  {cond(
                    derive([$tasks], (tasks) => tasks.filter((t) => t.completedAt == null).length === 0),
                    <p class={styles.emptyListMessage}>
                      {translate("workspace.project.tasks.assigned.emptyListMessage")}
                    </p>,
                  )}

                  <ul class={styles.taskList}>
                    {repeat(
                      derive([$tasks], (tasks) => tasks.filter((t) => t.completedAt == null).sort(sortTasks)),
                      (task) => task.id,
                      ($task) => {
                        return (
                          <TaskListItem
                            $task={$task}
                            interactive={$canInteract}
                            // onClick={(e) => {
                            //   e.preventDefault();
                            //   const task = $task.get();
                            //   router.navigate(`/projects/${task.projectId}/tasks/${task.id}`, {
                            //     preserveQuery: true,
                            //   });
                            // }}
                            scrollToTask={() => {}}
                          />
                        );
                      },
                    )}
                  </ul>
                </CollapsibleListSection>
              );
            },
          )}
        </div>
      </CardContent>

      <CardToolbar>
        <div class={styles.tagFilter}>
          <TagFilter
            $$tags={props.$$filterTags}
            $color={derive([$project], (p) => p!.color)}
            $suggestions={$tags}
          />
        </div>
      </CardToolbar>
    </div>
  );
}
