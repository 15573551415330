import {
  cond,
  type MaybeSignal,
  ref,
  type SettableSignal,
  type Signal,
  type ViewContext,
} from "@manyducks.co/dolla";
import { Renderable } from "@manyducks.co/dolla/lib/types";
import MoreIcon from "@icons/More";
import { HoverMenu } from "@views/HoverMenu";
import { HoverMenuHorizontalAlignment, HoverMenuVerticalAlignment } from "@views/HoverMenu/HoverMenu";
import { MenuList, MenuListItem } from "@views/MenuList";
import styles from "./MoreMenu.module.css";

interface MoreMenuOption {
  label: MaybeSignal<string>;
  icon?: MaybeSignal<Renderable>;
  callback: () => void;
  variant?: "normal" | "destructive";
  hidden?: MaybeSignal<boolean>;
  disabled?: MaybeSignal<boolean>;
}

interface MoreMenuAttrs {
  $$open: SettableSignal<boolean>;
  options: MoreMenuOption[];
  icon?: MaybeSignal<Renderable>;
  title?: MaybeSignal<string>;
  size?: "normal" | "small";
  variant?: "normal" | "card";
  color?: string | Signal<string> | Signal<string | undefined>;
  preferVerticalAlignment?: MaybeSignal<HoverMenuVerticalAlignment>;
  preferHorizontalAlignment?: MaybeSignal<HoverMenuHorizontalAlignment>;
}

/**
 * Displays a "..." button that opens a menu with more options.
 */
export function MoreMenu({ $$open, options, size, color, ...props }: MoreMenuAttrs, ctx: ViewContext) {
  function onKeyDown(e: KeyboardEvent) {
    if (e.key === "Escape") {
      $$open.set(false);
    }
  }

  const anchorRef = ref<HTMLElement>();

  ctx.watch([$$open], (open) => {
    if (open) {
      window.addEventListener("keydown", onKeyDown);
    } else {
      window.removeEventListener("keydown", onKeyDown);
    }
  });

  return (
    <div
      class={[
        styles.container,
        {
          [styles.small]: size === "small",
          [styles.card]: props.variant === "card",
        },
      ]}
    >
      <button
        type="button"
        class={{
          [styles.button]: true,
          [styles.open]: $$open,
        }}
        title={props.title}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          const open = $$open.get();
          if (!open) {
            const button = e.currentTarget as HTMLButtonElement;

            anchorRef.node = button;
            $$open.set(true);
          } else {
            $$open.set(false);
          }
        }}
      >
        {props.icon ?? <MoreIcon />}
      </button>

      <HoverMenu
        $$open={$$open}
        anchorRef={anchorRef}
        color={color}
        preferHorizontalAlignment={props.preferHorizontalAlignment}
        preferVerticalAlignment={props.preferVerticalAlignment}
        distanceFromAnchor={size === "small" ? 8 : 12}
      >
        <MenuList>
          {options.map((o) => {
            return cond(
              o.hidden,
              null,
              <MenuListItem
                icon={o.icon}
                label={o.label}
                variant={o.variant}
                disabled={o.disabled}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  o.callback();
                  $$open.set(false);
                }}
              />,
            );
          })}
        </MenuList>
      </HoverMenu>
    </div>
  );
}
