import { signal, HTTPStore, type StoreContext, derive } from "@manyducks.co/dolla";

const PUSH_PUBLIC_KEY =
  "BDzEsY6PkGh037b0cck71ZPXxtujkssIHVJMLZi9FUQXtFG3s6g4RIfibD6SpKaM-F3H7vNdRSGGosjAUWQJ91k";

export function PushStore(ctx: StoreContext) {
  const http = ctx.getStore(HTTPStore);

  const [$subscription, setSubscription] = signal<PushSubscription>();

  const [$chatBannerDismissed, setChatBannerDismissed] = signal(
    localStorage.getItem("quack-notification-banner-dismissed") === "true",
  );

  const dismissChatBanner = () => {
    localStorage.setItem("quack-notification-banner-dismissed", "true");
    setChatBannerDismissed(true);
  };

  const resetChatBanner = () => {
    localStorage.removeItem("quack-notification-banner-dismissed");
    setChatBannerDismissed(false);
  };

  ctx.onConnected(async () => {
    if ("serviceWorker" in navigator) {
      // We need the service worker registration to check for a subscription
      navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
        if (serviceWorkerRegistration.pushManager) {
          // Do we already have a push message subscription?
          serviceWorkerRegistration.pushManager.getSubscription().then((subscription) => {
            if (subscription) {
              setSubscription(subscription);

              // Keep server in sync
              sendSubscriptionToServer(subscription);
            }
          });
        }
      });
    }
  });

  const sendSubscriptionToServer = async (subscription: PushSubscription) => {
    return http
      .post("/api/push/web/registrations", {
        body: subscription.toJSON(),
      })
      .then(() => {
        ctx.log("sent", subscription.toJSON());
      });
  };

  const disablePushNotifications = async () => {
    const sub = $subscription.get();

    if (sub) {
      await http.delete(`/api/push/web/registrations?endpoint=${sub.toJSON().endpoint}`);
      await sub.unsubscribe();

      setSubscription(undefined);
      dismissChatBanner();
    }
  };

  const enablePushNotifications = async () => {
    return new Promise((resolve, reject) => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
          if (serviceWorkerRegistration.pushManager) {
            serviceWorkerRegistration.pushManager
              .subscribe({
                userVisibleOnly: true,
                applicationServerKey: PUSH_PUBLIC_KEY,
              })
              .then((subscription) => {
                setSubscription(subscription);
                resetChatBanner();

                // Keep your server in sync with the latest subscriptionId
                return sendSubscriptionToServer(subscription);
              })
              .then(resolve);
          } else {
            reject(new Error(`Push notifications are not supported by your browser.`));
          }
        });
      } else {
        reject(new Error("Service worker is not present"));
      }
    });
  };

  return {
    $isPushEnabled: derive([$subscription], (s) => s != null),

    $displayChatBanner: derive(
      [$subscription, $chatBannerDismissed],
      (sub, dismissed) => "Notification" in window && sub == null && !dismissed,
    ),
    dismissChatBanner,

    enablePushNotifications,
    disablePushNotifications,
  };
}
