import {
  cond,
  derive,
  LanguageStore,
  ref,
  repeat,
  signal,
  type Signal,
  type ViewContext,
} from "@manyducks.co/dolla";
import { TasksStore } from "@stores/TasksStore";
import Calendar from "@icons/Calendar";
import Check from "@icons/Check";
import { addDays, isSameDay, isSunday, subDays } from "date-fns";
import { Project, Task } from "schemas";
import { parseDueDate } from "../parseDueDate";
import styles from "./CalendarView.module.css";

interface CalendarViewProps {
  $project: Signal<Project>;
  $year: Signal<number>;
  $month: Signal<number>;
  $selectedDate: Signal<Date | undefined>;
  onDaySelected: (date: Date) => void;
}

export function CalendarView(props: CalendarViewProps, ctx: ViewContext) {
  const tasks = ctx.getStore(TasksStore);
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);

  const $days = derive(
    [props.$year, props.$month, props.$selectedDate, props.$project, tasks.$cache],
    (year, month, selectedDate, project, tasks) => {
      return createDays(
        year,
        month,
        selectedDate,
        [...tasks.values()].filter((t) => t.projectId === project.id),
      );
    },
  );

  const containerElement = ref<HTMLUListElement>();

  // Calculate how much vertical space in pixels is available for day items.
  // If not enough is available for the number of items, we display a "+X more" placeholder.
  const [$dayItemsHeight, setDayItemsHeight] = signal(0);

  function onResize() {
    const headerHeight = 24;
    const dayHeight = containerElement.node!.clientHeight / 6 - 2;
    setDayItemsHeight(dayHeight - headerHeight);
  }

  ctx.onConnected(() => {
    window.addEventListener("resize", onResize);
    onResize();
  });

  ctx.onDisconnected(() => {
    window.removeEventListener("resize", onResize);
  });

  return (
    <section class={styles.calendar}>
      <header class={styles.header}>
        <ul>
          {repeat(
            derive([$days], (d) => d.slice(0, 7)),
            (d) => d.date.getDay(),
            ($day) => (
              <li>
                {derive([$day, $currentLanguage], (day, lang) => {
                  const formatter = new Intl.DateTimeFormat([lang!, "en-US"], {
                    weekday: "short",
                  });
                  return formatter.format(day.date);
                })}
              </li>
            ),
          )}
        </ul>
      </header>
      <ul class={styles.days} ref={containerElement}>
        {repeat(
          $days,
          (d) => d.date.toISOString(),
          ($day, $index, ctx) => {
            const $activeItems = derive([$day], (x) => x.items.filter((t) => t.completedAt == null));
            const $completedItems = derive([$day], (x) => x.items.filter((t) => t.completedAt != null));

            const $activeItemCount = derive([$activeItems], (x) => x.length);
            const $completedItemCount = derive([$completedItems], (x) => x.length);

            const $remainingHeight = derive(
              [$dayItemsHeight, $activeItemCount, $completedItemCount],
              (availableHeight, activeItemCount, completedItemCount) => {
                let remainingHeight = availableHeight;
                if (activeItemCount > 0) {
                  remainingHeight -= 16; // Due header
                }
                if (completedItemCount > 0) {
                  remainingHeight -= 16; // Done header
                }
                return remainingHeight;
              },
            );

            const $visibleActiveItems = derive(
              [$remainingHeight, $activeItems],
              (remainingHeight, activeItems) => {
                let availableHeight = remainingHeight;
                let availableItems = [...activeItems];
                const items: Task[] = [];

                while (availableHeight > 18 && availableItems.length > 0) {
                  if (availableItems.length === 1 || availableHeight > 34) {
                    items.push(availableItems.shift()!);
                    availableHeight -= 18;
                  } else {
                    break;
                  }
                }

                return items;
              },
            );
            const $hiddenActiveItemCount = derive(
              [$activeItems, $visibleActiveItems],
              (items, visibleItems) => items.length - visibleItems.length,
            );
            const $visibleCompletedItems = derive(
              [$remainingHeight, $activeItems, $completedItems],
              (remainingHeight, activeItems, completedItems) => {
                let availableHeight = remainingHeight - 18 * activeItems.length;
                let availableItems = [...completedItems];
                const items: Task[] = [];

                while (availableHeight > 18 && availableItems.length > 0) {
                  if (availableItems.length === 1 || availableHeight > 34) {
                    items.push(availableItems.shift()!);
                    availableHeight -= 18;
                  } else {
                    break;
                  }
                }

                return items;
              },
            );
            const $hiddenCompletedItemCount = derive(
              [$completedItems, $visibleCompletedItems],
              (items, visibleItems) => items.length - visibleItems.length,
            );

            return (
              <li
                class={[
                  styles.day,
                  {
                    [styles.notInMonth]: derive([$day], (d) => !d.inCurrentMonth),
                    [styles.isToday]: derive([$day], (d) => d.isToday),
                    [styles.isSelected]: derive([$day], (d) => d.isSelected),
                  },
                ]}
              >
                <button
                  class={styles.dayButton}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    props.onDaySelected($day.get().date);
                  }}
                >
                  <div class={styles.dayHeader}>
                    {cond(
                      derive([$day], (d) => d.isStartOfMonth),
                      <span class={styles.dayMonthLabel}>
                        {derive([$day, $currentLanguage], (day, lang) => {
                          const formatter = new Intl.DateTimeFormat([lang!, "en-US"], {
                            month: "short",
                          });
                          return formatter.format(day.date);
                        })}
                      </span>,
                    )}
                    <span class={styles.dayDateLabel}>
                      {derive([$day, $currentLanguage], (day, lang) => {
                        const formatter = new Intl.DateTimeFormat([lang!, "en-US"], {
                          day: "numeric",
                        });
                        return formatter.format(day.date);
                      })}
                    </span>
                  </div>

                  {cond(
                    derive([$activeItemCount], (x) => x > 0),
                    <section class={styles.daySection}>
                      <header class={{ [styles.empty]: derive([$activeItemCount], (x) => x === 0) }}>
                        <Calendar />
                        <span>
                          {translate("workspace.project.tasks.calendar.monthView.dueHeader_value", {
                            value: $activeItemCount,
                          })}
                        </span>
                      </header>
                      <ul class={styles.dayItems}>
                        {repeat(
                          $visibleActiveItems,
                          (item) => item.id,
                          ($item) => {
                            return (
                              <li class={styles.dayItem}>
                                <span>{derive([$item], (x) => x.title)}</span>
                              </li>
                            );
                          },
                        )}
                        {cond(
                          derive([$hiddenActiveItemCount, $activeItems], (hiddenCount, items) => {
                            return items.length > 0 && hiddenCount > 0 && hiddenCount < items.length;
                          }),
                          <li class={styles.hiddenMessage}>
                            {translate("workspace.project.tasks.calendar.monthView.overflowLabel_value", {
                              value: $hiddenActiveItemCount,
                            })}
                          </li>,
                        )}
                      </ul>
                    </section>,
                  )}

                  {cond(
                    derive([$completedItemCount], (x) => x > 0),
                    <section class={styles.daySection}>
                      <header class={{ [styles.empty]: derive([$completedItemCount], (x) => x === 0) }}>
                        <Check />
                        <span>
                          {translate("workspace.project.tasks.calendar.monthView.doneHeader_value", {
                            value: $completedItemCount,
                          })}
                        </span>
                      </header>
                      <ul class={styles.dayItems}>
                        {repeat(
                          $visibleCompletedItems,
                          (item) => item.id,
                          ($item) => {
                            return (
                              <li class={[styles.dayItem, styles.completed]}>
                                <span>{derive([$item], (x) => x.title)}</span>
                              </li>
                            );
                          },
                        )}
                      </ul>
                      {cond(
                        derive([$hiddenCompletedItemCount, $completedItems], (hiddenCount, items) => {
                          return items.length > 0 && hiddenCount > 0 && hiddenCount < items.length;
                        }),
                        <li class={styles.hiddenMessage}>
                          {translate("workspace.project.tasks.calendar.monthView.overflowLabel_value", {
                            value: $hiddenCompletedItemCount,
                          })}
                        </li>,
                      )}
                    </section>,
                  )}
                </button>
              </li>
            );
          },
        )}
      </ul>
    </section>
  );
}

interface DayData {
  inCurrentMonth: boolean;
  isStartOfMonth: boolean;
  isSelected: boolean;
  isToday: boolean;
  date: Date;
  items: Task[];
}

function createDays(year: number, month: number, selectedDate: Date | undefined, items: Task[]): DayData[] {
  const days: DayData[] = [];

  // Find first Sunday to start off the grid.
  let firstDay = new Date(year, month - 1, 1);
  while (!isSunday(firstDay)) {
    firstDay = subDays(firstDay, 1);
  }

  const now = new Date();

  let cursor = firstDay;
  while (days.length < 42) {
    const inCurrentMonth = cursor.getFullYear() === year && cursor.getMonth() + 1 === month;
    const data: DayData = {
      inCurrentMonth,
      isStartOfMonth: cursor.getDate() === 1,
      isSelected: selectedDate != null && isSameDay(selectedDate, cursor),
      isToday: isSameDay(now, cursor),
      date: cursor,
      items: items.filter(
        (x) =>
          (x.dueDate != null && isSameDay(parseDueDate(x.dueDate), cursor)) ||
          (x.completedAt != null && isSameDay(x.completedAt, cursor)),
      ),
    };
    days.push(data);
    cursor = addDays(cursor, 1);
  }

  return days;
}
