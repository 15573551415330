export default {
  "chat": "Chat_chat_6dae1",
  "layout": "Chat_layout_6dae1",
  "editor": "Chat_editor_6dae1",
  "pushDisabledBanner": "Chat_pushDisabledBanner_6dae1",
  "pushDisabledBannerControls": "Chat_pushDisabledBannerControls_6dae1",
  "endOfMessages": "Chat_endOfMessages_6dae1",
  "chatListSpacer": "Chat_chatListSpacer_6dae1",
  "dividerLine": "Chat_dividerLine_6dae1",
  "dividerLabel": "Chat_dividerLabel_6dae1",
  "chatHeader": "Chat_chatHeader_6dae1",
  "chatTitle": "Chat_chatTitle_6dae1",
  "project": "Chat_project_6dae1",
  "listContent": "Chat_listContent_6dae1",
  "chatMessageList": "Chat_chatMessageList_6dae1",
  "chatList": "Chat_chatList_6dae1",
  "chatListItem": "Chat_chatListItem_6dae1",
  "chatListItemIcon": "Chat_chatListItemIcon_6dae1",
  "active": "Chat_active_6dae1",
  "chatListItemLabel": "Chat_chatListItemLabel_6dae1"
};import "ni:sha-256;XDHnNFx4P6f8Ra5Ij8Kdy8cO5zeSVw9ZUKtUL4HoVME";