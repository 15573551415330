export default {
  "container": "MoreMenu_container_a2f4d",
  "small": "MoreMenu_small_a2f4d",
  "button": "MoreMenu_button_a2f4d",
  "card": "MoreMenu_card_a2f4d",
  "open": "MoreMenu_open_a2f4d",
  "icon": "MoreMenu_icon_a2f4d",
  "menu": "MoreMenu_menu_a2f4d",
  "listButton": "MoreMenu_listButton_a2f4d",
  "destructive": "MoreMenu_destructive_a2f4d"
};import "ni:sha-256;zV3mpJj6qPMAh_-_hnvITSgR45gQYiPQIlMRnJACAoc";