import { cond, derive, signalify, type Signal, type ViewContext } from "@manyducks.co/dolla";
import styles from "./UserAvatar.module.css";

interface UserAvatarProps {
  src?: string | Signal<string> | Signal<string | undefined>;
  scale?: number;
}

export function UserAvatar(props: UserAvatarProps, ctx: ViewContext) {
  const $src = signalify(props.src);
  const $scale = signalify(props.scale ?? 1);
  const $size = derive([$scale], (scale) => 32 * scale);

  return (
    <div class={styles.container} style={{ width: $size, height: $size }}>
      {cond(
        $src,
        <img
          class={styles.avatar}
          width={$size}
          height={$size}
          src={derive([$src], (src) => src ?? "")}
          alt={""}
        />,
      )}
    </div>
  );
}
