export default {
  "container": "Card_container_da842",
  "outOfViewport": "Card_outOfViewport_da842",
  "lifted": "Card_lifted_da842",
  "card": "Card_card_da842",
  "scope": "Card_scope_da842",
  "scopeIcon": "Card_scopeIcon_da842",
  "scopeLabel": "Card_scopeLabel_da842",
  "scopeRole": "Card_scopeRole_da842",
  "roleIcon": "Card_roleIcon_da842",
  "roleLabel": "Card_roleLabel_da842",
  "header": "Card_header_da842",
  "contacted": "Card_contacted_da842",
  "headerContent": "Card_headerContent_da842",
  "headerGroup": "Card_headerGroup_da842",
  "titleGroup": "Card_titleGroup_da842",
  "icon": "Card_icon_da842",
  "title": "Card_title_da842",
  "mainTitle": "Card_mainTitle_da842",
  "subtitle": "Card_subtitle_da842",
  "showSubtitle": "Card_showSubtitle_da842",
  "outlet": "Card_outlet_da842",
  "menuPixelIcon": "Card_menuPixelIcon_da842",
  "cardContent": "Card_cardContent_da842",
  "scrollable": "Card_scrollable_da842",
  "cardToolbar": "Card_cardToolbar_da842",
  "cardToolbarContent": "Card_cardToolbarContent_da842",
  "cardWidget": "Card_cardWidget_da842"
};import "ni:sha-256;iXozpwCUBjWyKkLqmD-idujgl3X8YMKAm1NKOLl4vcc";