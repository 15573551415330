import { signal, derive, HTTPStore, RouterStore, type StoreContext } from "@manyducks.co/dolla";
import { produce } from "immer";
import { DeltaOperation } from "quill";
import { type ChatMessage } from "schemas";
import { IOStore } from "./IOStore";
import { ProjectsStore } from "./ProjectsStore";

function loadProjectId() {
  const stored = localStorage.getItem("chat.projectId");
  return stored ? Number(stored) : undefined;
}

export function ChatStore(ctx: StoreContext) {
  const http = ctx.getStore(HTTPStore);
  const io = ctx.getStore(IOStore);
  const projects = ctx.getStore(ProjectsStore);

  const [$messages, setMessages] = signal<ChatMessage[]>([]);
  const [$projectId, setProjectId] = signal(loadProjectId());

  ctx.watch([$projectId], (projectId) => {
    if (projectId == null) {
      localStorage.removeItem("chat.projectId");
    } else {
      localStorage.setItem("chat.projectId", String(projectId));
    }
  });

  // Deselect project if chat is not enabled.
  ctx.watch([projects.$cache, $projectId], (projects, id) => {
    const project = projects.find((p) => p.id === id);
    if (project == null) return;
    if (project.archivedAt != null || project.users.length < 2) {
      setProjectId(undefined);
    }
  });

  async function fetchMessages() {
    const res = await http.get<ChatMessage[]>(`/api/chat`);
    setMessages(res.body);
  }

  /**
   * @param delta - Quill delta of message content
   * @param attachments - UUIDs of uploaded files to attach
   */
  async function sendMessage(projectId: number, delta: DeltaOperation[], attachments: string[]) {
    const socket = io.socket();
    socket.emit("chat:message", { projectId, delta, attachments });
  }

  return {
    $messages,
    $projectId,
    setProjectId,

    fetchMessages,
    sendMessage,

    chatMessageReceived: (message: ChatMessage) => {
      ctx.log("received chat message", message);

      setMessages(
        produce((messages) => {
          const found = messages.find((m) => m.id === message.id);
          if (found) {
            Object.assign(found, message);
          } else {
            messages.push(message);
          }
        }),
      );
    },
  };
}
