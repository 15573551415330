export default {
  "layout": "PlaceLayout_layout_25bdb",
  "header": "PlaceLayout_header_25bdb",
  "titleArea": "PlaceLayout_titleArea_25bdb",
  "homeButton": "PlaceLayout_homeButton_25bdb",
  "homeButtonBackdrop": "PlaceLayout_homeButtonBackdrop_25bdb",
  "title": "PlaceLayout_title_25bdb",
  "backButton": "PlaceLayout_backButton_25bdb",
  "withBackButton": "PlaceLayout_withBackButton_25bdb",
  "titleContent": "PlaceLayout_titleContent_25bdb",
  "titleText": "PlaceLayout_titleText_25bdb",
  "mainText": "PlaceLayout_mainText_25bdb",
  "subText": "PlaceLayout_subText_25bdb",
  "deemphasized": "PlaceLayout_deemphasized_25bdb",
  "titleControls": "PlaceLayout_titleControls_25bdb",
  "headerButton": "PlaceLayout_headerButton_25bdb",
  "main": "PlaceLayout_main_25bdb",
  "userMenuIcon": "PlaceLayout_userMenuIcon_25bdb",
  "tabs": "PlaceLayout_tabs_25bdb",
  "active": "PlaceLayout_active_25bdb",
  "tabIcon": "PlaceLayout_tabIcon_25bdb",
  "tabBackdrop": "PlaceLayout_tabBackdrop_25bdb",
  "hidden": "PlaceLayout_hidden_25bdb",
  "content": "PlaceLayout_content_25bdb"
};import "ni:sha-256;c74dik2iRuFv9WAUCgVyhUKLuV6IbWuKF_naaZwRvik";