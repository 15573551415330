export default {
  "calendar": "CalendarView_calendar_cfcb9",
  "header": "CalendarView_header_cfcb9",
  "days": "CalendarView_days_cfcb9",
  "day": "CalendarView_day_cfcb9",
  "notInMonth": "CalendarView_notInMonth_cfcb9",
  "isToday": "CalendarView_isToday_cfcb9",
  "isSelected": "CalendarView_isSelected_cfcb9",
  "dayButton": "CalendarView_dayButton_cfcb9",
  "dayHeader": "CalendarView_dayHeader_cfcb9",
  "dayMonthLabel": "CalendarView_dayMonthLabel_cfcb9",
  "dayItems": "CalendarView_dayItems_cfcb9",
  "dayItem": "CalendarView_dayItem_cfcb9",
  "completed": "CalendarView_completed_cfcb9",
  "hiddenMessage": "CalendarView_hiddenMessage_cfcb9",
  "daySection": "CalendarView_daySection_cfcb9",
  "empty": "CalendarView_empty_cfcb9"
};import "ni:sha-256;k9CgKPlTNY5sJecRuuR9xgPY1JxB6J0-Tc0AkXY7KBA";