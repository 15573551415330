import { cond, derive, RouterStore, type Signal, type ViewContext } from "@manyducks.co/dolla";
import { ProjectsStore } from "@stores/ProjectsStore";
import { Project } from "schemas";
import { sticky } from "@helpers/sticky";

export function ProjectFiles(props: {}, ctx: ViewContext) {
  const router = ctx.getStore(RouterStore);
  const projects = ctx.getStore(ProjectsStore);

  const $projectId = derive([router.$params], (params) => params.projectId);
  const $currentProject = derive([$projectId, projects.$cache], (id, projects) =>
    projects.find((p) => p.id === id),
  );
  const $latestProject = derive([$currentProject], sticky());

  return cond($latestProject, <h1>FILES</h1>);
}
