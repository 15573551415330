export default {
  "container": "Dialog_container_7877f",
  "open": "Dialog_open_7877f",
  "backdrop": "Dialog_backdrop_7877f",
  "form": "Dialog_form_7877f",
  "modal": "Dialog_modal_7877f",
  "header": "Dialog_header_7877f",
  "headerIcon": "Dialog_headerIcon_7877f",
  "headerText": "Dialog_headerText_7877f",
  "title": "Dialog_title_7877f",
  "subtitle": "Dialog_subtitle_7877f",
  "withSubtitle": "Dialog_withSubtitle_7877f",
  "content": "Dialog_content_7877f",
  "controls": "Dialog_controls_7877f",
  "buttons": "Dialog_buttons_7877f"
};import "ni:sha-256;ToqUuSB7MRLZD0tdsJ0HL9FGK49SdYDpEhxMOijbEoQ";