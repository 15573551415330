export default {
  "titleAndDescription": "TaskEditDialog_titleAndDescription_c69fd",
  "titleContainer": "TaskEditDialog_titleContainer_c69fd",
  "titleInput": "TaskEditDialog_titleInput_c69fd",
  "titlePlaceholder": "TaskEditDialog_titlePlaceholder_c69fd",
  "descriptionContainer": "TaskEditDialog_descriptionContainer_c69fd",
  "descriptionInput": "TaskEditDialog_descriptionInput_c69fd",
  "descriptionPlaceholder": "TaskEditDialog_descriptionPlaceholder_c69fd",
  "formGroup": "TaskEditDialog_formGroup_c69fd",
  "visible": "TaskEditDialog_visible_c69fd",
  "formGroupContent": "TaskEditDialog_formGroupContent_c69fd",
  "attachmentsList": "TaskEditDialog_attachmentsList_c69fd",
  "clearButton": "TaskEditDialog_clearButton_c69fd",
  "metaPriorityItem": "TaskEditDialog_metaPriorityItem_c69fd",
  "metaButtons": "TaskEditDialog_metaButtons_c69fd",
  "metaButton": "TaskEditDialog_metaButton_c69fd",
  "hidden": "TaskEditDialog_hidden_c69fd",
  "metaButtonIcon": "TaskEditDialog_metaButtonIcon_c69fd",
  "favoriteButton": "TaskEditDialog_favoriteButton_c69fd",
  "active": "TaskEditDialog_active_c69fd"
};import "ni:sha-256;kw22Xn1tcbXaouS6PbuJak_AVWmP4RtwjKL8MUbIKGc";