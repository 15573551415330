export default {
  "messageList": "ChatMessageList_messageList_765be",
  "message": "ChatMessageList_message_765be",
  "mine": "ChatMessageList_mine_765be",
  "noTextSpacer": "ChatMessageList_noTextSpacer_765be",
  "authorAvatar": "ChatMessageList_authorAvatar_765be",
  "authorAvatarSpacer": "ChatMessageList_authorAvatarSpacer_765be",
  "messageBubble": "ChatMessageList_messageBubble_765be",
  "messageContent": "ChatMessageList_messageContent_765be",
  "messageInfo": "ChatMessageList_messageInfo_765be",
  "authorName": "ChatMessageList_authorName_765be",
  "messageTimestamp": "ChatMessageList_messageTimestamp_765be"
};import "ni:sha-256;UjQk_6EXhf6ZlJMvy7ax3o6uvKsiDxxU8CS4RGh_MDM";