import { derive, LanguageStore, signal, type ViewContext } from "@manyducks.co/dolla";
import { TasksStore } from "@stores/TasksStore";
import { ThemeStore } from "@stores/ThemeStore";
// import { History } from "./History/History";
import { ColorDivider } from "@views/ColorDivider";
import { PlaceLayout } from "Workspace/PlaceLayout/PlaceLayout";
import styles from "./MyTasks.module.css";

interface MyTasksProps {}

export function MyTasks(props: MyTasksProps, ctx: ViewContext) {
  const tasks = ctx.getStore(TasksStore);
  const theme = ctx.getStore(ThemeStore);
  const { translate } = ctx.getStore(LanguageStore);

  ctx.beforeConnect(() => {
    tasks.fetchToDos();
  });

  const $color = derive([theme.$userTheme], (t) => t["--theme-primary"]);
  const $taskCount = derive([tasks.$myTasks], (tasks) => {
    let count = 0;
    for (let i = 0; i < tasks.length; i++) {
      if (tasks[i].completedAt == null) {
        count += 1;
      }
    }
    return count;
  });

  const [$selectedTab, setSelectedTab] = signal<"todo" | "calendar">("todo");

  return (
    <PlaceLayout
      color={$color}
      title={translate("workspace.me.tasks.tabName")}
      tabs={[
        {
          href: "/my-tasks/list",
          active: (pattern) => pattern.startsWith("/my-tasks/list"),
          text: translate("workspace.me.tasks.todo.tabName"),
        },
        {
          href: "/my-tasks/calendar",
          active: (pattern) => pattern.startsWith("/my-tasks/calendar"),
          text: translate("workspace.project.tasks.calendar.tabName"),
        },
      ]}
    >
      <div class={styles.layout}>
        {/* <div class={styles.segmentButtons}>
          <SegmentButton
            value={$$selectedTab}
            onChange={$$selectedTab.set}
            segments={[
              {
                value: "todo",
                content: (
                  <div class={styles.segment}>
                    {translate("workspace.me.tasks.todo.tabName")}
                    <span class={styles.segmentCountBadge}>({$taskCount})</span>
                  </div>
                ),
              },
              {
                value: "calendar",
                content: (
                  <div class={styles.segment}>{translate("workspace.project.tasks.calendar.tabName")}</div>
                ),
              },
            ]}
          />
        </div> */}

        <div class={styles.tabContent}>
          {ctx.outlet()}
          {/* {$($$selectedTab, (tab) => {
            if (tab === "todo") {
              return <List />;
            }

            if (tab === "calendar") {
              return <Calendar />;
            }
          })} */}
        </div>
      </div>
    </PlaceLayout>
  );
}
