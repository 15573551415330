import { derive, type Signal, type SettableSignal, ViewContext, MaybeSignal } from "@manyducks.co/dolla";
import styles from "./SearchOrCreateInput.module.css";
import { TextInput } from "@views/TextInput";
import { Button } from "@views/Button";

interface SearchOrCreateInputProps {
  $$value: SettableSignal<string>;
  $canInteract: Signal<boolean>;
  inputPlaceholder: MaybeSignal<string>;
  buttonLabel: MaybeSignal<string>;
  onSubmit: (value: string) => void | Promise<void>;
}

export function SearchOrCreateInput(props: SearchOrCreateInputProps, _: ViewContext) {
  return (
    <form
      class={styles.container}
      onSubmit={async (e) => {
        e.preventDefault();

        if (!props.$canInteract.get()) {
          return;
        }

        await props.onSubmit(props.$$value.get());
        props.$$value.set("");
      }}
    >
      <TextInput $$value={props.$$value} name="noteTitle" placeholder={props.inputPlaceholder} />
      <Button
        type="submit"
        disabled={derive(
          [props.$$value, props.$canInteract],
          (value, interact) => !interact || value.trim().length === 0,
        )}
      >
        {props.buttonLabel}
      </Button>

      {/* <input
        type="text"
        class={styles.searchInput}
        $$value={props.$$value}
        name="noteTitle"
        placeholder={props.inputPlaceholder}
      />

      <input
        type="submit"
        class={styles.searchButton}
        disabled={derive(
          [props.$$value, props.$canInteract],
          (value, interact) => !interact || value.trim().length === 0,
        )}
        value={props.buttonLabel}
      /> */}
    </form>
  );
}
