import { derive, signal, StoreContext } from "@manyducks.co/dolla";

// Each breakpoint begins at its width in pixels
const Breakpoints = {
  S: 480,
  M: 767,
  L: 1023,
  XL: 1279,
};

/**
 * Tracks a set of breakpoint booleans based on the current screen size.
 */
export function BreakpointStore(ctx: StoreContext) {
  const [$width, setWidth] = signal(0);

  ctx.onConnected(function () {
    window.addEventListener(
      "resize",
      () => {
        setWidth(window.innerWidth);
      },
      { passive: true },
    );

    setWidth(window.innerWidth);
  });

  /**
   * Returns a new state that is true when page width is at least `width`.
   */
  function min$(width: number) {
    return derive([$width], (current) => current >= width);
  }

  /**
   * Returns a new state that is true when page width is less than `width`.
   */
  function max$(width: number) {
    return derive([$width], (current) => current < width);
  }

  return {
    $width,
    min$,
    max$,

    // Breakpoints are true when screen width is at least as wide as their value in pixels
    $sm: min$(Breakpoints.S),
    $md: min$(Breakpoints.M),
    $lg: min$(Breakpoints.L),
    $xl: min$(Breakpoints.XL),
  };
}
