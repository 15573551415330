import {
  DialogStore,
  LanguageStore,
  RouterStore,
  cond,
  derive,
  signal,
  type ViewContext,
} from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { BreakpointStore } from "@stores/BreakpointStore";
import { ChatStore } from "@stores/ChatStore";
import { IOStore } from "@stores/IOStore";
import { LoaderStore } from "@stores/LoaderStore";
import { NavStore } from "@stores/NavStore";
import { NotesStore } from "@stores/NotesStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { TasksStore } from "@stores/TasksStore";
import { ThemeStore } from "@stores/ThemeStore";
import ChatLineIcon from "@icons/ChatLine";
import CloseIcon from "@icons/Close";
import PlacesIcon from "@icons/Places";
import SearchIcon from "@icons/Search";
import SettingsIcon from "@icons/Settings";
import { FileViewer } from "@views/FileViewer";
import { TaskEditDialog } from "@views/TaskListItem/TaskEditDialog/TaskEditDialog";
import { Chat } from "./Chat";
import { Places } from "./Nav/Places/Places";
import { Search } from "./Nav/Search/Search";
import { Settings } from "./Nav/Settings/Settings";
import styles from "./Workspace.module.css";
import ArrowRight from "@icons/ArrowRight";

/**
 * Top level layout for the authenticated portions of the app.
 */
export function Workspace(_: {}, ctx: ViewContext) {
  const router = ctx.getStore(RouterStore);
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);
  const io = ctx.getStore(IOStore);
  const auth = ctx.getStore(AuthStore);
  const projects = ctx.getStore(ProjectsStore);
  const loader = ctx.getStore(LoaderStore);
  const notes = ctx.getStore(NotesStore);
  const tasks = ctx.getStore(TasksStore);
  const chat = ctx.getStore(ChatStore);
  const breakpoint = ctx.getStore(BreakpointStore);
  const theme = ctx.getStore(ThemeStore);
  const dialog = ctx.getStore(DialogStore);
  const nav = ctx.getStore(NavStore);

  // const [$menuWidth, setMenuWidth] = signal(Number(localStorage.getItem("ui.menuWidth") ?? 300));
  // const [$chatWidth, setChatWidth] = signal(Number(localStorage.getItem("ui.chatWidth") ?? 300));

  // const [$resizeTarget, setResizeTarget] = signal<"menu" | "chat" | null>(null);

  // ctx.watch([$menuWidth], (menuWidth) => {
  //   localStorage.setItem("ui.menuWidth", String(menuWidth));
  // });

  // ctx.watch([$chatWidth], (chatWidth) => {
  //   localStorage.setItem("ui.chatWidth", String(chatWidth));
  // });

  const $chatProject = derive([chat.$projectId, projects.$cache], (projectId, projects) => {
    return projects.find((p) => p.id === projectId);
  });
  const $chatColor = derive([$chatProject, theme.$placeColor], (project, placeColor) => {
    if (project) {
      return theme.getBlendedColor$(project.color, placeColor).get();
    } else {
      return placeColor;
    }
  });
  const [$chatIsAnimating, setChatIsAnimating] = signal(false);

  ctx.watch([$currentLanguage], (language) => {
    ctx.log({ language });
    if (language) {
      localStorage.setItem("quack-current-language", language);
    }
  });

  function onServiceWorkerMessage(this: ServiceWorkerContainer, event: MessageEvent<any>): any {
    ctx.info("received from worker", event.data);

    if (event.data.type === "chatMessage") {
      const projectId = event.data.project.id as number;
      chat.setProjectId(projectId);
      nav.openChat();
    }
  }

  const [$socketConnected, setSocketConnected] = signal(false);
  const [$isLoaded, setIsLoaded] = signal(false);

  ctx.onMount(async () => {
    if (!auth.$isLoggedIn.get()) {
      router.navigate("/login");
      loader.hideAppLoader();
      return;
    }

    // if (router.$path.get() === "/") {
    //   const lastVisited = localStorage.getItem("nav.lastVisitedPath");
    //   if (lastVisited) {
    //     router.navigate(lastVisited);
    //   } else {
    //     router.navigate("/my-tasks");
    //   }
    // }

    ctx.watch([router.$path], (path) => {
      localStorage.setItem("nav.lastVisitedPath", path);
    });

    // Once user becomes logged out, drop them back at the landing page.
    ctx.watch([auth.$isLoggedIn], (isLoggedIn) => {
      if (!isLoggedIn) {
        router.navigate("/login");
      }
    });

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", onServiceWorkerMessage);
    }

    await auth.getUsers();

    const socket = io.socket();

    socket.on("connect", () => {
      auth.getUpdateStatus();
      setSocketConnected(true);
    });

    socket.on("disconnect", () => {
      setSocketConnected(false);
    });

    socket.on("task:update", tasks.taskUpdateReceived);
    socket.on("task:delete", tasks.taskDeleteReceived);

    socket.on("note:update", notes.noteUpdateReceived);
    socket.on("note:delete", notes.noteDeleteReceived);

    // socket.on("settings:update", auth.settingsUpdateReceived);

    socket.on("project:invited", projects.projectInviteReceived); // When a project invite is received
    socket.on("project:added", (projectId) => {}); // When user joins a project
    socket.on("project:update", projects.projectUpdateReceived);
    socket.on("project:delete", (projectId) => {
      projects.projectDeleteReceived(projectId);
      socket.emit("projects:refresh", () => {
        ctx.log("Projects refreshed");
      });
    });

    socket.on("user:update", auth.userUpdateReceived);

    socket.on("chat:message", chat.chatMessageReceived);

    await Promise.all([projects.refreshList(), chat.fetchMessages(), nav.fetchFavorites()]).then(() => {
      loader.hideAppLoader();
      setIsLoaded(true);
    });

    ctx.onUnmount(() => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener("message", onServiceWorkerMessage);
      }
      socket.close();
    });
  });

  ctx.watch([$socketConnected], async (socketConnected) => {
    if (!socketConnected || !$isLoaded.get()) return;

    const promises: Promise<any>[] = [
      auth.getUsers(),
      projects.refreshList(),
      chat.fetchMessages(),
      nav.fetchFavorites(),
    ];

    // remove deleted resources
    const caches: {
      users: number[];
      projects: number[];
      notes: number[];
      tasks: number[];
    } = {
      users: auth.$users.get().map((x) => x.id),
      projects: projects.$cache.get().map((x) => x.id),
      notes: [],
      tasks: [],
    };

    const pattern = router.$pattern.get();
    const params = router.$params.get();

    const inNotes = pattern?.startsWith("/projects/{#projectId}/notes");
    const inTasks = pattern?.startsWith("/projects/{#projectId}/tasks");

    if (inNotes) {
      caches.notes = [...notes.$cache.get().keys()];
    } else if (inTasks) {
      caches.tasks = [...tasks.$cache.get().keys()];
    }

    const deletedCaches = await auth.validateCache(caches);

    auth.updateCache(deletedCaches.users);
    projects.updateCache(deletedCaches.projects);
    notes.updateCache(deletedCaches.notes);
    tasks.updateCache(deletedCaches.tasks);

    // load latest resources
    if (inNotes) {
      promises.push(notes.fetchIndexFor(Number(params.projectId), true));
    } else if (inTasks) {
      promises.push(tasks.fetchIndexFor(Number(params.projectId), true));
    } else if (pattern?.startsWith("/my-tasks")) {
      promises.push(tasks.fetchToDos());
    }

    await Promise.all(promises);

    ctx.log("Socket reconnected: data refreshed");
  });

  // check for updates
  const onVisibilityChange = async () => {
    if (document.visibilityState === "visible") {
      // clear notifications
      navigator.serviceWorker.ready.then((reg) => {
        reg.getNotifications().then((notifications) => {
          for (let i = 0; i < notifications.length; i += 1) {
            notifications[i].close();
          }
        });
      });

      // check for update
      const updateAvailable = await auth.getUpdateStatus();
      console.log(`UPDATE AVAILABLE: ${updateAvailable}`);
    }
  };

  ctx.onConnected(() => {
    window.addEventListener("visibilitychange", onVisibilityChange);
  });

  ctx.onDisconnected(() => {
    window.removeEventListener("visibilitychange", onVisibilityChange);
  });

  return cond(
    $isLoaded,
    <div
      class={styles.layout}
      style={{
        "--horizontal-padding-for-chat": derive([breakpoint.min$(800), nav.$chatIsOpen], (bp, open) => {
          if (bp) {
            if (open) {
              return "424px";
            } else {
              return "36px";
            }
          }

          return "0px";
        }),
      }}
    >
      <div class={styles.main} style={theme.getTheme$(theme.$placeColor)}>
        {ctx.outlet()}
      </div>

      <div
        class={[styles.chat, { [styles.open]: nav.$chatIsOpen, [styles.animating]: $chatIsAnimating }]}
        style={theme.getTheme$($chatColor)}
        onTransitionEnd={() => {
          if (!nav.$chatIsOpen.get()) {
            setChatIsAnimating(false);
          }
        }}
      >
        <button
          class={styles.chatTabClickZone}
          onClick={() => {
            // Set to current project before opening if there is no current chat project selected.
            if (!nav.$chatIsOpen.get() && chat.$projectId.get() == null) {
              const { projectId } = router.$params.get();
              if (projectId != null) {
                chat.setProjectId(Number(projectId));
              }
            }

            setChatIsAnimating(true);

            nav.toggleChat();
          }}
        />

        <div class={styles.chatTab}>
          <span class={styles.chatTabIcon}>
            <ArrowRight />
          </span>
          <span class={styles.chatTabLabel}>
            {cond(
              nav.$chatIsOpen,
              translate("workspace.chat.closeChatLabel"),
              translate("workspace.chat.openChatLabel"),
            )}
          </span>
          {cond(
            $chatProject,
            <ul class={styles.chatTabMeta}>
              <li class={styles.chatTabMetaName}>{derive([$chatProject], (p) => p?.name)}</li>
              <li class={styles.chatTabMetaUnread}>
                {translate("workspace.chat.unreadCountLabel", { count: "3" })}
              </li>
            </ul>,
          )}
        </div>

        <div class={styles.chatContent}>
          <Chat $chatColor={$chatColor} />
        </div>
      </div>

      {cond(nav.$fileViewerIsOpen, <FileViewer />)}
    </div>,
  );
}
