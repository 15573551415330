import { ref, signal, type Ref, type ViewContext } from "@manyducks.co/dolla";
import styles from "./Card.module.css";

interface CardContentProps {
  /**
   * Ref to receive the content DOM node.
   */
  ref?: Ref<HTMLElement>;

  initialScrollPosition?: number;
}

/**
 * Holds the card's main content.
 */
export function CardContent(props: CardContentProps, ctx: ViewContext) {
  const render = ctx.getStore("render");

  const _ref = props.ref ?? ref<HTMLElement>();

  const [$scrollable, setScrollable] = signal(false);

  if (props.initialScrollPosition) {
    ctx.onConnected(() => {
      setTimeout(() => {
        render.update(() => {
          props.ref?.get()?.scrollTo({
            top: props.initialScrollPosition!,
            behavior: "smooth",
          });
        });
      }, 100);
    });
  }

  ctx.onMount(() => {
    const el = _ref.get()!;

    function onScroll(e: Event) {
      setScrollable(el.scrollHeight > el.clientHeight);
    }

    el.addEventListener("scroll", onScroll);
    setScrollable(el.scrollHeight > el.clientHeight);

    return function cleanup() {
      el.removeEventListener("scroll", onScroll);
    };
  });

  return (
    <div class={[styles.cardContent, styles.scrollable]} ref={props.ref}>
      {ctx.outlet()}
    </div>
  );
}
