export default {
  "container": "ChatEditor_container_69a09",
  "editor": "ChatEditor_editor_69a09",
  "focused": "ChatEditor_focused_69a09",
  "placeholder": "ChatEditor_placeholder_69a09",
  "searchMenu": "ChatEditor_searchMenu_69a09",
  "attachments": "ChatEditor_attachments_69a09",
  "inputFormAttachment": "ChatEditor_inputFormAttachment_69a09",
  "attachmentToolbar": "ChatEditor_attachmentToolbar_69a09",
  "attachmentMeta": "ChatEditor_attachmentMeta_69a09",
  "attachmentSizeLabel": "ChatEditor_attachmentSizeLabel_69a09",
  "attachmentProgressBar": "ChatEditor_attachmentProgressBar_69a09",
  "form": "ChatEditor_form_69a09"
};import "ni:sha-256;_SsScJZxDysYdW7_7XagaF67ZcnPsddmo7ZKTn79NHU";